<form-loading-container
  class="agency-special"
  [loadingState]="loading$ | async"
  [height]="'90vh'"
>
<div class="row" style="margin: 0 10px">
  <div class="col-sm-6">
    <form>
      <div class="form-group row">
        <label class="col-3 col-form-label">Reederei</label>
        <div class="col-9" *ngIf="{companies: cruiseMetadataService.companies$ |async}as data">
          <ng-select
            placeholder="Reederei auswählen"
            [items]="data?.companies"
            [ngModel]="selectedCompany$ | async"
            (ngModelChange)="selectedCompany$.next($event);selectedShip$.next(null);selectedRoute$.next(null);selectedTimetable$.next(null)"
            [ngModelOptions]="{standalone: true}"
            bindLabel="name"
            [searchable]="true"
            [clearable]="true"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-3 col-form-label">Schiff</label>
        <div class="col-9" *ngIf="{ships: ships$ |async}as data">
          <ng-select
            placeholder="Schiff auswählen"
            [items]="data?.ships"
            [ngModel]="selectedShip$ | async"
            (ngModelChange)="selectedShip$.next($event); selectedRoute$.next(null);selectedTimetable$.next(null)"
            [ngModelOptions]="{standalone: true}"
            bindLabel="shipname"
            [searchable]="true"
            [clearable]="true"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-3 col-form-label">Route</label>
        <div class="col-9" *ngIf="{routes: routes$ |async}as data">
          <ng-select
            placeholder="Route auswählen"
            [items]="data?.routes"
            [ngModel]="selectedRoute$ | async"
            (ngModelChange)="selectedRoute$.next($event); selectedTimetable$.next(null)"
            [ngModelOptions]="{standalone: true}"
            bindLabel="name"
            [searchable]="true"
            [clearable]="true"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-3 col-form-label">Kreuzfahrt</label>
        <div class="col-9" *ngIf="{timetables: timetables$ |async}as data">
          <ng-select
            placeholder="Kreuzfahrt auswählen"
            [items]="data?.timetables"
            [ngModel]="selectedTimetable$ | async"
            (ngModelChange)="selectedTimetable$.next($event);displayCabinFilter$.next(true)"
            [ngModelOptions]="{standalone: true}"
            bindLabel="startDate"
            [searchable]="true"
            [clearable]="true"
          >
            <ng-template ng-label-tmp let-item="item">
              {{item?.startDate | date : 'dd.MM.yyyy'}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{item?.startDate | date : 'dd.MM.yyyy'}}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <br>
      <br>
      <h2>Flugzuschläge</h2>
      <div style="padding-bottom: 20px;">
        <button (click)="refreshFlightSurcharges()" class="btn btn-light">
          Flugzuschläge zurücksetzen
        </button>
      </div>
      <div class="airport-surcharge-form" style="width: 100%; display: flex">
        <formio
          [submission]="flightSurchargesSubmission$ | async"
          [form]="airportSurchargeFormConfig"
          (submit)="flightSurchargesFormData$.next($event)"
        ></formio>
      </div>
    </form>
  </div>
  <div class="col-sm-6 promo-form">
    <formio
      [form]="promoFromConfig$ | async"
      promoFormRef
      id="promo-form"
      (submit)="promoFormData$.next($event)"
    ></formio>
  </div>
</div>
<br />
<br />
<div *ngIf="displayCabinFilter$.value" style="margin: 0 5%">
    <h2>Kabinen</h2>
    <div style="padding-bottom: 20px;">
      <button (click)="refreshCabins()" class="btn btn-light">
        Kabinen zurücksetzen
      </button>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group row">
          <label class="col-3 col-form-label" style="text-align: center">Kabinkategorie</label>
          <div class="col-9" *ngIf="{cabinCategories: cabinCategories$ |async}as data">
            <ng-select
              placeholder="Nach Kabinkategorie filtern"
              [items]="data?.cabinCategories"
              [ngModel]="selectedCabinCategories$ | async"
              (ngModelChange)="selectedCabinCategories$.next($event)"
              [ngModelOptions]="{standalone: true}"
              bindLabel="cabinCategoryName"
              [searchable]="true"
              [clearable]="true"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group row">
          <label class="col-3 col-form-label" style="text-align: center">Tarif</label>
          <div class="col-9" *ngIf="{priceModels: priceModels$ |async}as data">
            <ng-select
              placeholder="Nach Tarif filtern"
              [items]="data?.priceModels"
              [ngModel]="selectedPriceModel$ | async"
              (ngModelChange)="selectedPriceModel$.next($event)"
              [ngModelOptions]="{standalone: true}"
              [searchable]="true"
              [clearable]="true"
            >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div id="cabins-prices-grid" class="overflow-auto scrollbar cabin-form">
      <div [class]="classesToBeHidden$ | async">
      <formio
        [submission]="cabinsSubmission$ | async"
        [form]="cabinFormConfig"
        (submit)="cabinFormData$.next($event)"
      ></formio>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; padding: 30px;">
      <button *ngIf="specialPrices" (click)="delete()" class="btn btn-danger">
        Alles Löschen
      </button>
      <button (click)="saveChanges()" class="btn btn-secondary">
        Änderungen Speichern
      </button>
    </div>
</div>
</form-loading-container>
