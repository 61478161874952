<div
  style="padding: 10px"
  *ngIf="{kpiIndependentData: kpiIndependentData$ | async, kpiDependentData: kpiDependentData$ | async} as data"
>
  <!--  <mat-expansion-panel expanded="true">-->
  <!--    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>-->
  <!--    <app-deeplink-filter-->
  <!--      header-filter-->
  <!--      [filterStateService]="filterStateService"-->
  <!--      [withFunctionButtons]="true"-->
  <!--      [withBadges]="true"-->
  <!--    >-->
  <!--    </app-deeplink-filter>-->
  <!--  </mat-expansion-panel>-->

  <!--      <div class="w-100"></div>-->
  <div class="container">
    <div class="col-4 p-3 day-booking-volume border">
      <p class="day-booking-volume-title">Umsatz Buchungsdatum heute:</p>
      <div class="volume-and-button">
        <p>
          {{data?.kpiIndependentData?.bookingVolumeToday |
          currency:'EUR':'symbol':'3.2-2':'de-DE'}}
        </p>
        <button mat-raised-button color="primary">View Sales</button>
      </div>
    </div>
    <div class="col-7 p-3 monthly-booking-data border">
      <p class="monthly-booking-data_title">Aktueller Monat</p>
      <div class="monthly-booking-data_values container">
        <div class="col-3">
          <p>Summe Neubuchungen</p>
          <p class="center-align">
            <mat-icon class="icon-monthly-data" style="background: blueviolet"
              >auto_graph</mat-icon
            >
            &nbsp;{{LabelFormatter(data?.kpiDependentData?.totalBookingsDuringPeriod)}}
          </p>
        </div>
        <div class="col-3">
          <p>Summe Neukunden</p>
          <p class="center-align">
            <mat-icon class="icon-monthly-data" style="background: limegreen"
              >person</mat-icon
            >
            &nbsp;{{LabelFormatter(data?.kpiDependentData?.newCustomersDuringPeriod)}}
          </p>
        </div>
        <div class="col-3">
          <p>Summe Anzahl Abreisen</p>
          <p class="center-align">
            <mat-icon class="icon-monthly-data" style="background: goldenrod"
              >devices</mat-icon
            >
            &nbsp;{{LabelFormatter(data?.kpiDependentData?.departuresDuringPeriod)}}
          </p>
        </div>
        <div class="col-3">
          <p>Summe Abreisen</p>
          <p class="center-align">
            <mat-icon class="icon-monthly-data" style="background: dodgerblue"
              >attach_money</mat-icon
            >
            <!--            &nbsp;{{data?.kpiDependentData?.bookingVolumeForDepartures |-->
            <!--            currency:'EUR':'symbol':'3.2-2':'de-DE'}}-->
            &nbsp;{{LabelFormatter(data?.kpiDependentData?.bookingVolumeForDepartures)}}€
          </p>
        </div>
      </div>
    </div>
    <div class="col-4 p-3 border">
      <p>Umsatz diese Woche</p>
      <div class="weekly-booking-chart">
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: currentWeekChartOptions$ | async}"
        >
        </ng-container>
      </div>
    </div>
    <div class="col-7 p-3 border">TopSeller Reisen</div>
    <div class="col-4 p-3">Diese Woche</div>
    <div class="col-7 p-3">Jahreszahlen</div>
    <div class="col-4 p-3">Umsatzverteilung aktuelles Jahr</div>
    <div class="col-7 p-3">Top Kunden</div>
  </div>
</div>

<!--CHART TEMPLATE-->
<ng-template #chartTemplate let-options="options">
  <mat-card>
    <!--                    <mat-card-header>CHART_TITLE</mat-card-header>-->
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="options"
        style="width: 100%; display: block"
      >
      </highcharts-chart>
    </mat-card-content>
  </mat-card>
</ng-template>
