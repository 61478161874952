import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalLoadingService } from 'src/app/shared/services/global-loading.service';
import { RequestModelPaged } from 'src/app/apis/core/models';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DatabaseModelUserBookedTripResponseModelDataTableResult } from '../../../apis/msb/models/database-model-user-booked-trip-response-model-data-table-result';
import { DatabaseModelUserBookedTrip } from '../../../apis/msb/models/database-model-user-booked-trip';

@Component({
  selector: 'app-booked-trips-table',
  templateUrl: './booked-trips-table.component.html',
  styleUrls: ['./booked-trips-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookedTripsTableComponent {
  @Input() userId?: number;

  /**
   * Returns Total Booking Volume.
   * WARNING: At some point we need to make a own backend method for this
   */
  readonly totalBookingVolume$ = this.adminService
    .streamBookings({
      body: {
        start: 0,
        sortDirection: 'desc',
        sortColumn: 'id',
        searchValue: '',
        length: 1000000,
        draw: 3,
        customFilter: []
      }
    })
    .pipe(
      map((bookingResponse) =>
        this._transformResponse(bookingResponse)
          .data.map(
            (x: DatabaseModelUserBookedTrip & { bookingDataParsed?: any }) =>
              x.bookingDataParsed.trip.totalPrice
          )
          .reduce((pv, cv) => pv + cv, 0)
      ),
      shareReplay(1)
    );

  constructor(
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      if (this.userId) {
        const customFilterKey = 'userId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push({
            key: customFilterKey,
            value: this.userId.toString(10)
          });
        }
      }
      return this.adminService
        .streamBookings({ body: request })
        .pipe(
          map((bookingResponse) => this._transformResponse(bookingResponse))
        );
    };
  }

  /**
   * Transform DataTable Result.
   * Iterates over each data row and parses bookingData as JSON to a new property called 'bookingDataParsed'.
   */
  private _transformResponse(
    bookingResponse: DatabaseModelUserBookedTripResponseModelDataTableResult
  ): DatabaseModelUserBookedTripResponseModelDataTableResult {
    (bookingResponse.data as any[]).forEach(
      (booking) => (booking.bookingDataParsed = JSON.parse(booking.bookingData))
    );
    return bookingResponse;
  }
}
