<div class="row filters">
  <div *ngFor="let filter of filters; index as i" class="col-sm-6 col-xl-3">
    <ng-container
      *ngIf="{
        filterState: filterStateService.filterState.filterStates[filter.matrixParameter],
        filterObserver: filterObserver$ | async
      } as data"
    >
      <ng-container
        *ngIf="{ isDefault: (data.filterState.isFilterDefault$ | async) } as default"
      >
        <ng-container [ngSwitch]="filter.type">
          <ng-container *ngSwitchCase="'INPUT_TEXT'">
            <mat-form-field
              [appearance]="appearance"
              [floatLabel]="'always'"
              [matTooltip]="filter?.tooltipText ? filter?.tooltipText : ''"
              matTooltipPosition="right"
            >
              <mat-label>{{ filter.label }}</mat-label>
              <input
                matInput
                [ngModel]="data.filterState.filterDataSingle$ | async"
                (ngModelChange)="filter.ngModelChange ? filter.ngModelChange($event) : data.filterState.updateFilterData({ selectedIds: $event })"
                [placeholder]="filter?.placeholder ? filter.placeholder : ''"
              />
              <mat-icon matPrefix>{{ filter.iconName }}</mat-icon>
              <button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                [style.opacity]="default.isDefault ? 0 : 1"
                [disabled]="default.isDefault"
                (click)="data.filterState.resetFilter()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'INPUT_NUMBER'">
            TODO: INPUT_NUMBER
          </ng-container>

          <ng-container *ngSwitchCase="'DROPDOWN'">
            <ng-container
              *ngIf="{ options: (data.filterState.filterSettings$ | async)?.availableItems } as dropdownData"
            >
              <ng-container
                *ngIf="{ disabled: dropdownData?.options?.length === 0 } as dropdownMeta"
              >
                <mat-form-field
                  [appearance]="appearance"
                  [floatLabel]="'always'"
                  [matTooltip]="dropdownMeta.disabled && filter.disabledPlaceholder ? filter.disabledPlaceholder : filter?.tooltipText ? filter?.tooltipText : ''"
                  matTooltipPosition="right"
                >
                  <mat-label>{{ filter.label }}</mat-label>
                  <mat-select
                    [disabled]="dropdownMeta.disabled"
                    [ngModel]="data.filterState.filterDataSingle$ | async"
                    (ngModelChange)="filter.ngModelChange ? filter.ngModelChange($event) : data.filterState.updateFilterData({ selectedIds: $event })"
                    [placeholder]="filter?.placeholder ? filter.placeholder : 'Alle'"
                  >
                    <mat-option
                      *ngIf="filter?.emptyOption"
                      [value]="filter.emptyOptionValue"
                      >{{ filter.emptyOption }}</mat-option
                    >
                    <mat-option
                      *ngFor="let opt of dropdownData.options"
                      [value]="opt.id"
                      [disabled]="opt?.disabled"
                    >
                      {{ opt.label }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matPrefix>{{ filter.iconName }}</mat-icon>
                  <button
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    [style.opacity]="default.isDefault ? 0 : 1"
                    [disabled]="default.isDefault"
                    (click)="data.filterState.resetFilter()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'MULTISELECT'">
            <ng-container
              *ngIf="{ value: data.filterState.filterDataMulti$ | async } as containerData"
            >
              <mat-form-field
                [appearance]="appearance"
                [floatLabel]="'always'"
                [matTooltip]="filter?.tooltipText ? filter?.tooltipText : ''"
                matTooltipPosition="right"
              >
                <mat-label>{{ filter.label }}</mat-label>
                <mat-select
                  (closed)="data.filterState.updateFilterData({ selectedIds: containerData.value })"
                  [multiple]="true"
                  [(ngModel)]="containerData.value"
                  [placeholder]="filter?.placeholder ? filter.placeholder : 'Alle'"
                >
                  <mat-option
                    *ngFor="let opt of (data.filterState.filterSettings$ | async)?.availableItems"
                    [value]="opt.id"
                  >
                    {{ opt.label }}
                  </mat-option>
                </mat-select>
                <mat-icon matPrefix>{{ filter.iconName }}</mat-icon>
                <button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  [style.opacity]="default.isDefault ? 0 : 1"
                  [disabled]="default.isDefault"
                  (click)="data.filterState.resetFilter()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'MULTISELECT_WITH_SEARCH'">
            MULTISELECT_WITH_SEARCH
          </ng-container>

          <ng-container *ngSwitchCase="'SELECT_WITH_SEARCH'">
            SELECT_WITH_SEARCH
          </ng-container>

          <ng-container *ngSwitchCase="'DATERANGE'"> DATERANGE </ng-container>

          <ng-container *ngSwitchCase="'DATEPICKER'"> DATEPICKER </ng-container>

          <ng-container *ngSwitchCase="'RADIO_SINGLE'">
            RADIO_SINGLE
          </ng-container>

          <ng-container *ngSwitchCase="'BUTTON'"> BUTTON </ng-container>

          <ng-container *ngSwitchCase="'BUTTON_GROUP'">
            BUTTON_GROUP
          </ng-container>

          <ng-container *ngSwitchCase="'TABS'"> TABS </ng-container>

          <ng-container *ngSwitchCase="'INPUT_NUMBER_WITH_STEPPER'">
            INPUT_NUMBER_WITH_STEPPER
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>

<div *ngIf="withFunctionButtons" class="row filter-function-buttons">
  <div class="col">
    <button
      (click)="sendFilters()"
      mat-raised-button
      style="margin-right: 1rem"
      color="primary"
    >
      Filter Teilen
    </button>
    <button
      (click)="resetFilters()"
      class="ms-3x"
      mat-raised-button
      color="primary"
    >
      Zurücksetzen
    </button>
  </div>
</div>

<div *ngIf="withBadges" class="row filter-badges">
  <div class="col d-flex justify-content-start flex-wrap">
    <ng-container *ngFor="let fltrCfg of filters">
      <ng-container *ngIf="fltrCfg.type !== 'HIDDEN'">
        <ng-container
          *ngIf="badgeTextObservables[fltrCfg.matrixParameter] | async as filterText"
        >
          <ds-tag
            [labelTemplate]="badgeTagTemplateRef"
            [isDismissable]="true"
            (dismiss)="resetSingleFilter(fltrCfg)"
            ds-overflow="truncate"
            [title]="filterText"
          >
          </ds-tag>
          <ng-template #badgeTagTemplateRef>
            <strong>{{ fltrCfg.label }}</strong> {{ filterText }}
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
