<ng-template #cellDateSavedRef let-row="row">
  {{row?.saveOn | date : 'dd.MM.yy'}} @ {{row?.saveOn | date : 'shortTime'}}
</ng-template>

<ng-template #rowDetailTemplateRef let-row="row">
  <div style="max-width: 85vw;">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>timeline</mat-icon>&nbsp; Abreisetermine
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-timetables-table [cruiseId]="row.cruiseId">
        </app-timetables-table>
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'routeId', name: 'Routen Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'routeTimetable.route.name', name: 'Routenname', sortable: false },
        { prop: 'routeTimetable.ship.shipname', name: 'Schiff', sortable: false },
        { prop: 'routeTimetable.shippingCompany.name', name: 'Reederei', sortable: false },
        { prop: 'userId', name: 'User Id' },
        { prop: 'user.firstName', name: 'Vorname' , sortable: false },
        { prop: 'user.lastName', name: 'Nachname' , sortable: false },
        { prop: 'user.email', name: 'E-Mail' , sortable: false },
        { prop: 'user.customerNumber', name: 'Kundennummer', sortable: false },
        { prop: 'saveOn', name: 'Datum hinzugefügt', cellTemplate: cellDateSavedRef}
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()" [enableRowDetails]="true" [rowDetailTemplateRef]="rowDetailTemplateRef">
  </dynamic-table>

</ng-container>
