import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalLoadingService } from '../shared/services/global-loading.service';
import { GlobalDataService } from '../shared/services/global-data.service';
import { ROUTE } from '../shared/enums/route.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  readonly ROUTE = ROUTE;

  constructor(
    private readonly _globalLoadingService: GlobalLoadingService,
    readonly globalDataService: GlobalDataService,
    readonly router: Router
  ) {}

  ngOnInit(): void {
    if (!this.globalDataService.AccessToken) {
      //todo: actually check if the token is valid, too...
      // alert('ACCESS DENIED'); // what's with all the alerts!?
      this.router.navigate(['/login']);
      return;
    }
    this._globalLoadingService.release();
  }
}
