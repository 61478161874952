/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { MailApiConfiguration as __Configuration } from '../mail-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class RodomniaEmailTemplatesService extends __BaseService {
  static readonly LoehneRausschickenEmailTemplatePath = '/api/RodomniaEmailTemplates/loehne';
  static readonly RechnungenSchreibenEmailTemplatePath = '/api/RodomniaEmailTemplates/rechnungen';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  LoehneRausschickenEmailTemplateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RodomniaEmailTemplates/loehne`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  LoehneRausschickenEmailTemplate(): __Observable<null> {
    return this.LoehneRausschickenEmailTemplateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  RechnungenSchreibenEmailTemplateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RodomniaEmailTemplates/rechnungen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  RechnungenSchreibenEmailTemplate(): __Observable<null> {
    return this.RechnungenSchreibenEmailTemplateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RodomniaEmailTemplatesService {
}

export { RodomniaEmailTemplatesService }
