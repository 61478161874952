/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestModelSearchFilterParams } from '../models/request-model-search-filter-params';
import { RequestModelSmartBulkSearchFilterParamEntry } from '../models/request-model-smart-bulk-search-filter-param-entry';
import { ResponseModelSearchResult } from '../models/response-model-search-result';
import { RouteAdditionalData } from '../models/route-additional-data';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation filterRoutes
   */
  static readonly FilterRoutesPath = '/api/Search/filter-routes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filterRoutes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterRoutes$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<ResponseModelSearchResult>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.FilterRoutesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseModelSearchResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filterRoutes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterRoutes(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<ResponseModelSearchResult> {

    return this.filterRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<ResponseModelSearchResult>) => r.body as ResponseModelSearchResult)
    );
  }

  /**
   * Path part for operation getBulkRouteAdditionalData
   */
  static readonly GetBulkRouteAdditionalDataPath = '/api/Search/bulk-route-additional-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBulkRouteAdditionalData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBulkRouteAdditionalData$Response(params?: {
    context?: HttpContext
    body?: Array<RequestModelSmartBulkSearchFilterParamEntry>
  }
): Observable<StrictHttpResponse<Array<RouteAdditionalData>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetBulkRouteAdditionalDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RouteAdditionalData>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBulkRouteAdditionalData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBulkRouteAdditionalData(params?: {
    context?: HttpContext
    body?: Array<RequestModelSmartBulkSearchFilterParamEntry>
  }
): Observable<Array<RouteAdditionalData>> {

    return this.getBulkRouteAdditionalData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RouteAdditionalData>>) => r.body as Array<RouteAdditionalData>)
    );
  }

  /**
   * Path part for operation getDurationRangeFiltered
   */
  static readonly GetDurationRangeFilteredPath = '/api/Search/duration-range-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDurationRangeFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDurationRangeFiltered$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.GetDurationRangeFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDurationRangeFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDurationRangeFiltered(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getDurationRangeFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

}
