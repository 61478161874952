/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { MailApiConfiguration as __Configuration } from '../mail-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RequestModelBookingEmail } from '../models/request-model-booking-email';
@Injectable({
  providedIn: 'root',
})
class AdminMsbEmailTemplatesService extends __BaseService {
  static readonly PrintBookingPath = '/api/AdminMsbEmailTemplates/print-booking';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  PrintBookingResponse(body?: RequestModelBookingEmail): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AdminMsbEmailTemplates/print-booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  PrintBooking(body?: RequestModelBookingEmail): __Observable<string> {
    return this.PrintBookingResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AdminMsbEmailTemplatesService {
}

export { AdminMsbEmailTemplatesService }
