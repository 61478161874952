<ng-template #cellDateStartRef let-row="row">
  {{row?.startDate | date : 'dd.MM.yy'}} @ {{row?.startDate | date : 'shortTime'}}
</ng-template>

<ng-template #rowDetailTemplateRef let-row="row">
  <div style="max-width: 85vw;">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>flight</mat-icon>&nbsp; Flug Preise (Aktuell)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-flight-prices-table [cruiseId]="row.cruiseId">
          </app-flight-prices-table>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>euro_symbol</mat-icon>&nbsp; Preise (Aktuell)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-live-prices-table [cruiseId]="row.cruiseId">
          </app-live-prices-table>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>euro_symbol</mat-icon>&nbsp; Preise (Historisch)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-historical-prices-table [cruiseId]="row.cruiseId">
          </app-historical-prices-table>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'shipId', name: 'Ship Id' },
        { prop: 'shippingCompanyId', name: 'Company Id' },
        { prop: 'startDate', name: 'Start Date', cellTemplate: cellDateStartRef },
        { prop: 'duration', name: 'Duration' },
        { prop: 'offerType', name: 'Offer Type' }
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()" [enableRowDetails]="true" [rowDetailTemplateRef]="rowDetailTemplateRef">
  </dynamic-table>

</ng-container>
