<div
  class="dynamic-table_filter-container"
  [style.display]="hideFilters ? 'none' : 'block'"
>
  <mat-form-field *ngIf="config?.pageLength">
    <mat-label>Einträge pro Seite</mat-label>
    <mat-select
      [(ngModel)]="config.pageLength"
      (selectionChange)="pageLengthSelectionChange($event)"
    >
      <mat-option *ngFor="let entryCount of rowsPerPage" [value]="entryCount">
        {{entryCount}} pro Seite
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Suchbegriff</mat-label>
    <input
      matInput
      placeholder="Placeholder"
      autocomplete="off"
      [(ngModel)]="page.search"
      (keyup)="searchSubject.next(page.search)"
      placeholder="Suchbegriff"
      #searchInputRef
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <ng-content select="generic-dropdown-filter,div"></ng-content>
</div>
<div class="dynamic-table">
  <ngx-datatable
    #dynamicTableRef
    class="expandable bootstrap"
    [rows]="rows"
    [columns]="config.columns"
    [columnMode]="'force'"
    [headerHeight]="40"
    [footerHeight]="40"
    [rowHeight]="60"
    [externalPaging]="true"
    [externalSorting]="true"
    [count]="page.count"
    [offset]="page.offset"
    [limit]="page.limit"
    [sortType]="'single'"
    [scrollbarH]="true"
    [selectionType]="observers.multiSelection ? 'multiClick' : undefined"
    (activate)="onRowActivate($event)"
    (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
  >
    <ngx-datatable-row-detail [rowHeight]="'auto'">
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-row-detail-template
      >
        <div *ngIf="rowDetailTemplateRef" style="padding-left: 35px">
          <ng-container
            [ngTemplateOutlet]="rowDetailTemplateRef"
            [ngTemplateOutletContext]="{row:row}"
          >
          </ng-container>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
  </ngx-datatable>
</div>
