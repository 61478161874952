<global-loading></global-loading>
<ng-container *ngIf="{ activeRoute: activeRoute$ | async } as data">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
    >
      <mat-toolbar>MSB Admin</mat-toolbar>
      <mat-nav-list>
        <a
          mat-list-item
          *ngFor="let item of navItems"
          [href]="item.route"
          [attr.style]=" data?.activeRoute?.route ===  item.route ? 'font-weight: bold' : ''"
        >
          <div class="maxwidth-elipsis" [title]="item.title">
            <mat-icon>{{item.icon}}</mat-icon>&nbsp;&nbsp; {{item.title}}
          </div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>{{data?.activeRoute?.title}}</span>
      </mat-toolbar>
      <!-- Content -->
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
