import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateBookingsTableService } from './filter-state-bookings-table.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { DatabaseModelUserBookedTrip } from '../../../apis/msb/models/database-model-user-booked-trip';
import { DatabaseModelUserBookedTripResponseModelDataTableResult } from '../../../apis/msb/models/database-model-user-booked-trip-response-model-data-table-result';

@Component({
  selector: 'app-manage-bookings-table',
  templateUrl: './bookings-table.page.html',
  styleUrls: ['./bookings-table.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingsTableComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  /**
   * Returns Total Booking Volume.
   * WARNING: At some point we need to make a own backend method for this
   * The filters dateFrom and dateTo can also be incorporated to show the booking volume
   */
  readonly totalBookingVolume$ = this.adminService
    .streamBookings({
      body: {
        start: 0,
        sortDirection: 'desc',
        sortColumn: 'id',
        searchValue: '',
        length: 1000000,
        draw: 3,
        customFilter: []
      }
    })
    .pipe(
      map((bookingResponse) =>
        this._transformResponse(bookingResponse)
          .data.map(
            (x: DatabaseModelUserBookedTrip & { bookingDataParsed?: any }) =>
              x.bookingDataParsed.trip.totalPrice
          )
          .reduce((pv, cv) => pv + cv, 0)
      ),
      shareReplay(1)
    );

  constructor(
    readonly filterStateService: FilterStateBookingsTableService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      return this.filter$.pipe(
        filter((fltr) => !!fltr?.dateTo && !!fltr?.dateFrom),
        tap((fltr) => {
          if (fltr?.dateFrom) {
            const customFilterKey = 'dateFrom';
            if (!request.customFilter.some((x) => customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.dateFrom
              });
            }
          }

          if (fltr?.dateTo) {
            const customFilterKey = 'dateTo';
            if (!request.customFilter.some((x) => customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.dateTo
              });
            }
          }
        }),
        switchMap((fltr) =>
          this.adminService
            .streamBookings({ body: request })
            .pipe(
              map((bookingResponse) => this._transformResponse(bookingResponse))
            )
        )
      );
    };
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }

  /**
   * Transform DataTable Result.
   * Iterates over each data row and parses bookingData as JSON to a new property called 'bookingDataParsed'.
   */
  private _transformResponse(
    bookingResponse: DatabaseModelUserBookedTripResponseModelDataTableResult
  ): DatabaseModelUserBookedTripResponseModelDataTableResult {
    (bookingResponse.data as any[]).forEach(
      (booking) => (booking.bookingDataParsed = JSON.parse(booking.bookingData))
    );
    return bookingResponse;
  }
}
