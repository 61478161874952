import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router, Event } from '@angular/router';
import { GlobalLoadingService } from '../../services/global-loading.service';

@Component({
  selector: 'global-loading',
  templateUrl: './global-loading.component.html',
  styleUrls: ['./global-loading.component.scss']
})
export class GlobalLoadingComponent implements OnInit, OnDestroy {

  private urlBlacklist = [
    '/',
    '/auth/login'
  ];

  private subscriptions: Array<any> = [];

  constructor(
    public globalLoadingService: GlobalLoadingService,
    public router: Router
  ) { }

  /**
   * Listens to router events
   */
  ngOnInit() {
    this.subscriptions.push(this.router.events.subscribe(routerEvent => this.checkRouterEvent(routerEvent)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Checks router event and sets properties navigationComplete and componentInitialized according to state
   */
  private checkRouterEvent(event: Event): void {
    let urlBlacklisted = false;
    if (event && (<any>event).url) {
      urlBlacklisted = this.urlBlacklist.some(url => url === (<any>event).url);
    }
    if (urlBlacklisted) {
      this.globalLoadingService.release();
    }
    if (!urlBlacklisted && event instanceof NavigationStart) {
      this.globalLoadingService.showGlobalLoader();
    } else if (event instanceof NavigationEnd) {
      this.globalLoadingService.globalLoadingStates.navigationComplete = true;
    } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.globalLoadingService.globalLoadingStates.navigationComplete = true;
    }
  }

}
