<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateSavedRef let-row="row">
          {{row?.saveOn | date : 'dd.MM.yy'}} @ {{row?.saveOn | date :
          'shortTime'}}
        </ng-template>

        <ng-template #rowDetailTemplateRef let-row="row">
          <div style="max-width: 85vw">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>timeline</mat-icon>&nbsp; Abreisetermine
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <app-manage-timetables [cruiseId]="row.cruiseId">
                </app-manage-timetables>
              </ng-template>
            </mat-expansion-panel>
          </div>
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'routeId', name: 'Routen Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'routeTimetable.route.name', name: 'Routenname', sortable: false },
        { prop: 'routeTimetable.ship.shipname', name: 'Schiff', sortable: false },
        { prop: 'routeTimetable.shippingCompany.name', name: 'Reederei', sortable: false },
        { prop: 'adults', name: 'Erwachsene', sortable: false },
        { prop: 'children', name: 'Kinder', sortable: false },
        { prop: 'childrenAges', name: 'Kinder-alter', sortable: false },
        { prop: 'cabinType', name: 'Kabinentyp'},
        { prop: 'priceModels', name: 'Tariffe', sortable: false },
        { prop: 'airportSelected', name: 'Flughafen', sortable: false },
        { prop: 'wishBudget', name: 'Wünschbudget', sortable: false },
        { prop: 'wishPriceReached', name: 'Maximalbudget erreicht', sortable: false },
        { prop: 'wishPriceAlmostReached', name: 'Maximalbudget fast erreicht', sortable: false },
        { prop: 'priceIncreased', name: 'Preisänderung', sortable: false },
        { prop: 'userId', name: 'User Id' },
        { prop: 'user.firstName', name: 'Vorname' , sortable: false },
        { prop: 'user.lastName', name: 'Nachname' , sortable: false },
        { prop: 'user.email', name: 'E-Mail' , sortable: false },
        { prop: 'user.customerNumber', name: 'Kundennummer', sortable: false },
        { prop: 'saveOn', name: 'Datum hinzugefügt', cellTemplate: cellDateSavedRef}
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
