
<ng-template #cellDateAddedRef let-row="row">
  {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date : 'shortTime'}}
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'routeId',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'airportStartFrom', name: 'Airport Start From' },
        { prop: 'airportStartTo', name: 'Airport Start To' },
        { prop: 'airportEndFrom', name: 'Airport End From' },
        { prop: 'airportEndTo', name: 'Airport End To' },
        { prop: 'priceFlightAdult', name: 'Price Flight Adult' },
        { prop: 'priceFlightChild', name: 'Price Flight Child' },
        { prop: 'dateAdded', name: 'Date Added', cellTemplate: cellDateAddedRef }
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()">
  </dynamic-table>

</ng-container>
