/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestModalRegistration } from '../models/request-modal-registration';
import { RequestModelAuthStatus } from '../models/request-model-auth-status';
import { StringServiceResponse } from '../models/string-service-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authStatus
   */
  static readonly AuthStatusPath = '/api/Auth/auth_status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authStatus$Response(params?: {
    context?: HttpContext
    body?: RequestModelAuthStatus
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authStatus(params?: {
    context?: HttpContext
    body?: RequestModelAuthStatus
  }
): Observable<boolean> {

    return this.authStatus$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation register
   */
  static readonly RegisterPath = '/api/Auth/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `register()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register$Response(params?: {
    context?: HttpContext
    body?: RequestModalRegistration
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.RegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `register$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register(params?: {
    context?: HttpContext
    body?: RequestModalRegistration
  }
): Observable<StringServiceResponse> {

    return this.register$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = '/api/Auth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method doesn't expect any request body.
   */
  login$Response(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  login(params?: {
    email?: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StringServiceResponse> {

    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

}
