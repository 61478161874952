
<ng-template #cellDateSavedRef let-row="row">
  {{row?.saveOn | date : 'dd.MM.yy'}} @ {{row?.saveOn | date : 'shortTime'}}
</ng-template>

<ng-template #rowDetailTemplateRef let-row="row">
  <div style="max-width: 85vw;">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>timeline</mat-icon>&nbsp; Abreisetermine
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-timetables-table [routeId]="row.routeId">
        </app-timetables-table>
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' }
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()" [enableRowDetails]="true" [rowDetailTemplateRef]="rowDetailTemplateRef">
  </dynamic-table>

</ng-container>
