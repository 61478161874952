import { Pipe, PipeTransform } from '@angular/core';
import { GlobalDataService } from '../services/global-data.service';

@Pipe({
  name: 'logEntryFormatter'
})
export class LogEntryFormatterPipe implements PipeTransform {

  constructor(
    private _globalDataService: GlobalDataService
  ) { }

  transform(logKey: string): string {
    let label = logKey;
    if (
      this._globalDataService.LogTypes &&
      this._globalDataService.LogTypes.length
    ) {
      const logKvpMatch: any = this._globalDataService.LogTypes.find(x => x.key === logKey);
      if (
        logKvpMatch &&
        logKvpMatch.value
      ) {
        label = logKvpMatch.value;
      }
    }
    return label;
  }

}
