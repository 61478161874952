import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalLoadingService } from 'src/app/shared/services/global-loading.service';
import { DatabaseModelAccount } from 'src/app/apis/core/models';
import { RequestModelPaged } from 'src/app/apis/core/models';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-observations-table',
  templateUrl: './observations-table.component.html',
  styleUrls: ['./observations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObservationsTableComponent {
  @Input() userId?: number;

  constructor(
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      if (this.userId) {
        const customFilterKey = 'userId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push({
            key: customFilterKey,
            value: this.userId.toString(10)
          });
        }
      }
      return this.adminService.streamObservations({ body: request });
    };
  }

  clickRow(account: DatabaseModelAccount) {
    console.log('clickRow');
  }
}
