<ng-template #cellDateAddedRef let-row="row">
  {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date : 'shortTime'}}
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'userId', name: 'User-Id' },
        { prop: 'fcmToken', name: 'Fcm-Token' },
        { prop: 'platform', name: 'Platform' },
        { prop: 'language', name: 'Sprache' },
        { prop: 'version', name: 'Version' },
        { prop: 'deviceId', name: 'Device-Id'},
        { prop: 'dateAdded', name: 'Datum-add', cellTemplate: cellDateAddedRef },
        { prop: 'trackingStatus', name: 'Tracking-status' },
        { prop: 'notificationsEnabled', name: 'Noti-enabled' }
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()">
  </dynamic-table>

</ng-container>

