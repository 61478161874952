import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateAccountsService } from './filter-state-accounts.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelPaged } from '../../../apis/core/models/request-model-paged';
import { DatabaseModelUser } from '../../../apis/msb/models/database-model-user';
import { map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './accounts.page.html',
  styleUrls: ['./accounts.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountsComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly loading$ = new BehaviorSubject<LoadingStates>(LoadingStates.LOADING);

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  constructor(
    readonly filterStateService: FilterStateAccountsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) =>
      this.adminService.streamUsers({
        body: request
      });
  }

  clickRow() {
    this.loading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.loading$.next(LoadingStates.LOADED);
    }, 500);
  }

  async saveChanges(updated, original) {
    const newPassword = updated.tmpPassword;
    delete updated.tmpPassword;
    const user: DatabaseModelUser = {
      ...original,
      ...updated
    };

    const saved = await this.adminService
      .updateUser({
        body: user,
        newPassword: newPassword ?? ''
      })
      .toPromise();

    if (saved) {
      alert('Speichern Erfolgreich');
      this._dynamicTableComponent.reloadTable();
    } else {
      alert(
        'Speichern Fehlgeschlagen! Bitte kontaktiere Melvin vom App Support!'
      );
    }
  }
}
