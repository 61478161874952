import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { FilterStateFlightPricesService } from './filter-state-flight-prices.service';

@Injectable({
  providedIn: 'root'
})
export class FilterStateFlightPricesResolver implements Resolve<Observable<boolean>> {
  constructor(private readonly router: Router, private readonly _filterStateService: FilterStateFlightPricesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._filterStateService.initRoutingState(route);
  }
}
