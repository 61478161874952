import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import * as moment from 'moment';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import {
  INavigationStateService,
  InitializeNavigationStateHandler,
  NavigationStateHandler,
  UltraDynamicFilterConfig,
  UltraDynamicFilterStateMap
} from '../../../shared/navigation/navigation-state-handler.class';

export interface BookingsFilterState {
  dateFrom?: string;
  dateTo?: string;
  dimension?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStateBookingsService
  implements INavigationStateService<BookingsFilterState>
{
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<BookingsFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.ANALYTICS_BOOKINGS];
  }

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.ANALYTICS_BOOKINGS,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      // TODO configure filters
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateFrom',
        label: 'Datum von (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateTo',
        label: 'Datum bis (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'dimension',
        label: 'Zeit-Dimension',
        iconName: 'date_range',
        required: true,
        options$: of([
          {
            id: 'yyyy-MM-dd',
            label: 'Nach Tag (YYYY-MM-DD)'
          },
          {
            id: 'y',
            label: 'Nach Monat (YYYY-MM)'
          },
          {
            id: 'yyyy',
            label: 'Nach Jahr (YYYY)'
          }
        ]),
        defaultValue: 'yyyy-MM-dd'
      }
    ]
  };

  constructor(protected readonly _router: Router) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(
      PATHS.MSB,
      this.config,
      _router
    );
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): BookingsFilterState {
    const parsedFilter: BookingsFilterState = {};

    if (fltr?.dateFrom) {
      parsedFilter.dateFrom = fltr.dateFrom;
    }

    if (fltr?.dateTo) {
      parsedFilter.dateTo = fltr.dateTo;
    }

    if (fltr?.dimension) {
      parsedFilter.dimension = fltr.dimension;
    }

    return parsedFilter;
  }
}
