<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellCruiseIdsTemplateRef let-row="row">
          {{row?.cruiseIds?.length ? row.cruiseIds.length : 0}}
        </ng-template>

        <ng-template #cellValidFromDateTemplateRef let-row="row">
          {{row?.validFrom | date : 'dd.MM.yy'}}
        </ng-template>

        <ng-template #cellValidTillDateTemplateRef let-row="row">
          {{row?.validTill | date : 'dd.MM.yy'}}
        </ng-template>

        <ng-template #cellDateCreatedTemplateRef let-row="row">
          {{row?.dateCreated | date : 'dd.MM.yy'}} @ {{row?.dateCreated | date :
          'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'offerType', name: 'PROMO Code' },
        { prop: 'offerTypeLabel', name: 'Anzeigelabel' },
        { prop: 'dashboardTitle', name: 'Dashboard Titel' },
        { prop: 'cruiseIds', name: 'Abreisetermine', cellTemplate: cellCruiseIdsTemplateRef },
        { prop: 'validFrom', name: 'Gültig von', cellTemplate: cellValidFromDateTemplateRef },
        { prop: 'validTill', name: 'Gültig bis', cellTemplate: cellValidTillDateTemplateRef },
        { prop: 'dateCreated', name: 'Hinzugefügt am', cellTemplate: cellDateCreatedTemplateRef },
        { prop: 'note', name: 'Notiz' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            #dynamicTableRef
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
            <ng-template #rowDetailTemplateRef let-row="row">
              <div style="max-width: 90vw">
                <app-tagged-timetables-form
                  [taggedTimetable]="row"
                  (saved)="dynamicTableRef.reloadTable()"
                >
                </app-tagged-timetables-form>
              </div>
            </ng-template>
          </dynamic-table>
          <mat-accordion
            style="width: 100%"
            *ngIf="{ saved: false } as expData"
          >
            <mat-expansion-panel
              #expansionPanelRef
              (afterExpand)="expData.saved = false;"
            >
              <mat-expansion-panel-header>
                <mat-panel-title style="display: flex; justify-content: right">
                  Neuen Eintrag hinzufügen
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <app-tagged-timetables-form
                  *ngIf="!expData.saved"
                  (saved)="expData.saved = true;expansionPanelRef.close();dynamicTableRef.reloadTable()"
                >
                </app-tagged-timetables-form>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>
