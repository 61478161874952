/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelPrice } from '../models/database-model-price';
import { DatabaseModelRoute } from '../models/database-model-route';

@Injectable({
  providedIn: 'root',
})
export class SearchRoutesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRoutes_1
   */
  static readonly GetRoutes_1Path = '/api/SearchRoutes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoutes_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutes_1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchRoutesService.GetRoutes_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelRoute>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoutes_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutes_1(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelRoute>> {

    return this.getRoutes_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelRoute>>) => r.body as Array<DatabaseModelRoute>)
    );
  }

  /**
   * Path part for operation getRouteIDsByNormalSearch
   */
  static readonly GetRouteIDsByNormalSearchPath = '/api/SearchRoutes/{from}/{to}/{flightInclusive}/{budget}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRouteIDsByNormalSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteIDsByNormalSearch$Response(params: {
    from: string;
    to: string;
    flightInclusive: boolean;
    budget: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelPrice>>> {

    const rb = new RequestBuilder(this.rootUrl, SearchRoutesService.GetRouteIDsByNormalSearchPath, 'get');
    if (params) {
      rb.path('from', params.from, {});
      rb.path('to', params.to, {});
      rb.path('flightInclusive', params.flightInclusive, {});
      rb.path('budget', params.budget, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelPrice>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRouteIDsByNormalSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteIDsByNormalSearch(params: {
    from: string;
    to: string;
    flightInclusive: boolean;
    budget: number;
    context?: HttpContext
  }
): Observable<Array<DatabaseModelPrice>> {

    return this.getRouteIDsByNormalSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelPrice>>) => r.body as Array<DatabaseModelPrice>)
    );
  }

}
