/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelUploadedRouteFile } from '../models/database-model-uploaded-route-file';

@Injectable({
  providedIn: 'root',
})
export class UploadedRouteFilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllUploadedRouteFiles
   */
  static readonly GetAllUploadedRouteFilesPath = '/api/UploadedRouteFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUploadedRouteFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUploadedRouteFiles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>> {

    const rb = new RequestBuilder(this.rootUrl, UploadedRouteFilesService.GetAllUploadedRouteFilesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUploadedRouteFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUploadedRouteFiles(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelUploadedRouteFile>> {

    return this.getAllUploadedRouteFiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>) => r.body as Array<DatabaseModelUploadedRouteFile>)
    );
  }

  /**
   * Path part for operation getAllUploadedRouteFilesByRouteId
   */
  static readonly GetAllUploadedRouteFilesByRouteIdPath = '/api/UploadedRouteFiles/uploaded-route-files/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUploadedRouteFilesByRouteId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUploadedRouteFilesByRouteId$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>> {

    const rb = new RequestBuilder(this.rootUrl, UploadedRouteFilesService.GetAllUploadedRouteFilesByRouteIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUploadedRouteFilesByRouteId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUploadedRouteFilesByRouteId(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<DatabaseModelUploadedRouteFile>> {

    return this.getAllUploadedRouteFilesByRouteId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelUploadedRouteFile>>) => r.body as Array<DatabaseModelUploadedRouteFile>)
    );
  }

}
