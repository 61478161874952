import * as Highcharts from 'highcharts';
import { BookingsFilterState } from '../../pages/analytics/bookings/filter-state-bookings.service';
import { HighchartsChartData } from '../../apis/msb/models/highcharts-chart-data';
import { omit } from 'lodash';
import { CustomerInteractionFilterState } from '../../pages/analytics/customer-interaction/filter-state-customer-interaction.service';

export const LabelFormatter = (value: number): string => {
  if (value > 0) {
    if (value >= Math.pow(10, 9)) {
      return Highcharts.numberFormat(value / Math.pow(10, 9), 2) + 'B';
    } else if (value >= Math.pow(10, 6)) {
      return Highcharts.numberFormat(value / Math.pow(10, 6), 2) + 'M';
    } else if (value >= Math.pow(10, 3)) {
      return Highcharts.numberFormat(value / Math.pow(10, 3), 2) + 'K';
    } else {
      return Highcharts.numberFormat(value, 0);
    }
  }
};

export const HighChartOptions = (
  fltr: BookingsFilterState | CustomerInteractionFilterState,
  hcData: HighchartsChartData,
  isPieChart: boolean = false,
  yAxisTitle: string,
  isPieChartEnabled: boolean = false
): Highcharts.Options => {
  const isDrillDownAvailable = hcData?.drilldownSeries?.length > 0;
  let chartOptions: Highcharts.Options = {};

  chartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: hcData?.title
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: hcData.categories,
      max: fltr?.dimension === 'yyyy' ? hcData?.categories?.length : 7,
      scrollbar: {
        enabled: true
      }
    },
    yAxis: {
      title: {
        text: yAxisTitle
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return LabelFormatter(this.y);
          }
        }
      }
    },
    series: hcData.series as any
  };

  if (isPieChart) {
    chartOptions = {
      ...omit(chartOptions, ['plotOptions', 'xAxis', 'yAxis', 'chart']),
      chart: {
        type: 'pie'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return (
                '<b>' +
                this.key +
                '</b>: ' +
                Highcharts.numberFormat(this.percentage, 1) +
                ' %'
              );
            }
          }
        }
      },
      tooltip: {
        // pointFormat:
        //   '<b>{series.name}</b>:<br>{point.percentage:.1f} %<br>total: ',
        formatter: function () {
          return (
            '<b>' +
            this.key +
            '</b>: ' +
            Highcharts.numberFormat(this.percentage, 1) +
            ' %' +
            '<br/><b>' +
            LabelFormatter(this.y) +
            '</b> '
          );
        }
      }
    };
  }

  if (isDrillDownAvailable) {
    const chartData = chartOptions.chart;
    chartOptions = {
      ...omit(chartOptions, ['chart']),
      chart: {
        //the following is done to hide the x axis and y axis labels (currently only pie chart drill down is implemented). If other chart types are to be implemented, adjust the code accordingly
        ...chartData,
        events: {
          drilldown() {
            const chart = this;

            chart.axes.forEach((axis) =>
              axis.update({ visible: false }, false)
            );
            chart.reflow();
          },
          drillup() {
            const chart = this;

            chart.axes.forEach((axis) => axis.update({ visible: true }, false));
            chart.reflow();
          }
        }
      },
      drilldown: {
        series: hcData.drilldownSeries.map((ds) => {
          const mappedData: [string, number][] = ds.data.map((s) => {
            return [s?.key, s?.value];
          });
          const retValue = {
            ...omit(ds, ['data']),
            data: mappedData
          };
          return retValue;
        }) as any
      }
    };
  }

  //TODO: fix the following commented out code for enabling the pie chart for the logs(customer-interaction)

  // if (isPieChartEnabled) {
  //   debugger;
  //   const seriesData = [
  //     {
  //       name: 'Percentage',
  //       colorByPoint: true,
  //       data: hcData.series.map((series) => ({
  //         name: series.name,
  //         y: series.data.reduce((a, b) => a + b?.y, 0)
  //       }))
  //     }
  //   ];
  //
  //   const pieChartOptions: Highcharts.Options = {
  //     chart: {
  //       plotBackgroundColor: null,
  //       plotBorderWidth: null,
  //       plotShadow: false,
  //       type: 'pie'
  //     },
  //     title: {
  //       text: hcData.title
  //     },
  //     tooltip: {
  //       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  //     },
  //     accessibility: {
  //       point: {
  //         valueSuffix: '%'
  //       }
  //     },
  //     plotOptions: {
  //       pie: {
  //         allowPointSelect: true,
  //         cursor: 'pointer',
  //         dataLabels: {
  //           enabled: true,
  //           format: '<b>{point.name}</b>: {point.percentage:.1f} %'
  //         }
  //       }
  //     },
  //     series: seriesData as any
  //   };
  //
  //   return pieChartOptions;
  // }

  return chartOptions;
};
