import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { KeyValuePairStringString } from 'src/app/apis/core/models';
import { AdminService } from '../../apis/msb/services/admin.service';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  public AccessToken = '';
  public LogTypes: Array<KeyValuePairStringString>;
}

@Injectable({ providedIn: 'root' })
export class GlobalDataResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private _adminService: AdminService,
    private _globalDataService: GlobalDataService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!route?.queryParams?.token && !localStorage.getItem('APP_AUTH_USER')) {
      return null;
    } else if (route?.queryParams?.token) {
      this._globalDataService.AccessToken = route.queryParams.token;
      localStorage.setItem('APP_AUTH_USER', route.queryParams.token);
      this.router.navigate([], {
        queryParams: {
          token: null
        },
        queryParamsHandling: 'merge'
      });
    } else if (localStorage.getItem('APP_AUTH_USER') != null) {
      this._globalDataService.AccessToken =
        localStorage.getItem('APP_AUTH_USER');
    } else {
      return null; // it's impossible to get here, but leave it here just in case a future change doesn't break things horribly
    }
    return forkJoin(this._adminService.getLogTypes()).pipe(
      tap((responses) => {
        this._globalDataService.LogTypes = responses[0];
      }),
      switchMap(() => of(true))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _globalDataService: GlobalDataService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this._globalDataService.AccessToken) {
      request = request.clone({
        setHeaders: {
          XAuthorization: `Token ${this._globalDataService.AccessToken}`
        }
      });
    }
    return next.handle(request);
  }
}
