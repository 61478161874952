import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalLoadingService } from 'src/app/shared/services/global-loading.service';
import { DatabaseModelAccount } from 'src/app/apis/core/models';
import { RequestModelPaged } from 'src/app/apis/core/models';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-timetables-table',
  templateUrl: './timetables-table.component.html',
  styleUrls: ['./timetables-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimetablesTableComponent {
  @Input() routeId?: number;
  @Input() cruiseId?: string;

  constructor(
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      if (this.routeId) {
        const customFilterKey = 'routeId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push({
            key: customFilterKey,
            value: this.routeId.toString(10)
          });
        }
      }
      if (this.cruiseId) {
        const customFilterKey = 'cruiseId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push({
            key: customFilterKey,
            value: this.cruiseId
          });
        }
      }
      return this.adminService.streamTimetables({ body: request });
    };
  }

  clickRow(account: DatabaseModelAccount) {
    console.log('clickRow');
  }
}
