<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateSavedRef let-row="row">
          {{row?.saveOn | date : 'dd.MM.yy'}} @ {{row?.saveOn | date :
          'shortTime'}}
        </ng-template>

        <ng-template #rowDetailTemplateRef let-row="row">
          <div style="max-width: 85vw">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>timeline</mat-icon>&nbsp; Abreisetermine
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <app-manage-timetables [routeId]="row.route.id">
                </app-manage-timetables>
              </ng-template>
            </mat-expansion-panel>
          </div>
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'route.id', name: 'Route Id' },
        { prop: 'route.name', name: 'Route Name' },
        { prop: 'user.id', name: 'User Id' },
        { prop: 'user.firstName', name: 'User Vorname' },
        { prop: 'user.lastName', name: 'User Nachname' },
        { prop: 'user.email', name: 'User E-Mail' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
