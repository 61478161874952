import { PricePredictionsComponent } from './pages/manage/price-predictions/price-predictions.page';
import { FilterStatePricePredictionsResolver } from './pages/manage/price-predictions/filter-state-price-predictions.resolver';
import { OverviewComponent } from './pages/analytics/overview/overview.page';
import { FilterStateOverviewResolver } from './pages/analytics/overview/filter-state-overview.resolver';
import { CustomerInteractionComponent } from './pages/analytics/customer-interaction/customer-interaction.page';
import { FilterStateCustomerInteractionResolver } from './pages/analytics/customer-interaction/filter-state-customer-interaction.resolver';
import { FavouriteRoutesComponent } from './pages/manage/favourite-routes/favourite-routes.page';
import { FilterStateFavouriteRoutesResolver } from './pages/manage/favourite-routes/filter-state-favourite-routes.resolver';
import { ObservationsComponent } from './pages/manage/observations/observations.page';
import { FilterStateObservationsResolver } from './pages/manage/observations/filter-state-observations.resolver';
import { BudgetObservationsComponent } from './pages/manage/budget-observations/budget-observations.page';
import { FilterStateBudgetObservationsResolver } from './pages/manage/budget-observations/filter-state-budget-observations.resolver';
import { CabinsComponent } from './pages/manage/cabins/cabins.page';
import { FilterStateCabinsResolver } from './pages/manage/cabins/filter-state-cabins.resolver';
import { ShipsComponent } from './pages/manage/ships/ships.page';
import { FilterStateShipsResolver } from './pages/manage/ships/filter-state-ships.resolver';
import { ShippingCompaniesComponent } from './pages/manage/shipping-companies/shipping-companies.page';
import { FilterStateShippingCompaniesResolver } from './pages/manage/shipping-companies/filter-state-shipping-companies.resolver';
import { TaggedTimetablesComponent } from './pages/manage/tagged-timetables/tagged-timetables.page';
import { FilterStateTaggedTimetablesResolver } from './pages/manage/tagged-timetables/filter-state-tagged-timetables.resolver';
import { SpecialPricesComponent } from './pages/manage/special-prices/special-prices.page';
import { FilterStateSpecialPricesResolver } from './pages/manage/special-prices/filter-state-special-prices.resolver';
import { LivePriceDropsComponent } from './pages/manage/live-price-drops/live-price-drops.page';
import { FilterStateLivePriceDropsResolver } from './pages/manage/live-price-drops/filter-state-live-price-drops.resolver';
import { ShopifyCouponsComponent } from './pages/manage/shopify-coupons/shopify-coupons.page';
import { FilterStateShopifyCouponsResolver } from './pages/manage/shopify-coupons/filter-state-shopify-coupons.resolver';
import { RoutesComponent } from './pages/manage/routes/routes.page';
import { FilterStateRoutesResolver } from './pages/manage/routes/filter-state-routes.resolver';
import { TimetablesComponent } from './pages/manage/timetables/timetables.page';
import { FilterStateTimetablesResolver } from './pages/manage/timetables/filter-state-timetables.resolver';
import { HistoricalPricesComponent } from './pages/manage/historical-prices/historical-prices.page';
import { FilterStateHistoricalPricesResolver } from './pages/manage/historical-prices/filter-state-historical-prices.resolver';
import { LivePricesComponent } from './pages/manage/live-prices/live-prices.page';
import { FilterStateLivePricesResolver } from './pages/manage/live-prices/filter-state-live-prices.resolver';
import { FlightPricesComponent } from './pages/manage/flight-prices/flight-prices.page';
import { FilterStateFlightPricesResolver } from './pages/manage/flight-prices/filter-state-flight-prices.resolver';
import { NotificationsDeletedComponent } from './pages/manage/notifications-deleted/notifications-deleted.page';
import { FilterStateNotificationsDeletedResolver } from './pages/manage/notifications-deleted/filter-state-notifications-deleted.resolver';
import { NotificationsSentComponent } from './pages/manage/notifications-sent/notifications-sent.page';
import { FilterStateNotificationsSentResolver } from './pages/manage/notifications-sent/filter-state-notifications-sent.resolver';
import { FcmTokensComponent } from './pages/manage/fcm-tokens/fcm-tokens.page';
import { FilterStateFcmTokensResolver } from './pages/manage/fcm-tokens/filter-state-fcm-tokens.resolver';
import { BookingsTableComponent } from './pages/manage/bookings-table/bookings-table.page';
import { FilterStateBookingsTableResolver } from './pages/manage/bookings-table/filter-state-bookings-table.resolver';
import { AccountsComponent } from './pages/manage/accounts/accounts.page';
import { FilterStateAccountsResolver } from './pages/manage/accounts/filter-state-accounts.resolver';
import { BookingsComponent } from './pages/analytics/bookings/bookings.page';
import { FilterStateBookingsResolver } from './pages/analytics/bookings/filter-state-bookings.resolver';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GlobalDataResolver } from './shared/services/global-data.service';
import { PATHS } from './shared/enums/paths.enum';
import { ROUTE } from './shared/enums/route.enum';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    resolve: {
      data: GlobalDataResolver
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      data: GlobalDataResolver
    }
  },
  {
    path: PATHS.MSB,
    resolve: {
      globalData: GlobalDataResolver
    },
    children: [
      {
        path: ROUTE.ANALYTICS,
        component: LoginComponent
      },
      {
        path: ROUTE.ANALYTICS_BOOKINGS,
        component: BookingsComponent,
        resolve: {
          init: FilterStateBookingsResolver
        }
      },
      {
        path: ROUTE.MANAGE_ACCOUNTS,
        component: AccountsComponent,
        resolve: {
          init: FilterStateAccountsResolver
        }
      },
      {
        path: ROUTE.MANAGE_BOOKINGS_TABLE,
        component: BookingsTableComponent,
        resolve: {
          init: FilterStateBookingsTableResolver
        }
      },
      {
        path: ROUTE.MANAGE_FCM_TOKENS,
        component: FcmTokensComponent,
        resolve: {
          init: FilterStateFcmTokensResolver
        }
      },
      {
        path: ROUTE.MANAGE_NOTIFICATIONS_SENT,
        component: NotificationsSentComponent,
        resolve: {
          init: FilterStateNotificationsSentResolver
        }
      },
      {
        path: ROUTE.MANAGE_NOTIFICATIONS_DELETED,
        component: NotificationsDeletedComponent,
        resolve: {
          init: FilterStateNotificationsDeletedResolver
        }
      },
      {
        path: ROUTE.MANAGE_FLIGHT_PRICES,
        component: FlightPricesComponent,
        resolve: {
          init: FilterStateFlightPricesResolver
        }
      },
      {
        path: ROUTE.MANAGE_LIVE_PRICES,
        component: LivePricesComponent,
        resolve: {
          init: FilterStateLivePricesResolver
        }
      },
      {
        path: ROUTE.MANAGE_HISTORICAL_PRICES,
        component: HistoricalPricesComponent,
        resolve: {
          init: FilterStateHistoricalPricesResolver
        }
      },
      {
        path: ROUTE.MANAGE_TIMETABLES,
        component: TimetablesComponent,
        resolve: {
          init: FilterStateTimetablesResolver
        }
      },
      {
        path: ROUTE.MANAGE_ROUTES,
        component: RoutesComponent,
        resolve: {
          init: FilterStateRoutesResolver
        }
      },
      {
        path: ROUTE.MANAGE_SHOPIFY_COUPONS,
        component: ShopifyCouponsComponent,
        resolve: {
          init: FilterStateShopifyCouponsResolver
        }
      },
      {
        path: ROUTE.MANAGE_LIVE_PRICE_DROPS,
        component: LivePriceDropsComponent,
        resolve: {
          init: FilterStateLivePriceDropsResolver
        }
      },
      {
        path: ROUTE.MANAGE_SPECIAL_PRICES,
        component: SpecialPricesComponent,
        resolve: {
          init: FilterStateSpecialPricesResolver
        }
      },
      {
        path: ROUTE.MANAGE_TAGGED_TIMETABLES,
        component: TaggedTimetablesComponent,
        resolve: {
          init: FilterStateTaggedTimetablesResolver
        }
      },
      {
        path: ROUTE.MANAGE_SHIPPING_COMPANIES,
        component: ShippingCompaniesComponent,
        resolve: {
          init: FilterStateShippingCompaniesResolver
        }
      },
      {
        path: ROUTE.MANAGE_SHIPS,
        component: ShipsComponent,
        resolve: {
          init: FilterStateShipsResolver
        }
      },
      {
        path: ROUTE.MANAGE_CABINS,
        component: CabinsComponent,
        resolve: {
          init: FilterStateCabinsResolver
        }
      },
      {
        path: ROUTE.MANAGE_BUDGET_OBSERVATIONS,
        component: BudgetObservationsComponent,
        resolve: {
          init: FilterStateBudgetObservationsResolver
        }
      },
      {
        path: ROUTE.MANAGE_OBSERVATIONS,
        component: ObservationsComponent,
        resolve: {
          init: FilterStateObservationsResolver
        }
      },
      {
        path: ROUTE.MANAGE_FAVOURITE_ROUTES,
        component: FavouriteRoutesComponent,
        resolve: {
          init: FilterStateFavouriteRoutesResolver
        }
      },
      {
        path: ROUTE.ANALYTICS_CUSTOMER_INTERACTION,
        component: CustomerInteractionComponent,
        resolve: {
          init: FilterStateCustomerInteractionResolver
        }
      },
      {
        path: ROUTE.ANALYTICS_OVERVIEW,
        component: OverviewComponent,
        resolve: {
          init: FilterStateOverviewResolver
        }
      },
      {
        path: ROUTE.MANAGE_PRICE_PREDICTIONS,
        component: PricePredictionsComponent,
        resolve: {
          init: FilterStatePricePredictionsResolver
        }
      }
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: !environment.production })
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {}
