import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { AdminService } from '../../apis/msb/services/admin.service';
import { CabinsService } from '../../apis/msb/services/cabins.service';
import { Observable } from 'rxjs';
import { DatabaseModelPrice } from '../../apis/msb/models/database-model-price';

@Injectable({
  providedIn: 'root'
})
export class CruiseMetadataService {
  readonly companies$ = this._adminService
    .streamShippingCompanies({
      body: {
        start: 0,
        sortDirection: 'asc',
        sortColumn: 'id',
        searchValue: '',
        length: 100000,
        draw: 1,
        customFilter: []
      }
    })
    .pipe(
      map((x) => {
        return x?.data;
      }),
      shareReplay(1)
    );

  readonly ships$ = this._adminService
    .streamShips({
      body: {
        start: 0,
        sortDirection: 'asc',
        sortColumn: 'id',
        searchValue: '',
        length: 100000,
        draw: 1,
        customFilter: []
      }
    })
    .pipe(
      map((x) => {
        return x?.data;
      }),
      shareReplay(1)
    );

  readonly routes$ = this._adminService
    .streamLiveRoutes({
      body: {
        start: 0,
        sortDirection: 'asc',
        sortColumn: 'id',
        searchValue: '',
        length: 100000,
        draw: 1,
        customFilter: []
      }
    })
    .pipe(
      map((x) => {
        return x?.data;
      }),
      shareReplay(1)
    );

  readonly timetables$ = this._adminService
    .streamTimetables({
      body: {
        start: 0,
        sortDirection: 'asc',
        sortColumn: 'id',
        searchValue: '',
        length: 100000,
        draw: 1,
        customFilter: []
      }
    })
    .pipe(
      map((x) => {
        return x?.data;
      }),
      shareReplay(1)
    );

  readonly cabinCategoryOptions$ = this._cabinsService
    .getCabinCategoryOptions()
    .pipe(
      map((cabins) =>
        cabins.map((c) => ({
          key: c.cabinCategoryCode,
          value: c.cabinCategoryName
        }))
      ),
      shareReplay(1)
    );

  constructor(
    private _adminService: AdminService,
    private _cabinsService: CabinsService
  ) {}
}
