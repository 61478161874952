/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customer } from '../models/customer';
import { DatabaseModelUser } from '../models/database-model-user';
import { ObjectServiceResponse } from '../models/object-service-response';
import { RequestModalRegistration } from '../models/request-modal-registration';
import { RequestModelChangePassword } from '../models/request-model-change-password';
import { RequestModelConfirmResetCode } from '../models/request-model-confirm-reset-code';
import { RequestModelForgotPassword } from '../models/request-model-forgot-password';
import { RequestModelSetNewPasswordAfterReset } from '../models/request-model-set-new-password-after-reset';
import { RequestModelUpdateOverrideItems } from '../models/request-model-update-override-items';
import { ResponseModelBookedTrip } from '../models/response-model-booked-trip';
import { ResponseModelBookingHistoryOverrideItem } from '../models/response-model-booking-history-override-item';
import { StringServiceResponse } from '../models/string-service-response';
import { Traveller } from '../models/traveller';
import { UpdateCustomerInfoRequest } from '../models/update-customer-info-request';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProfile
   */
  static readonly GetProfilePath = '/api/User/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DatabaseModelUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getProfile(params?: {
    context?: HttpContext
  }
): Observable<DatabaseModelUser> {

    return this.getProfile$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUser>) => r.body as DatabaseModelUser)
    );
  }

  /**
   * Path part for operation getCurrentUser
   */
  static readonly GetCurrentUserPath = '/api/User/current';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUser$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DatabaseModelUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetCurrentUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUser(params?: {
    context?: HttpContext
  }
): Observable<DatabaseModelUser> {

    return this.getCurrentUser$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUser>) => r.body as DatabaseModelUser)
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/api/User/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DatabaseModelUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {
    id: number;
    context?: HttpContext
  }
): Observable<DatabaseModelUser> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUser>) => r.body as DatabaseModelUser)
    );
  }

  /**
   * Path part for operation getTravelGroup
   */
  static readonly GetTravelGroupPath = '/api/User/travel-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTravelGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelGroup$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Traveller>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetTravelGroupPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Traveller>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTravelGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelGroup(params?: {
    context?: HttpContext
  }
): Observable<Array<Traveller>> {

    return this.getTravelGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Traveller>>) => r.body as Array<Traveller>)
    );
  }

  /**
   * Path part for operation tryAddBewotecCustomerId
   */
  static readonly TryAddBewotecCustomerIdPath = '/api/User/try-add-customerid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tryAddBewotecCustomerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tryAddBewotecCustomerId$Response(params?: {
    context?: HttpContext
    body?: RequestModalRegistration
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.TryAddBewotecCustomerIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tryAddBewotecCustomerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tryAddBewotecCustomerId(params?: {
    context?: HttpContext
    body?: RequestModalRegistration
  }
): Observable<StringServiceResponse> {

    return this.tryAddBewotecCustomerId$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

  /**
   * Path part for operation forgotPassword
   */
  static readonly ForgotPasswordPath = '/api/User/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword$Response(params?: {
    context?: HttpContext
    body?: RequestModelForgotPassword
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ForgotPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword(params?: {
    context?: HttpContext
    body?: RequestModelForgotPassword
  }
): Observable<StringServiceResponse> {

    return this.forgotPassword$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

  /**
   * Path part for operation confirmPasswordResetCode
   */
  static readonly ConfirmPasswordResetCodePath = '/api/User/confirm-reset-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmPasswordResetCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmPasswordResetCode$Response(params?: {
    context?: HttpContext
    body?: RequestModelConfirmResetCode
  }
): Observable<StrictHttpResponse<ObjectServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ConfirmPasswordResetCodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmPasswordResetCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmPasswordResetCode(params?: {
    context?: HttpContext
    body?: RequestModelConfirmResetCode
  }
): Observable<ObjectServiceResponse> {

    return this.confirmPasswordResetCode$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectServiceResponse>) => r.body as ObjectServiceResponse)
    );
  }

  /**
   * Path part for operation setNewPasswordAfterReset
   */
  static readonly SetNewPasswordAfterResetPath = '/api/User/forgot-password-set-new-pwd';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setNewPasswordAfterReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setNewPasswordAfterReset$Response(params?: {
    context?: HttpContext
    body?: RequestModelSetNewPasswordAfterReset
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.SetNewPasswordAfterResetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setNewPasswordAfterReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setNewPasswordAfterReset(params?: {
    context?: HttpContext
    body?: RequestModelSetNewPasswordAfterReset
  }
): Observable<StringServiceResponse> {

    return this.setNewPasswordAfterReset$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

  /**
   * Path part for operation changePassword_1
   */
  static readonly ChangePassword_1Path = '/api/User/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword_1$Response(params?: {
    context?: HttpContext
    body?: RequestModelChangePassword
  }
): Observable<StrictHttpResponse<StringServiceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ChangePassword_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringServiceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePassword_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword_1(params?: {
    context?: HttpContext
    body?: RequestModelChangePassword
  }
): Observable<StringServiceResponse> {

    return this.changePassword_1$Response(params).pipe(
      map((r: StrictHttpResponse<StringServiceResponse>) => r.body as StringServiceResponse)
    );
  }

  /**
   * Path part for operation confirmEMailVerificationCode
   */
  static readonly ConfirmEMailVerificationCodePath = '/api/User/confirm-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmEMailVerificationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmEMailVerificationCode$Response(params?: {
    enteredConfirmationCode?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ConfirmEMailVerificationCodePath, 'post');
    if (params) {
      rb.query('enteredConfirmationCode', params.enteredConfirmationCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmEMailVerificationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmEMailVerificationCode(params?: {
    enteredConfirmationCode?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.confirmEMailVerificationCode$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateCustomerInformation
   */
  static readonly UpdateCustomerInformationPath = '/api/User/update-customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerInformation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerInformation$Response(params?: {
    context?: HttpContext
    body?: UpdateCustomerInfoRequest
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateCustomerInformationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerInformation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerInformation(params?: {
    context?: HttpContext
    body?: UpdateCustomerInfoRequest
  }
): Observable<boolean> {

    return this.updateCustomerInformation$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getLunarCustomerData
   */
  static readonly GetLunarCustomerDataPath = '/api/User/lunar-customer-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLunarCustomerData()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getLunarCustomerData$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetLunarCustomerDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLunarCustomerData$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getLunarCustomerData(params?: {
    context?: HttpContext
  }
): Observable<Customer> {

    return this.getLunarCustomerData$Response(params).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Path part for operation getReservationsOverrideItems
   */
  static readonly GetReservationsOverrideItemsPath = '/api/User/get-reservations-override-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReservationsOverrideItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationsOverrideItems$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseModelBookingHistoryOverrideItem>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetReservationsOverrideItemsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelBookingHistoryOverrideItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReservationsOverrideItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationsOverrideItems(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseModelBookingHistoryOverrideItem>> {

    return this.getReservationsOverrideItems$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelBookingHistoryOverrideItem>>) => r.body as Array<ResponseModelBookingHistoryOverrideItem>)
    );
  }

  /**
   * Path part for operation updateReservationsOverrideData
   */
  static readonly UpdateReservationsOverrideDataPath = '/api/User/update-reservations-override-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReservationsOverrideData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReservationsOverrideData$Response(params?: {
    context?: HttpContext
    body?: RequestModelUpdateOverrideItems
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateReservationsOverrideDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateReservationsOverrideData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReservationsOverrideData(params?: {
    context?: HttpContext
    body?: RequestModelUpdateOverrideItems
  }
): Observable<boolean> {

    return this.updateReservationsOverrideData$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getBookedTripsForTesting
   */
  static readonly GetBookedTripsForTestingPath = '/api/User/test-active-or-booked-trips';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookedTripsForTesting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookedTripsForTesting$Response(params?: {
    context?: HttpContext
    body?: number
  }
): Observable<StrictHttpResponse<Array<ResponseModelBookedTrip>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetBookedTripsForTestingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelBookedTrip>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookedTripsForTesting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookedTripsForTesting(params?: {
    context?: HttpContext
    body?: number
  }
): Observable<Array<ResponseModelBookedTrip>> {

    return this.getBookedTripsForTesting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelBookedTrip>>) => r.body as Array<ResponseModelBookedTrip>)
    );
  }

  /**
   * Path part for operation deleteUserAccount
   */
  static readonly DeleteUserAccountPath = '/api/User/delete-account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserAccount$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserAccountPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserAccount(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.deleteUserAccount$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
