import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LoadingStates } from '../../shared/components/loading-container/loading-container.component';
import { AdminService } from '../../apis/msb/services/admin.service';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { ExtendedComponentSchema, FormioForm } from '@formio/angular';
import { DatabaseModelShippingCompany } from '../../apis/msb/models/database-model-shipping-company';
import { DatabaseModelShip } from '../../apis/msb/models/database-model-ship';
import { DatabaseModelRouteTimetable } from '../../apis/msb/models/database-model-route-timetable';
import { omit, pick, uniq, uniqBy } from 'lodash';
import { DatabaseModelCabin } from '../../apis/msb/models/database-model-cabin';
import { CabinsService } from '../../apis/msb/services/cabins.service';
import { DatabaseModelPrice } from '../../apis/msb/models/database-model-price';
import { DatabaseModelSpecialPrices } from '../../apis/msb/models/database-model-special-prices';
import { DatabaseModelRoute } from '../../apis/msb/models/database-model-route';
import { DatabaseVirtualModelSpecialPricesRow } from '../../apis/msb/models/database-virtual-model-special-prices-row';
import { ConfirmationModalComponent } from '../../shared/Modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CruiseMetadataService } from '../../shared/services/cruise-metadata.service';
import { ResponseCabinCategoryOption } from '../../apis/msb/models/response-cabin-category-option';
import { AirportsService } from '../../apis/msb/services/airports.service';
import { DatabaseVirtualModelFlightSurcharge } from '../../apis/msb/models/database-virtual-model-flight-surcharge';
import { GlobalLoadingService } from '../../shared/services/global-loading.service';

export interface DatabaseModelCabinWithPriceModel {
  cabin: DatabaseModelCabin;
  priceModel?: string;
}

@Component({
  selector: 'app-agency-special',
  templateUrl: './agency-special.component.html',
  styleUrls: ['./agency-special.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencySpecialComponent implements OnInit {
  private readonly cabinsLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  private readonly flightSurchargesLoading$ =
    new BehaviorSubject<LoadingStates>(LoadingStates.LOADING);

  readonly loading$ = combineLatest([
    this.cabinsLoading$,
    this.flightSurchargesLoading$
  ]).pipe(
    map(([c, f]) => {
      if (!this.specialPrices) {
        return LoadingStates.LOADED;
      }

      if (c === LoadingStates.LOADED && f === LoadingStates.LOADED) {
        return LoadingStates.LOADED;
      } else {
        return LoadingStates.LOADING;
      }
    }),
    shareReplay()
  );

  readonly buttonClasses = {
    promo: 'submit-button-promo-form',
    cabins: 'submit-button-cabin-form',
    flightSurcharges: 'submit-button-flightsurcharges-form'
  };

  @Input() specialPrices: DatabaseModelSpecialPrices;
  @Output() saved = new EventEmitter();

  readonly options: any = {
    isLoading: false,
    i18n: {
      en: {
        submitError:
          'Bitte überprüfen Sie das Formular und korrigieren Sie alle Fehler vor dem Absenden'
      }
    }
  };

  readonly confirmDialogData = {
    data: {
      message: 'Möchten Sie alle Werte zurücksetzen?',
      buttonText: {
        ok: 'Ja',
        cancel: 'Nein'
      }
    }
  };

  readonly selectedCompany$ = new BehaviorSubject<DatabaseModelShippingCompany>(
    null
  );
  readonly selectedShip$ = new BehaviorSubject<DatabaseModelShip>(null);
  readonly selectedRoute$ = new BehaviorSubject<DatabaseModelRoute>(null);
  readonly selectedTimetable$ =
    new BehaviorSubject<DatabaseModelRouteTimetable>(null);
  readonly displayCabinFilter$ = new BehaviorSubject<boolean>(false);
  readonly selectedCabinCategories$ =
    new BehaviorSubject<ResponseCabinCategoryOption>(null);
  readonly selectedPriceModel$ = new BehaviorSubject<string>(null);
  readonly promoFormData$ = new BehaviorSubject<any>(null);
  readonly cabinFormData$ = new BehaviorSubject<any>(null);
  readonly flightSurchargesFormData$ = new BehaviorSubject<any>(null);
  readonly reloadFlightSurcharges$ = new BehaviorSubject<boolean>(false);
  readonly reloadCabin$ = new BehaviorSubject<boolean>(false);

  readonly ships$ = combineLatest([
    this.cruiseMetadataService.ships$,
    this.selectedCompany$
  ]).pipe(
    map(([ships, company]) => {
      const matchingShips = ships?.filter(
        (ship) => ship?.shippingCompanyID === company?.id
      );
      return matchingShips;
    }),
    shareReplay(1)
  );

  readonly routes$ = combineLatest([
    this.cruiseMetadataService.routes$,
    this.selectedShip$
  ]).pipe(
    map(([routes, ship]) => {
      const matchingRoutes = routes?.filter(
        (route) => route?.shipId === ship?.id
      );
      return matchingRoutes;
    }),
    shareReplay(1)
  );

  readonly timetables$ = combineLatest([
    this.cruiseMetadataService.timetables$,
    this.selectedRoute$
  ]).pipe(
    map(([timetables, route]) => {
      const matchingTimetables = timetables?.filter(
        (timetable) => timetable?.routeId === route?.id
      );
      return matchingTimetables;
    }),
    shareReplay(1)
  );

  readonly prices$ = this.selectedTimetable$.pipe(
    filter((x) => !!x?.cruiseId),
    switchMap((x) =>
      this._adminService.streamLivePrices({
        body: {
          start: 0,
          sortDirection: 'asc',
          sortColumn: 'id',
          searchValue: '',
          length: 10000000,
          draw: 1,
          customFilter: [{ key: 'cruiseId', value: x.cruiseId }]
        }
      })
    ),
    map((prices) => prices?.data),
    shareReplay(1)
  );

  readonly rawCabins$: Observable<DatabaseModelCabin[]> =
    this.selectedTimetable$.pipe(
      filter((x) => !!x?.cruiseId),
      switchMap((x) =>
        this._adminService.streamCabins({
          body: {
            start: 0,
            sortDirection: 'asc',
            sortColumn: 'id',
            searchValue: '',
            length: 100000,
            draw: 1,
            customFilter: [{ key: 'cruiseId', value: x.cruiseId }]
          }
        })
      ),
      map((x) => x?.data),
      shareReplay(1)
    );

  readonly priceModels$ = combineLatest([
    this._cabinsService.getPriceModelsForShippingCompany(),
    this.selectedRoute$
  ]).pipe(
    map(([priceModels, route]) => {
      const priceModelsForShippingCompany = priceModels
        .filter((pm) => pm?.shippingCompanyId === route?.shippingCompany?.id)
        .map((pm) => pm.priceModel);
      return priceModelsForShippingCompany;
    }),
    shareReplay(1)
  );

  readonly cabinCategories$ = combineLatest([
    this._cabinsService.getCabinCategoryOptions(),
    this.selectedTimetable$,
    this.prices$
  ]).pipe(
    filter(([cabinCategories, timetable, prices]) => !!prices?.length),
    map(([cabinCategories, timetable, prices]) => {
      const availablePrices = prices.filter(
        (price) => price?.cruiseId === timetable?.cruiseId
      );
      const availableCabinCategories = cabinCategories.filter((c) =>
        availablePrices.some((p) => p.cabinTypeCode === c.cabinCategoryCode)
      );
      return availableCabinCategories;
    }),
    shareReplay(1)
  );

  readonly cabins$: Observable<DatabaseModelCabinWithPriceModel[]> =
    combineLatest([
      this.rawCabins$,
      this.prices$,
      this.selectedTimetable$,
      this.cabinCategories$
    ]).pipe(
      map(([cabins, prices, timetable]) => {
        const filteredPrices = prices.filter(
          (price) => price?.cruiseId === timetable?.cruiseId
        );

        const matchingCabins = cabins.filter((cabin) =>
          filteredPrices.some(
            (p) =>
              p.cabin === cabin.cabinCode &&
              p.cabinTypeCode === cabin.cabinType &&
              p.shipId === cabin.shipId
          )
        );

        const matchingCabinsWithPM: DatabaseModelCabinWithPriceModel[] = [];
        filteredPrices.forEach((p) => {
          matchingCabins.filter((c) => {
            if (
              p?.cabin === c?.cabinCode &&
              p?.cabinTypeCode === c?.cabinType
            ) {
              const cabinWithPM = {
                cabin: c,
                priceModel: p?.priceModel
              };
              matchingCabinsWithPM.push(cabinWithPM);
            }
          });
        });

        return matchingCabinsWithPM;
      }),
      shareReplay(1)
    );

  readonly promoFromConfig$: Observable<FormioForm> = of({}).pipe(
    map(() => {
      const isEdit = !!this.specialPrices;

      const promoFormConfig: FormioForm & {
        components?: Array<
          ExtendedComponentSchema & {
            key:
              | keyof DatabaseModelSpecialPrices
              | 'submit'
              | 'offerOnlyForCustomer';
          }
        >;
      } = {
        display: 'form',
        components: [
          {
            label: 'Promo Kurztext',
            labelPosition: 'left',
            labelWidth: 30,
            placeholder: 'z.B. DEAL oder SPECIAL',
            tableView: true,
            key: 'promoCode',
            type: 'textfield',
            input: true,
            defaultValue: isEdit ? this.specialPrices.promoCode : null
          },
          {
            label: 'Promo Beschreibung',
            labelPosition: 'left',
            labelWidth: 30,
            placeholder: 'z.B. Sonderpreis inkl. Getränkepaket',
            tableView: true,
            key: 'promoDescription',
            type: 'textfield',
            input: true,
            defaultValue: isEdit ? this.specialPrices.promoDescription : null
          },
          {
            label: 'Special info',
            labelPosition: 'left',
            labelWidth: 30,
            placeholder: 'z.B. Bordguthaben',
            tableView: true,
            key: 'specialInfo',
            type: 'textfield',
            input: true,
            defaultValue: isEdit ? this.specialPrices.specialInfo : null
          },
          {
            label: 'Reederei Buchungscode',
            labelPosition: 'left',
            labelWidth: 30,
            tableView: true,
            key: 'bookingCode',
            type: 'textfield',
            input: true,
            defaultValue: isEdit ? this.specialPrices.bookingCode : null
          },
          {
            label: 'Angebot nur für Kunden',
            tableView: false,
            key: 'offerOnlyForCustomer',
            type: 'checkbox',
            input: true,
            defaultValue: isEdit
              ? this.specialPrices.offerOnlyForCustomer
              : false
          },
          {
            label: 'Gültig von',
            labelPosition: 'left',
            labelWidth: 30,
            tableView: false,
            enableMinDateInput: false,
            datePicker: {
              disableWeekends: false,
              disableWeekdays: false
            },
            validate: {
              required: true
            },
            enableMaxDateInput: false,
            defaultValue: isEdit ? this.specialPrices.validFrom : null,
            key: 'validFrom',
            type: 'datetime',
            input: true,
            widget: {
              type: 'calendar',
              displayInTimezone: 'viewer',
              locale: 'en',
              useLocaleSettings: false,
              allowInput: true,
              mode: 'single',
              enableTime: true,
              noCalendar: false,
              format: 'yyyy-MM-dd hh:mm a',
              hourIncrement: 1,
              minuteIncrement: 1,
              time_24hr: false,
              minDate: null,
              disableWeekends: false,
              disableWeekdays: false,
              maxDate: null
            }
          },
          {
            label: 'Gültig bis',
            labelPosition: 'left',
            labelWidth: 30,
            tableView: false,
            enableMinDateInput: false,
            datePicker: {
              disableWeekends: false,
              disableWeekdays: false
            },
            validate: {
              required: true
            },
            enableMaxDateInput: false,
            defaultValue: isEdit ? this.specialPrices.validTill : null,
            key: 'validTill',
            type: 'datetime',
            input: true,
            widget: {
              type: 'calendar',
              displayInTimezone: 'viewer',
              locale: 'en',
              useLocaleSettings: false,
              allowInput: true,
              mode: 'single',
              enableTime: true,
              noCalendar: false,
              format: 'yyyy-MM-dd hh:mm a',
              hourIncrement: 1,
              minuteIncrement: 1,
              time_24hr: false,
              minDate: null,
              disableWeekends: false,
              disableWeekdays: false,
              maxDate: null
            }
          },
          {
            label: 'Notiz',
            labelPosition: 'left',
            labelWidth: 30,
            autoExpand: false,
            tableView: true,
            key: 'note',
            type: 'textarea',
            input: true,
            defaultValue: isEdit ? this.specialPrices.note : null
          },
          {
            customClass: this.buttonClasses.promo,
            type: 'button',
            label: 'Submit',
            key: 'submit',
            disableOnInvalid: true,
            input: true
          }
        ]
      };

      return promoFormConfig;
    })
  );

  readonly airports$ = this._airportsService.getAirports().pipe(shareReplay());

  readonly flightSurchargesData$: Observable<
    DatabaseVirtualModelFlightSurcharge[]
  > = combineLatest([this.selectedTimetable$, this.airports$]).pipe(
    filter(([x, a]) => !!x?.cruiseId && !!a.length),
    tap(() => this.flightSurchargesLoading$.next(LoadingStates.LOADING)),
    switchMap(([x, airports]) =>
      this._adminService
        .streamLiveFlightPrices({
          body: {
            start: 0,
            sortDirection: 'desc',
            sortColumn: 'dateAdded',
            searchValue: '',
            length: 5,
            draw: 6,
            customFilter: [{ key: 'cruiseId', value: x.cruiseId }]
          }
        })
        .pipe(
          map((response) => {
            const flightSurcharges = uniqBy(
              response?.data ?? [],
              (a) => a.airportStartFrom
            ).map((a) => {
              const airportLabel = airports.find(
                (ap) => ap.airportCode === a.airportStartFrom
              )?.name;
              const fs: DatabaseVirtualModelFlightSurcharge = {
                enabled: false,
                airportStartFromCode: a.airportStartFrom,
                airportStartFromLabel: airportLabel ?? a.airportStartFrom,
                surchargeValue: 0
              };
              return fs;
            });
            return flightSurcharges;
          })
        )
    ),
    tap(() => this.flightSurchargesLoading$.next(LoadingStates.LOADED)),
    shareReplay()
  );

  readonly cabinFormConfig: FormioForm & {
    components: Array<
      ExtendedComponentSchema & {
        components?: Array<
          ExtendedComponentSchema & {
            key: keyof DatabaseModelPrice | keyof DatabaseModelCabin;
          }
        >;
      }
    >;
  } = {
    components: [
      {
        label: '',
        key: 'cabinFilter',
        type: 'datagrid',
        customClass: 'test',
        input: false,
        tableView: false,
        components: [
          {
            label: 'Kabine',
            customClass: 'cabin-name',
            disabled: true,
            key: 'name',
            type: 'textfield',
            input: false
          },
          {
            label: 'Code',
            customClass: 'cabin-code',
            disabled: true,
            key: 'cabin',
            type: 'textfield',
            input: false
          },
          {
            label: 'Kategorie',
            customClass: 'cabin-category',
            disabled: true,
            key: 'cabinType',
            type: 'number',
            input: false
          },
          {
            label: 'Tarif',
            key: 'priceModel',
            disabled: true,
            customClass: 'price-model',
            type: 'textfield',
            input: false
          },
          {
            label: 'Min',
            customClass: 'min',
            key: 'min',
            type: 'number',
            input: true
          },
          {
            label: 'Max',
            customClass: 'max',
            key: 'max',
            type: 'number',
            input: true
          },
          {
            label: 'Erw. als 1./2.',
            customClass: 'erw12',
            key: 'priceCruise',
            type: 'number',
            input: true
          },
          {
            label: 'Erw. Einzel',
            customClass: 'erw1',
            key: 'priceCruiseSingle',
            type: 'number',
            input: true
          },
          {
            label: 'Erw. als 3./4.',
            customClass: 'erw34',
            key: 'priceCruise34',
            type: 'number',
            input: true
          },
          {
            label: 'Kind. als 2.',
            customClass: 'kind12',
            key: 'priceCruiseChild',
            type: 'number',
            input: true
          },
          {
            label: 'Kind. als 3./4.',
            customClass: 'kind34',
            key: 'priceCruiseChild34',
            type: 'number',
            input: true
          }
        ]
      },
      {
        customClass: this.buttonClasses.cabins,
        type: 'button',
        label: 'Submit',
        key: 'submit',
        disableOnInvalid: true,
        input: true
      }
    ]
  };

  readonly airportSurchargeFormConfig: FormioForm & {
    components: Array<
      ExtendedComponentSchema & {
        components?: Array<
          ExtendedComponentSchema & {
            key: keyof DatabaseVirtualModelFlightSurcharge;
          }
        >;
      }
    >;
  } = {
    components: [
      {
        label: '',
        key: 'airportSurcharges',
        type: 'datagrid',
        customClass: 'test',
        input: false,
        tableView: false,
        components: [
          {
            label: '',
            key: 'enabled',
            type: 'checkbox',
            input: true
          },
          {
            label: 'Abflughafen',
            key: 'airportStartFromLabel',
            type: 'textfield',
            disabled: true
          },
          {
            label: 'Zuschlag',
            key: 'surchargeValue',
            type: 'number',
            input: true
          }
        ]
      },
      {
        customClass: this.buttonClasses.flightSurcharges,
        type: 'button',
        label: 'Submit',
        key: 'submit',
        disableOnInvalid: true,
        input: true
      }
    ]
  };

  readonly flightSurchargesSubmission$: Observable<any> = combineLatest([
    this.flightSurchargesData$,
    this.reloadFlightSurcharges$
  ]).pipe(
    map(([data, reload]) => {
      let submission = {} as any;
      if (this.specialPrices && reload === false) {
        submission = {
          data: {
            airportSurcharges: [
              ...(this.specialPrices?.airportSurcharges?.map((x) => ({
                ...x
              })) ?? [])
            ]
          }
        };
      } else {
        submission = {
          data: {
            airportSurcharges: [...data]
          }
        };
      }
      return submission;
    }),
    tap(() => this._cdRef.markForCheck()),
    shareReplay()
  );

  readonly cabinsSubmission$: Observable<any> = combineLatest([
    this.cabins$,
    this.reloadCabin$
  ]).pipe(
    tap(() => this.cabinsLoading$.next(LoadingStates.LOADING)),
    map(([cabins, reloadCabin]) => {
      const cabinData: Array<DatabaseVirtualModelSpecialPricesRow> = [];
      if (this.specialPrices && reloadCabin === false) {
        this.specialPrices?.prices.forEach((p) => cabinData.push(p));
      } else {
        cabins.forEach((c) => {
          cabinData.push({
            name: c?.cabin?.name,
            cabin: c?.cabin?.cabinCode,
            cabinType: c?.cabin?.cabinType,
            priceModel: c?.priceModel,
            min: c?.cabin?.min,
            max: c?.cabin?.max
          });
        });
      }
      const propertiesToBeNulledIfEmpty: Array<
        keyof DatabaseVirtualModelSpecialPricesRow
      > = [
        'priceCruise',
        'priceCruise34',
        'priceCruiseSingle',
        'priceCruiseChild',
        'priceCruiseChild34'
      ];
      cabinData.forEach((c) => {
        propertiesToBeNulledIfEmpty.forEach((k) => {
          if (!c[k]) {
            delete c[k];
          }
        });
      });
      const submission = {
        data: {
          cabinFilter: [...cabinData]
        }
      };
      return submission;
    }),
    tap((submission) => {
      this.cabinsLoading$.next(LoadingStates.LOADED);
      setTimeout(() => {
        const gridDOMElements = this._elRef?.nativeElement.querySelectorAll(
          '#cabins-prices-grid [ref="datagrid-cabinFilter-row"]'
        );

        if (gridDOMElements) {
          const rowNodes: Array<HTMLTableRowElement> =
            Array.from(gridDOMElements);

          rowNodes.forEach((rowNode, index) => {
            const rowData = submission?.data?.cabinFilter[index];
            const classes = [];
            classes.push(`row-cabin-category-${rowData.cabinType}`);
            classes.push(`row-cabin-pricemodel-${rowData.priceModel}`);
            rowNode.className = classes.join(' ');
          });
        }
      }, 500);
    }),
    shareReplay(1)
  );

  readonly classesToBeHidden$ = combineLatest([
    this.cabinCategories$,
    this.selectedCabinCategories$,
    this.priceModels$,
    this.selectedPriceModel$
  ]).pipe(
    map(([categories, selectedCategory, priceModels, selectedPriceModel]) => {
      if (selectedCategory || selectedPriceModel) {
        const classes = [];
        if (selectedCategory) {
          const cabinCategoryNotSelected = categories.filter(
            (c) =>
              selectedCategory.cabinCategoryCode !== c.cabinCategoryCode &&
              selectedCategory.cabinCategoryName !== c.cabinCategoryName
          );
          cabinCategoryNotSelected.forEach((c) => {
            classes.push(`row-cabin-category-${c.cabinCategoryCode}-hidden`);
          });
        }
        if (selectedPriceModel) {
          const priceModelsNotSelected = priceModels.filter(
            (pm) => selectedPriceModel !== pm
          );
          priceModelsNotSelected.forEach((pm) => {
            classes.push(`row-cabin-pricemodel-${pm}-hidden`);
          });
        }

        const classesToBeHidden =
          classes.length > 0
            ? classes.join(' ')
            : 'no-cabin-category-selected-selected';
        console.log('classes to be hidden', classesToBeHidden);
        return classesToBeHidden;
      }
      return 'no-class-selected';
    }),
    shareReplay(1)
  );

  readonly submittedFormData$: Observable<DatabaseModelSpecialPrices> =
    combineLatest([
      this.selectedRoute$,
      this.selectedTimetable$,
      this.promoFormData$,
      this.cabinFormData$,
      this.flightSurchargesFormData$
    ]).pipe(
      filter(
        ([route, timetable, promoForm, cabinForm, flightSurcharges]) =>
          !!timetable && !!cabinForm && !!promoForm && !!flightSurcharges
      ),
      debounceTime(1000),
      map(([route, timetable, promoForm, cabinForm, flightSurcharges]) => {
        const priceRowsToAdd: DatabaseVirtualModelSpecialPricesRow[] =
          cabinForm?.data?.cabinFilter?.map((c) => {
            const priceRow: DatabaseVirtualModelSpecialPricesRow = {
              name: c?.name,
              cabin: c?.cabin,
              cabinType: c?.cabinType,
              priceModel: c?.priceModel,
              min: c?.min,
              max: c?.max,
              priceCruise: c?.priceCruise,
              priceCruise34: c?.priceCruise34,
              priceCruiseSingle: c?.priceCruiseSingle,
              priceCruiseChild: c?.priceCruiseChild,
              priceCruiseChild34: c?.priceCruiseChild34
            };
            return priceRow;
          });

        const form: DatabaseModelSpecialPrices = {
          routeId: route?.id,
          cruiseId: timetable?.cruiseId,
          shipId: route?.shipId || route?.ship?.id,
          shippingCompanyId:
            route?.shippingCompanyId || route?.shippingCompany?.id,
          routeName: route?.name,
          dateAdded: '',
          prices: [...uniq(priceRowsToAdd)],
          airportSurcharges: flightSurcharges?.data?.airportSurcharges,
          offerOnlyForCustomer: promoForm?.data?.offerOnlyForCustomer,
          validFrom: promoForm?.data?.validFrom,
          validTill: promoForm?.data?.validTill,
          note: promoForm?.data?.note,
          bookingCode: promoForm?.data?.bookingCode,
          specialInfo: promoForm?.data?.specialInfo,
          promoCode: promoForm?.data?.promoCode,
          promoDescription: promoForm?.data?.promoDescription
        };

        return form;
      }),
      shareReplay(1)
    );

  constructor(
    private _adminService: AdminService,
    private _airportsService: AirportsService,
    readonly cruiseMetadataService: CruiseMetadataService,
    private _elRef: ElementRef,
    private _cdRef: ChangeDetectorRef,
    private _cabinsService: CabinsService,
    private _globalLoadingService: GlobalLoadingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.specialPrices) {
      this.prefillSpecialPricesEditor(this.specialPrices);
    }
  }

  async saveChanges() {
    Object.values(this.buttonClasses).forEach((c) =>
      this._elRef?.nativeElement.querySelector(`.${c} > button`)?.click()
    );
    if (!this.selectedTimetable$?.value?.cruiseId) {
      alert('Bitte wähle einen Abreisetermin aus!');
      return;
    }

    const formData = await this.submittedFormData$.pipe(take(1)).toPromise();
    if (!formData?.airportSurcharges?.some((x) => x?.enabled)) {
      alert(
        'Bitte wähle mindestens einen Abflughafen bei den Flugzuschlägen aus!'
      );
      return;
    }
    const requiredFields: Array<keyof DatabaseModelSpecialPrices> = [
      'promoCode',
      'promoDescription',
      'validFrom',
      'validTill'
    ];
    if (requiredFields.some((field) => !formData[field])) {
      alert('Bitte prüfe deine Eingaben');
      return;
    }

    this._globalLoadingService.showGlobalLoader();
    let response: boolean;
    if (this.specialPrices) {
      response = await this._adminService
        .editSpecialPrices({ body: formData })
        .toPromise();
    } else {
      response = await this._adminService
        .addSpecialPrices({ body: formData })
        .toPromise();
    }
    if (response) {
      this.saved.next();
    }
    this._globalLoadingService.release();
  }

  async delete() {
    this._globalLoadingService.showGlobalLoader();
    const response = await this._adminService
      .deleteSpecialPrices({
        body: {
          ...pick(this.specialPrices, ['id'])
        }
      })
      .toPromise();
    this._globalLoadingService.release();
    if (response) {
      this.saved.next();
    }
  }

  async prefillSpecialPricesEditor(specialPrices) {
    const shippingCompanyId = specialPrices?.shippingCompanyId;
    const shipId = specialPrices?.shipId;
    const routeId = specialPrices?.routeId;
    const cruiseId = specialPrices?.cruiseId;

    const shippingCompany = await this.cruiseMetadataService.companies$
      .pipe(
        take(1),
        map((companies) => {
          return companies.find((c) => c?.id === shippingCompanyId);
        })
      )
      .toPromise();

    this.selectedCompany$.next(shippingCompany);

    const ship = await this.ships$
      .pipe(
        take(1),
        map((ships) => {
          return ships.find((s) => s?.id === shipId);
        })
      )
      .toPromise();

    this.selectedShip$.next(ship);

    const route = await this.routes$
      .pipe(
        take(1),
        map((routes) => {
          return routes.find((r) => r?.id === routeId);
        })
      )
      .toPromise();

    this.selectedRoute$.next(route);

    const timetable = await this.timetables$
      .pipe(
        take(1),
        map((timetables) => {
          return timetables.find((t) => t?.cruiseId === cruiseId);
        })
      )
      .toPromise();

    this.selectedTimetable$.next(timetable);
    this.displayCabinFilter$.next(true);
  }

  async refreshFlightSurcharges() {
    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      this.confirmDialogData
    );
    await dialogRef.afterOpened().toPromise();
    const confirmed = await dialogRef.afterClosed().toPromise();
    if (confirmed) {
      this.reloadFlightSurcharges$.next(true);
    }
  }

  async refreshCabins() {
    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      this.confirmDialogData
    );
    await dialogRef.afterOpened().toPromise();
    const confirmed = await dialogRef.afterClosed().toPromise();
    if (confirmed) {
      this.reloadCabin$.next(true);
    }
  }
}
