<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'asc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'shipname', name: 'Shiff Name' },
        { prop: 'shipCode', name: 'Shiff Code' },
        { prop: 'shippingCompanyID', name: 'Reederei Id' },
        { prop: 'shippingCompany.name', name: 'Reederei' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
