
<ng-template #cellDateAddedRef let-row="row">
  {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date : 'shortTime'}}
</ng-template>

<ng-container *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'userId', name: 'User-Id' },
        { prop: 'title', name: 'Title' },
        { prop: 'body', name: 'Body' },
        { prop: 'data', name: 'Data' },
        { prop: 'dateAdded', name: 'Date_added', cellTemplate: cellDateAddedRef }
      ],
      fetchData: fetchData()
} as tableConfig">

  <dynamic-table [config]="tableConfig" [disableSearch]="true" (rowClick)="clickRow($event)" (tableDraw)="globalLoadingService.release()">
  </dynamic-table>

</ng-container>
