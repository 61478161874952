import { Pipe, PipeTransform } from '@angular/core';
import { AdminMsbEmailTemplatesService } from '../../apis/mail/services/admin-msb-email-templates.service';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'mailTemplate'
})
export class MailTemplatePipe implements PipeTransform {
  constructor(
    private _adminMsbEmailTemplatesService: AdminMsbEmailTemplatesService,
    private _domSanitizer: DomSanitizer
  ) {}

  transform(rowData: any): Observable<SafeHtml> {
    return this._adminMsbEmailTemplatesService
      .PrintBooking({
        bookingJson: rowData.bookingData,
        recipientEmail: '',
        agencyEmail: ''
      })
      .pipe(
        map((htmlString) => {
          let htmlBodyString = '';
          if (htmlString?.length) {
            const virtualHtmlEmail = document.createElement('html');
            virtualHtmlEmail.innerHTML = htmlString;
            const bodyElem = virtualHtmlEmail.querySelector('body');
            if (bodyElem?.innerHTML?.length) {
              htmlBodyString = bodyElem.innerHTML;
            }
          }
          return this._domSanitizer.bypassSecurityTrustHtml(htmlBodyString);
        }),
        shareReplay(1)
      );
  }
}
