<div style="padding: 10px">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filter </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #rowDetailTemplateRef let-row="row">
          <div style="width: 90vw">
            <form-loading-container
              [loadingState]="loading$ | async"
              [height]="'200px'"
            >
              <mat-accordion style="width: 100%">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>edit</mat-icon>&nbsp; Kundendaten Bearbeiten
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div style="padding: 0 20px">
                      <formio
                        [form]="row | accountDetailsForm"
                        (submit)="saveChanges($event?.data, row)"
                      >
                      </formio>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>book</mat-icon>&nbsp; Buchungen
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <app-booked-trips-table [userId]="row.id">
                    </app-booked-trips-table>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>remove_red_eye</mat-icon>&nbsp; Überwachungen
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div style="padding: 20px">
                      <app-observations-table [userId]="row.id">
                      </app-observations-table>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>star</mat-icon>&nbsp; Favoriten
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div style="padding: 20px">
                      <app-favourite-routes-table [userId]="row.id">
                      </app-favourite-routes-table>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>remove_red_eye</mat-icon>&nbsp; Logs
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <app-log-table [userId]="row.id"> </app-log-table>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>vpn_key</mat-icon>&nbsp; Geräte
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <app-fcm-token-table [userId]="row.id"> </app-fcm-token-table>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon>notifications</mat-icon>&nbsp; Empfangene
                      Benachrichtigungen
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <app-fcm-notifications-table [userId]="row.id">
                    </app-fcm-notifications-table>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>
            </form-loading-container>
          </div>
        </ng-template>
        <ng-template #cellDateCreatedRef let-row="row">
          {{row?.createdOn | date : 'dd.MM.yy'}} @ {{row?.createdOn | date :
          'shortTime'}}
        </ng-template>
        <ng-template #cellRoleRef let-row="row">
        <span
          class="account_role"
          *ngFor="let role of row?.roles"
          [style.color]="role === 'CUSTOMER' ? 'green' : '#000' "
        >
          {{role}}
        </span>
        </ng-template>

        <ng-container
          *ngIf="{
            defaultOrder: 'createdOn',
            defaultOrderDirection: 'desc',
            pageLength: filterData.pageLength,
            columns: [
              { prop: 'id', name: 'Id' },
              { prop: 'customerNumber', name: 'Kd.-Nr.' },
              { prop: 'firstName', name: 'Vorname' },
              { prop: 'lastName', name: 'Nachname' },
              { prop: 'email', name: 'E-Mail' },
              { prop: 'emailConfirmed', name: 'Bestätigt' },
              { prop: 'bewotecCustomerId', name: 'BewotecId' },
              { prop: 'createdOn', name: 'Registriert', cellTemplate: cellDateCreatedRef },
              { prop: 'roles', name: 'Rolle', cellTemplate: cellRoleRef },
              { prop: 'letterSalutation', name: 'Briefanrede' },
              { prop: 'maxFavourites', name: 'MaxFavoriten' },
              { prop: 'maxObservations', name: 'MaxÜberwachungen' }
            ],
            fetchData: fetchData()
          } as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
