<mat-form-field *ngIf="filterOptions?.length">
    <mat-label>{{filterLabel}}</mat-label>
    <mat-select [(ngModel)]="selectedValue" (selectionChange)="filterSelectionChange($event)">
        <mat-option *ngFor="let filterValue of filterOptions" [value]="filterValue">
            {{isKvp ? filterValue?.value : filterValue}}
        </mat-option>
    </mat-select>
    <button mat-button *ngIf="selectedValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilter($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>