/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { MailApiConfiguration as __Configuration } from '../mail-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TemplateModelOnboarding } from '../models/template-model-onboarding';
import { RequestModelBookingEmail } from '../models/request-model-booking-email';
import { TemplateModelConfirmationCode } from '../models/template-model-confirmation-code';
import { TemplateModelRegistrationFail } from '../models/template-model-registration-fail';
@Injectable({
  providedIn: 'root',
})
class MsbEmailTemplatesService extends __BaseService {
  static readonly OnboardingEmailTemplateCustomerPath = '/api/MsbEmailTemplates/onboarding/customer';
  static readonly OnboardingEmailTemplateRegisteredPath = '/api/MsbEmailTemplates/onboarding/registered';
  static readonly SendOnboardingEmailPath = '/api/MsbEmailTemplates/onboarding';
  static readonly BookingEmailTemplatePath = '/api/MsbEmailTemplates/booking';
  static readonly SendBookingEmailPath = '/api/MsbEmailTemplates/booking';
  static readonly SendConfirmationCodeEmailPath = '/api/MsbEmailTemplates/confirm-link-customer-number';
  static readonly SendForgotPasswordCodeEmailPath = '/api/MsbEmailTemplates/confirm-forgot-password';
  static readonly SendConfirmationEmailPath = '/api/MsbEmailTemplates/confirmation-code';
  static readonly SendRegistrationEmailFailEmailPath = '/api/MsbEmailTemplates/registration-fail-email';
  static readonly SendRegistrationCustomerNumberFailEmailPath = '/api/MsbEmailTemplates/registration-fail-customer-number';
  static readonly SendRegistrationFailEmailPath = '/api/MsbEmailTemplates/registration-fail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  OnboardingEmailTemplateCustomerResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/onboarding/customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  OnboardingEmailTemplateCustomer(): __Observable<null> {
    return this.OnboardingEmailTemplateCustomerResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  OnboardingEmailTemplateRegisteredResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/onboarding/registered`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  OnboardingEmailTemplateRegistered(): __Observable<null> {
    return this.OnboardingEmailTemplateRegisteredResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SendOnboardingEmailResponse(body?: TemplateModelOnboarding): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MsbEmailTemplates/onboarding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  SendOnboardingEmail(body?: TemplateModelOnboarding): __Observable<boolean> {
    return this.SendOnboardingEmailResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }
  BookingEmailTemplateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  BookingEmailTemplate(): __Observable<null> {
    return this.BookingEmailTemplateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SendBookingEmailResponse(body?: RequestModelBookingEmail): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MsbEmailTemplates/booking`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  SendBookingEmail(body?: RequestModelBookingEmail): __Observable<boolean> {
    return this.SendBookingEmailResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }
  SendConfirmationCodeEmailResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/confirm-link-customer-number`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  SendConfirmationCodeEmail(): __Observable<null> {
    return this.SendConfirmationCodeEmailResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  SendForgotPasswordCodeEmailResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/confirm-forgot-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  SendForgotPasswordCodeEmail(): __Observable<null> {
    return this.SendForgotPasswordCodeEmailResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SendConfirmationEmailResponse(body?: TemplateModelConfirmationCode): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MsbEmailTemplates/confirmation-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  SendConfirmationEmail(body?: TemplateModelConfirmationCode): __Observable<boolean> {
    return this.SendConfirmationEmailResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }
  SendRegistrationEmailFailEmailResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/registration-fail-email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  SendRegistrationEmailFailEmail(): __Observable<null> {
    return this.SendRegistrationEmailFailEmailResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  SendRegistrationCustomerNumberFailEmailResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MsbEmailTemplates/registration-fail-customer-number`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  SendRegistrationCustomerNumberFailEmail(): __Observable<null> {
    return this.SendRegistrationCustomerNumberFailEmailResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SendRegistrationFailEmailResponse(body?: TemplateModelRegistrationFail): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MsbEmailTemplates/registration-fail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  SendRegistrationFailEmail(body?: TemplateModelRegistrationFail): __Observable<boolean> {
    return this.SendRegistrationFailEmailResponse(body).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module MsbEmailTemplatesService {
}

export { MsbEmailTemplatesService }
