
import { orderBy } from 'lodash';

const accessValueByPath = (obj, path) => {
    return path.replace(/\[|\]\.?/g, '.')
        .split('.')
        .filter(s => s)
        .reduce((acc, val) => acc && acc[val], obj);
}

const flatten = function (data) {
    var result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++)
                recurse(cur[i], prop ? prop + "." + i : "" + i);
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}

export const flattenExport = (data, deleteKey = 'logValue.Data') => {
    const dict: any = {};
    data.data.forEach(row =>
        Object.keys(flatten(row)).forEach(propName => dict[propName] = true)
    );
    const keys = orderBy(Object.keys(dict));//;.filter(key => key !== deleteKey);
    const rows: any[][] = [];
    data.data.forEach(row => {
        const rowValues = [];
        keys.forEach(k => rowValues.push(
            accessValueByPath(row, k)
        ));
        rows.push(rowValues);
    });
    return {
        rows: rows,
        columns: keys.map(x => ({
            name: x,
            filterButton: true
        }))
    }
};

// export const unflatten = function (data) {
//     "use strict";
//     if (Object(data) !== data || Array.isArray(data))
//         return data;
//     var result = {}, cur, prop, idx, last, temp;
//     for (var p in data) {
//         cur = result, prop = "", last = 0;
//         do {
//             idx = p.indexOf(".", last);
//             temp = p.substring(last, idx !== -1 ? idx : undefined);
//             cur = cur[prop] || (cur[prop] = (!isNaN(parseInt(temp)) ? [] : {}));
//             prop = temp;
//             last = idx + 1;
//         } while (idx >= 0);
//         cur[prop] = data[p];
//     }
//     return result[""];
// }