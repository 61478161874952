import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import { INavigationStateService, InitializeNavigationStateHandler, NavigationStateHandler, UltraDynamicFilterConfig, UltraDynamicFilterStateMap } from '../../../shared/navigation/navigation-state-handler.class';
import * as moment from 'moment';

export interface OverviewFilterState {
  pageLength?: number;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  dimension?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStateOverviewService implements INavigationStateService<OverviewFilterState> {
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<OverviewFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.ANALYTICS_OVERVIEW];
  }

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.ANALYTICS_OVERVIEW,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      // TODO configure filters

      //table specific filters
      {
        type: 'DROPDOWN',
        matrixParameter: 'pageLength',
        label: 'Einträge pro Seite',
        iconName: '',
        required: true,
        options$: of([
          { id: '5', label: '5 pro Seite' },
          { id: '10', label: '10 pro Seite' },
          { id: '50', label: '50 pro Seite' },
          { id: '100', label: '100 pro Seite' },
          { id: '1000', label: '1000 pro Seite' }
        ]),
        defaultValue: '10',
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'search',
        controlOptions: {
          debounceTime: 500
        },
        label: 'Suchbegriff',
        iconName: 'search',
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateFrom',
        label: 'Datum von (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateTo',
        label: 'Datum bis (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      //Specific for the charts
      {
        type: 'DROPDOWN',
        matrixParameter: 'dimension',
        label: 'Zeit-Dimension',
        iconName: 'date_range',
        required: true,
        options$: of([
          {
            id: 'yyyy-MM-dd',
            label: 'Nach Tag (YYYY-MM-DD)'
          },
          {
            id: 'y',
            label: 'Nach Monat (YYYY-MM)'
          },
          {
            id: 'yyyy',
            label: 'Nach Jahr (YYYY)'
          }
        ]),
        defaultValue: 'yyyy-MM-dd'
      }
    ]
  };

  constructor(
    protected readonly  _router: Router
  ) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(PATHS.MSB, this.config, _router);
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): OverviewFilterState {
    const parsedFilter: OverviewFilterState = {};

    // TODO parse the filter data

    if (fltr?.pageLength) {
      parsedFilter.pageLength = parseInt(fltr.pageLength, 10);
    }

    if (fltr?.search) {
      parsedFilter.search = fltr.search;
    }

    if (fltr?.dateFrom) {
      parsedFilter.dateFrom = fltr.dateFrom;
    }

    if (fltr?.dateTo) {
      parsedFilter.dateTo = fltr.dateTo;
    }

    if (fltr?.dimension) {
      parsedFilter.dimension = fltr.dimension;
    }

    return parsedFilter;
  }
}
