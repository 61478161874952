import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateCabinsService } from './filter-state-cabins.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';

@Component({
  selector: 'app-manage-cabins',
  templateUrl: './cabins.page.html',
  styleUrls: ['./cabins.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabinsComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  constructor(
    readonly filterStateService: FilterStateCabinsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => this.adminService.streamCabins({ body: request });
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }
}
