import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  delay,
  filter,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';

import theme from 'highcharts/themes/brand-dark';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateCustomerInteractionService } from './filter-state-customer-interaction.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelHighChartData } from '../../../apis/msb/models/request-model-high-chart-data';
import * as moment from 'moment';
import { HighChartOptions } from '../../../shared/utils/chart.utils';

@Component({
  selector: 'app-analytics-customer-interaction',
  templateUrl: './customer-interaction.page.html',
  styleUrls: ['./customer-interaction.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerInteractionComponent implements OnInit {
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly chartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly Highcharts = Highcharts;

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  readonly filterValid$: Observable<boolean> =
    this.filterStateService.filterState.getFilterData$.pipe(
      map((fltr) => {
        let dateRangeIsValid = false;
        if (fltr?.dateFrom?.length === 10 && fltr?.dateTo?.length === 10) {
          if (
            moment(fltr.dateFrom, 'YYYY-MM-DD').isValid() &&
            moment(fltr.dateTo, 'YYYY-MM-DD').isValid()
          ) {
            dateRangeIsValid = true;
          }
        }
        return dateRangeIsValid;
      }),
      tap(() => {
        setTimeout(() => this.globalLoadingService.release(), 1000);
      }),
      shareReplay()
    );

  readonly chartConfigs$: Observable<RequestModelHighChartData> =
    this.filterStateService.filterState.getFilterData$.pipe(
      map((fltr) => {
        const chartConfigs: RequestModelHighChartData = {
          startDate: fltr?.dateFrom,
          endDate: fltr?.dateTo,
          dimension: fltr?.dimension,
          chartconfigs: [
            {
              title: 'Buchungen',
              columns: [
                'SHOW_CABIN_VACANCY_CHECKER',
                'SHOW_BOOKING_FORM',
                'SUBMIT_BOOKING_FORM_SUCCESS',
                'SUBMIT_BOOKING_FORM_FAIL'
              ]
            },
            {
              title: 'Datenimport',
              columns: ['DATA_IMPORT_SUCCESS', 'DATA_IMPORT_FAIL']
            },
            {
              title: 'App Start',
              columns: ['APP_START']
            },
            {
              title: 'Registrierungen',
              columns: ['REGISTRATION_SUCCESS', 'REGISTRATION_FAIL']
            },
            {
              title: 'Suchen',
              columns: ['SEARCH']
            },
            {
              title: 'Modul Nutzung',
              columns: [
                'SHOW_DASHBOARD',
                'SHOW_OBSERVATIONS',
                'SHOW_SHIP_LIST_OR_DETAIL'
              ]
            },
            {
              title: 'Überwachungen (alt)',
              columns: ['OBSERVATION_START', 'OBSERVATION_STOP']
            },
            {
              title: 'Überwachungen (neu)',
              columns: [
                'BUDGET_OBSERVATION_START',
                'BUDGET_OBSERVATION_EDIT',
                'BUDGET_OBSERVATION_STOP'
              ]
            },
            {
              title: 'Favoriten',
              columns: ['FAVOURITE_ADD', 'FAVOURITE_REMOVE']
            },
            {
              title: 'Passwort',
              columns: [
                'CHANGE_PASSWORD_SUCCESS',
                'ADD_NEW_PASSWORD_FORGOTPW_SUCCESS'
              ]
            },
            {
              title: 'E-Mail Bestätigungen',
              columns: [
                'CONFIRMATION_EMAIL_SENT_SUCCESS',
                'CONFIRMATION_EMAIL_SENT_FAIL',
                'EMAIL_CONFIRMED_SUCCESS',
                'EMAIL_CONFIRMED_FAIL'
              ]
            },
            {
              title: 'Login',
              columns: ['LOGIN_SUCCESS', 'LOGIN_FAILED']
            },
            {
              title: 'Detektive',
              columns: ['SHOW_IMPRINT', 'SHOW_PRIVACY', 'SHOW_TOS']
            },
            {
              title: 'App Downloads',
              columns: ['OPEN_PLAYSTORE_FROM_WEB']
            },
            {
              title: 'Kommentare',
              columns: [
                'COMMENT_ADD_SUCCESS',
                'COMMENT_ADD_FAIL',
                'COMMENT_DELETE_SUCCESS',
                'COMMENT_DELETE_FAIL'
              ]
            }
          ]
        };
        return chartConfigs;
      }),
      shareReplay(1)
    );

  readonly chartData$: Observable<Array<Highcharts.Options>> = combineLatest([
    this.filterValid$,
    this.filterStateService.filterState.getFilterData$,
    this.chartConfigs$
  ]).pipe(
    tap(() => this.chartLoading$.next(LoadingStates.LOADING)),
    delay(2000),
    filter(([fltrValid, fltr, config]) => !!fltrValid && !!config),
    switchMap(([fltrValid, fltr, config]) => {
      if (fltrValid) {
        const isPieChartEnabled = fltr?.chartType === 'pie';
        return this.adminService.getHcChartData({ body: config }).pipe(
          map((chartData) => {
            return chartData.map((chartOptions) => {
              return HighChartOptions(
                fltr,
                chartOptions,
                false,
                'Anzahl',
                isPieChartEnabled
              );
            });
          })
        );
      } else {
        return of(null as Array<Highcharts.Options>);
      }
    }),
    tap((options) => {
      console.log(options);
      this.chartLoading$.next(LoadingStates.LOADED);
    }),
    shareReplay()
  );

  // readonly chartOptions$ = this.chartData$.pipe(
  //   map((rows) => {
  //     return HighchartsOptions(<Highcharts.Options>{
  //       chart: {
  //         type: 'column',
  //         height: 450
  //       },
  //       xAxis: {
  //         categories: rows.map((row) => row.name),
  //         gridLineWidth: 0
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Value'
  //         },
  //         gridLineWidth: 0
  //       },
  //       series: [
  //         {
  //           data: rows.map((row) => row.value)
  //         }
  //       ]
  //     });
  //   }),
  //   shareReplay()
  // );

  constructor(
    readonly filterStateService: FilterStateCustomerInteractionService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    theme(Highcharts);
    this.globalLoadingService.release();
  }
}
