import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import {
  INavigationStateService,
  InitializeNavigationStateHandler,
  NavigationStateHandler,
  UltraDynamicFilterConfig,
  UltraDynamicFilterStateMap
} from '../../../shared/navigation/navigation-state-handler.class';
import * as moment from 'moment';

export interface CustomerInteractionFilterState {
  dateFrom?: string;
  dateTo?: string;
  dimension?: string;
  chartType?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStateCustomerInteractionService
  implements INavigationStateService<CustomerInteractionFilterState>
{
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<CustomerInteractionFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.ANALYTICS_CUSTOMER_INTERACTION];
  }

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.ANALYTICS_CUSTOMER_INTERACTION,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateFrom',
        label: 'Datum von (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateTo',
        label: 'Datum bis (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'dimension',
        label: 'Zeit-Dimension',
        iconName: 'date_range',
        required: true,
        options$: of([
          {
            id: 'yyyy-MM-dd',
            label: 'Nach Tag (YYYY-MM-DD)'
          },
          {
            id: 'y',
            label: 'Nach Monat (YYYY-MM)'
          },
          {
            id: 'yyyy',
            label: 'Nach Jahr (YYYY)'
          }
        ]),
        defaultValue: 'yyyy-MM-dd'
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'chartType',
        label: 'Diagram Typ',
        iconName: 'date_range',
        required: true,
        options$: of([
          {
            id: 'column',
            label: 'Spalte'
          },
          {
            id: 'pie',
            label: 'Kuchen'
          }
        ]),
        defaultValue: 'column'
      }
    ]
  };

  constructor(protected readonly _router: Router) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(
      PATHS.MSB,
      this.config,
      _router
    );
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): CustomerInteractionFilterState {
    const parsedFilter: CustomerInteractionFilterState = {};

    if (fltr?.dateFrom) {
      parsedFilter.dateFrom = fltr.dateFrom;
    }

    if (fltr?.dateTo) {
      parsedFilter.dateTo = fltr.dateTo;
    }

    if (fltr?.dimension) {
      parsedFilter.dimension = fltr.dimension;
    }

    if (fltr?.chartType) {
      parsedFilter.chartType = fltr.chartType;
    }

    return parsedFilter;
  }
}
