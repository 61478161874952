import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';

@Component({
  selector: 'generic-dropdown-filter',
  templateUrl: './generic-dropdown-filter.component.html',
  styleUrls: ['./generic-dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDropdownFilterComponent {

  @Input()
  isKvp: boolean;

  @Input()
  filterLabel: string;

  @Input()
  filterKey: string;

  @Input()
  filterOptions: Array<any>;

  @Input()
  tableRef: DynamicTableComponent;

  selectedValue: any;

  constructor(
    private _cdr: ChangeDetectorRef
  ) { }

  filterSelectionChange($event): void {
    if ($event) {
      const value: string = this.isKvp && $event.value && $event.value.key ? $event.value.key : $event.value;
      this.tableRef.customFilter = this.tableRef.customFilter.filter(x => x.key !== this.filterKey);
      if (value) {
        this.tableRef.customFilter.push({
          key: this.filterKey,
          value: value
        });
      }
      this.tableRef.resetPagination();
      this.tableRef.reloadTable();
      this._cdr.markForCheck();
    }
  }

  resetFilter($mouseEvent): void {
    if ($mouseEvent.stopPropagation) {
      $mouseEvent.stopPropagation();
    }
    this.selectedValue = null;
    this.filterSelectionChange({ value: null });
  }

}
