/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingsKpiDependentData } from '../models/bookings-kpi-dependent-data';
import { BookingsKpiIndependentData } from '../models/bookings-kpi-independent-data';
import { DatabaseModelAuthModalVisitorResponseModelDataTableResult } from '../models/database-model-auth-modal-visitor-response-model-data-table-result';
import { DatabaseModelBudgetObservationResponseModelDataTableResult } from '../models/database-model-budget-observation-response-model-data-table-result';
import { DatabaseModelCabinResponseModelDataTableResult } from '../models/database-model-cabin-response-model-data-table-result';
import { DatabaseModelDeletedNotificationResponseModelDataTableResult } from '../models/database-model-deleted-notification-response-model-data-table-result';
import { DatabaseModelFcmNotificationResponseModelDataTableResult } from '../models/database-model-fcm-notification-response-model-data-table-result';
import { DatabaseModelFcmTokenResponseModelDataTableResult } from '../models/database-model-fcm-token-response-model-data-table-result';
import { DatabaseModelFlightPriceResponseModelDataTableResult } from '../models/database-model-flight-price-response-model-data-table-result';
import { DatabaseModelLogEntryResponseModelDataTableResult } from '../models/database-model-log-entry-response-model-data-table-result';
import { DatabaseModelPricePredictionResponseModelDataTableResult } from '../models/database-model-price-prediction-response-model-data-table-result';
import { DatabaseModelPriceResponseModelDataTableResult } from '../models/database-model-price-response-model-data-table-result';
import { DatabaseModelRouteDetail } from '../models/database-model-route-detail';
import { DatabaseModelRouteResponseModelDataTableResult } from '../models/database-model-route-response-model-data-table-result';
import { DatabaseModelRouteTimetableResponseModelDataTableResult } from '../models/database-model-route-timetable-response-model-data-table-result';
import { DatabaseModelShipResponseModelDataTableResult } from '../models/database-model-ship-response-model-data-table-result';
import { DatabaseModelShippingCompanyResponseModelDataTableResult } from '../models/database-model-shipping-company-response-model-data-table-result';
import { DatabaseModelShopifyCouponResponseModelDataTableResult } from '../models/database-model-shopify-coupon-response-model-data-table-result';
import { DatabaseModelSpecialPrices } from '../models/database-model-special-prices';
import { DatabaseModelSpecialPricesResponseModelDataTableResult } from '../models/database-model-special-prices-response-model-data-table-result';
import { DatabaseModelTaggedTimetables } from '../models/database-model-tagged-timetables';
import { DatabaseModelTaggedTimetablesResponseModelDataTableResult } from '../models/database-model-tagged-timetables-response-model-data-table-result';
import { DatabaseModelTelephoneBookedTripResponseModelDataTableResult } from '../models/database-model-telephone-booked-trip-response-model-data-table-result';
import { DatabaseModelUploadedRouteFile } from '../models/database-model-uploaded-route-file';
import { DatabaseModelUploadedRouteFileResponseModelDataTableResult } from '../models/database-model-uploaded-route-file-response-model-data-table-result';
import { DatabaseModelUser } from '../models/database-model-user';
import { DatabaseModelUserBookedTripResponseModelDataTableResult } from '../models/database-model-user-booked-trip-response-model-data-table-result';
import { DatabaseModelUserFavouriteRouteResponseModelDataTableResult } from '../models/database-model-user-favourite-route-response-model-data-table-result';
import { DatabaseModelUserObservationResponseModelDataTableResult } from '../models/database-model-user-observation-response-model-data-table-result';
import { DatabaseModelUserResponseModelDataTableResult } from '../models/database-model-user-response-model-data-table-result';
import { Folder } from '../models/folder';
import { HighchartsChartData } from '../models/highcharts-chart-data';
import { PriceDropInfo } from '../models/price-drop-info';
import { RequestModelCustomerNotification } from '../models/request-model-customer-notification';
import { RequestModelHighChartData } from '../models/request-model-high-chart-data';
import { RequestModelPaged } from '../models/request-model-paged';
import { ShopifyCouponRequest } from '../models/shopify-coupon-request';
import { ShopifyCouponResult } from '../models/shopify-coupon-result';
import { StringDoubleTuple } from '../models/string-double-tuple';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createShopifyCoupon
   */
  static readonly CreateShopifyCouponPath = '/api/Admin/createshopifycoupon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createShopifyCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createShopifyCoupon$Response(params?: {
    context?: HttpContext
    body?: ShopifyCouponRequest
  }
): Observable<StrictHttpResponse<Array<ShopifyCouponResult>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.CreateShopifyCouponPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShopifyCouponResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createShopifyCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createShopifyCoupon(params?: {
    context?: HttpContext
    body?: ShopifyCouponRequest
  }
): Observable<Array<ShopifyCouponResult>> {

    return this.createShopifyCoupon$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShopifyCouponResult>>) => r.body as Array<ShopifyCouponResult>)
    );
  }

  /**
   * Path part for operation getDeparturesForDate
   */
  static readonly GetDeparturesForDatePath = '/api/Admin/departuresfordate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeparturesForDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeparturesForDate$Response(params?: {
    date?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Folder>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetDeparturesForDatePath, 'post');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Folder>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeparturesForDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeparturesForDate(params?: {
    date?: string;
    context?: HttpContext
  }
): Observable<Array<Folder>> {

    return this.getDeparturesForDate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Folder>>) => r.body as Array<Folder>)
    );
  }

  /**
   * Path part for operation getHcChartData
   */
  static readonly GetHcChartDataPath = '/api/Admin/chartdata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHcChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getHcChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<Array<HighchartsChartData>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetHcChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HighchartsChartData>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHcChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getHcChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<Array<HighchartsChartData>> {

    return this.getHcChartData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HighchartsChartData>>) => r.body as Array<HighchartsChartData>)
    );
  }

  /**
   * Path part for operation getTourOperator
   */
  static readonly GetTourOperatorPath = '/api/Admin/get-tour-operator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTourOperator()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTourOperator$Response(params?: {
    op?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetTourOperatorPath, 'post');
    if (params) {
      rb.query('op', params.op, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTourOperator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTourOperator(params?: {
    op?: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getTourOperator$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getBookingVolumeChartData
   */
  static readonly GetBookingVolumeChartDataPath = '/api/Admin/booking-chart-data/booking-volume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingVolumeChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingVolumeChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<HighchartsChartData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingVolumeChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HighchartsChartData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingVolumeChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingVolumeChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<HighchartsChartData> {

    return this.getBookingVolumeChartData$Response(params).pipe(
      map((r: StrictHttpResponse<HighchartsChartData>) => r.body as HighchartsChartData)
    );
  }

  /**
   * Path part for operation getBookingsSuccessCountChartData
   */
  static readonly GetBookingsSuccessCountChartDataPath = '/api/Admin/booking-chart-data/bookings-booked-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsSuccessCountChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsSuccessCountChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<HighchartsChartData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingsSuccessCountChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HighchartsChartData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsSuccessCountChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsSuccessCountChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<HighchartsChartData> {

    return this.getBookingsSuccessCountChartData$Response(params).pipe(
      map((r: StrictHttpResponse<HighchartsChartData>) => r.body as HighchartsChartData)
    );
  }

  /**
   * Path part for operation getBookingsVolumePieChartData
   */
  static readonly GetBookingsVolumePieChartDataPath = '/api/Admin/booking-chart-data/bookings-booking-volume-pie';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsVolumePieChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsVolumePieChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<HighchartsChartData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingsVolumePieChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HighchartsChartData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsVolumePieChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsVolumePieChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<HighchartsChartData> {

    return this.getBookingsVolumePieChartData$Response(params).pipe(
      map((r: StrictHttpResponse<HighchartsChartData>) => r.body as HighchartsChartData)
    );
  }

  /**
   * Path part for operation getBookingsCountPieChartData
   */
  static readonly GetBookingsCountPieChartDataPath = '/api/Admin/booking-chart-data/bookings-bookings-count-pie';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsCountPieChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsCountPieChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<HighchartsChartData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingsCountPieChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HighchartsChartData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsCountPieChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingsCountPieChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<HighchartsChartData> {

    return this.getBookingsCountPieChartData$Response(params).pipe(
      map((r: StrictHttpResponse<HighchartsChartData>) => r.body as HighchartsChartData)
    );
  }

  /**
   * Path part for operation getCustomersCountChartData
   */
  static readonly GetCustomersCountChartDataPath = '/api/Admin/booking-chart-data/customers-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersCountChartData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCustomersCountChartData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<HighchartsChartData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetCustomersCountChartDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HighchartsChartData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomersCountChartData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCustomersCountChartData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<HighchartsChartData> {

    return this.getCustomersCountChartData$Response(params).pipe(
      map((r: StrictHttpResponse<HighchartsChartData>) => r.body as HighchartsChartData)
    );
  }

  /**
   * Path part for operation getBookingKpiIndependentData
   */
  static readonly GetBookingKpiIndependentDataPath = '/api/Admin/booking-kpi-independent-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingKpiIndependentData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingKpiIndependentData$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BookingsKpiIndependentData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingKpiIndependentDataPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingsKpiIndependentData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingKpiIndependentData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingKpiIndependentData(params?: {
    context?: HttpContext
  }
): Observable<BookingsKpiIndependentData> {

    return this.getBookingKpiIndependentData$Response(params).pipe(
      map((r: StrictHttpResponse<BookingsKpiIndependentData>) => r.body as BookingsKpiIndependentData)
    );
  }

  /**
   * Path part for operation getBookingKpiDependentData
   */
  static readonly GetBookingKpiDependentDataPath = '/api/Admin/booking-kpi-dependent-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingKpiDependentData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingKpiDependentData$Response(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<StrictHttpResponse<BookingsKpiDependentData>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetBookingKpiDependentDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookingsKpiDependentData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingKpiDependentData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBookingKpiDependentData(params?: {
    context?: HttpContext
    body?: RequestModelHighChartData
  }
): Observable<BookingsKpiDependentData> {

    return this.getBookingKpiDependentData$Response(params).pipe(
      map((r: StrictHttpResponse<BookingsKpiDependentData>) => r.body as BookingsKpiDependentData)
    );
  }

  /**
   * Path part for operation streamLogs
   */
  static readonly StreamLogsPath = '/api/Admin/stream/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLogs$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelLogEntryResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelLogEntryResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLogs(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelLogEntryResponseModelDataTableResult> {

    return this.streamLogs$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelLogEntryResponseModelDataTableResult>) => r.body as DatabaseModelLogEntryResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation getPricePredictionPriceModels
   */
  static readonly GetPricePredictionPriceModelsPath = '/api/Admin/price-prediction-price-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPricePredictionPriceModels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricePredictionPriceModels$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetPricePredictionPriceModelsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPricePredictionPriceModels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricePredictionPriceModels(params?: {
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getPricePredictionPriceModels$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation streamPricePredictions
   */
  static readonly StreamPricePredictionsPath = '/api/Admin/stream/price-predictions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamPricePredictions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamPricePredictions$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelPricePredictionResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamPricePredictionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelPricePredictionResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamPricePredictions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamPricePredictions(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelPricePredictionResponseModelDataTableResult> {

    return this.streamPricePredictions$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelPricePredictionResponseModelDataTableResult>) => r.body as DatabaseModelPricePredictionResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamUsers
   */
  static readonly StreamUsersPath = '/api/Admin/stream/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamUsers$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelUserResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUserResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamUsers(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelUserResponseModelDataTableResult> {

    return this.streamUsers$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUserResponseModelDataTableResult>) => r.body as DatabaseModelUserResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamVisitors
   */
  static readonly StreamVisitorsPath = '/api/Admin/stream/visitors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamVisitors()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamVisitors$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelAuthModalVisitorResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamVisitorsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelAuthModalVisitorResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamVisitors$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamVisitors(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelAuthModalVisitorResponseModelDataTableResult> {

    return this.streamVisitors$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelAuthModalVisitorResponseModelDataTableResult>) => r.body as DatabaseModelAuthModalVisitorResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamFcmTokens
   */
  static readonly StreamFcmTokensPath = '/api/Admin/stream/fcmtokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamFcmTokens()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFcmTokens$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelFcmTokenResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamFcmTokensPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelFcmTokenResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamFcmTokens$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFcmTokens(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelFcmTokenResponseModelDataTableResult> {

    return this.streamFcmTokens$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelFcmTokenResponseModelDataTableResult>) => r.body as DatabaseModelFcmTokenResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamFcmNotifications
   */
  static readonly StreamFcmNotificationsPath = '/api/Admin/stream/fcmnotifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamFcmNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFcmNotifications$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelFcmNotificationResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamFcmNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelFcmNotificationResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamFcmNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFcmNotifications(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelFcmNotificationResponseModelDataTableResult> {

    return this.streamFcmNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelFcmNotificationResponseModelDataTableResult>) => r.body as DatabaseModelFcmNotificationResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamDeletedFcmNotifications
   */
  static readonly StreamDeletedFcmNotificationsPath = '/api/Admin/stream/deletedfcmnotifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamDeletedFcmNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamDeletedFcmNotifications$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelDeletedNotificationResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamDeletedFcmNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelDeletedNotificationResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamDeletedFcmNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamDeletedFcmNotifications(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelDeletedNotificationResponseModelDataTableResult> {

    return this.streamDeletedFcmNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelDeletedNotificationResponseModelDataTableResult>) => r.body as DatabaseModelDeletedNotificationResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamBookings
   */
  static readonly StreamBookingsPath = '/api/Admin/stream/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamBookings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamBookings$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelUserBookedTripResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUserBookedTripResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamBookings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamBookings(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelUserBookedTripResponseModelDataTableResult> {

    return this.streamBookings$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUserBookedTripResponseModelDataTableResult>) => r.body as DatabaseModelUserBookedTripResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamTelephoneBookings
   */
  static readonly StreamTelephoneBookingsPath = '/api/Admin/stream/telephone-bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamTelephoneBookings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTelephoneBookings$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelTelephoneBookedTripResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamTelephoneBookingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelTelephoneBookedTripResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamTelephoneBookings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTelephoneBookings(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelTelephoneBookedTripResponseModelDataTableResult> {

    return this.streamTelephoneBookings$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelTelephoneBookedTripResponseModelDataTableResult>) => r.body as DatabaseModelTelephoneBookedTripResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamRoutes
   */
  static readonly StreamRoutesPath = '/api/Admin/stream/routes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamRoutes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamRoutes$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamRoutesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamRoutes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamRoutes(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelRouteResponseModelDataTableResult> {

    return this.streamRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>) => r.body as DatabaseModelRouteResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamLiveRoutes
   */
  static readonly StreamLiveRoutesPath = '/api/Admin/stream/live-routes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamLiveRoutes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLiveRoutes$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamLiveRoutesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamLiveRoutes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLiveRoutes(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelRouteResponseModelDataTableResult> {

    return this.streamLiveRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelRouteResponseModelDataTableResult>) => r.body as DatabaseModelRouteResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamTimetables
   */
  static readonly StreamTimetablesPath = '/api/Admin/stream/timetables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamTimetables()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTimetables$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelRouteTimetableResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamTimetablesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelRouteTimetableResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamTimetables$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTimetables(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelRouteTimetableResponseModelDataTableResult> {

    return this.streamTimetables$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelRouteTimetableResponseModelDataTableResult>) => r.body as DatabaseModelRouteTimetableResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamLivePrices
   */
  static readonly StreamLivePricesPath = '/api/Admin/stream/live-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamLivePrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLivePrices$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamLivePricesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamLivePrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLivePrices(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelPriceResponseModelDataTableResult> {

    return this.streamLivePrices$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>) => r.body as DatabaseModelPriceResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamPriceDrops
   */
  static readonly StreamPriceDropsPath = '/api/Admin/stream/price-drops';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamPriceDrops()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamPriceDrops$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamPriceDropsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamPriceDrops$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamPriceDrops(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelPriceResponseModelDataTableResult> {

    return this.streamPriceDrops$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>) => r.body as DatabaseModelPriceResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation topPriceDrops
   */
  static readonly TopPriceDropsPath = '/api/Admin/top-price-drops';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `topPriceDrops()` instead.
   *
   * This method doesn't expect any request body.
   */
  topPriceDrops$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PriceDropInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.TopPriceDropsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PriceDropInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `topPriceDrops$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  topPriceDrops(params?: {
    context?: HttpContext
  }
): Observable<Array<PriceDropInfo>> {

    return this.topPriceDrops$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PriceDropInfo>>) => r.body as Array<PriceDropInfo>)
    );
  }

  /**
   * Path part for operation streamLiveFlightPrices
   */
  static readonly StreamLiveFlightPricesPath = '/api/Admin/stream/live-flight-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamLiveFlightPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLiveFlightPrices$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelFlightPriceResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamLiveFlightPricesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelFlightPriceResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamLiveFlightPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamLiveFlightPrices(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelFlightPriceResponseModelDataTableResult> {

    return this.streamLiveFlightPrices$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelFlightPriceResponseModelDataTableResult>) => r.body as DatabaseModelFlightPriceResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamHistoricalPrices
   */
  static readonly StreamHistoricalPricesPath = '/api/Admin/stream/historical-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamHistoricalPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamHistoricalPrices$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamHistoricalPricesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamHistoricalPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamHistoricalPrices(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelPriceResponseModelDataTableResult> {

    return this.streamHistoricalPrices$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelPriceResponseModelDataTableResult>) => r.body as DatabaseModelPriceResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamCabins
   */
  static readonly StreamCabinsPath = '/api/Admin/stream/cabins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamCabins()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamCabins$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelCabinResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamCabinsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelCabinResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamCabins$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamCabins(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelCabinResponseModelDataTableResult> {

    return this.streamCabins$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelCabinResponseModelDataTableResult>) => r.body as DatabaseModelCabinResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamShips
   */
  static readonly StreamShipsPath = '/api/Admin/stream/ships';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamShips()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShips$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelShipResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamShipsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelShipResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamShips$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShips(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelShipResponseModelDataTableResult> {

    return this.streamShips$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelShipResponseModelDataTableResult>) => r.body as DatabaseModelShipResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamShippingCompanies
   */
  static readonly StreamShippingCompaniesPath = '/api/Admin/stream/shippingcompanies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamShippingCompanies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShippingCompanies$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelShippingCompanyResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamShippingCompaniesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelShippingCompanyResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamShippingCompanies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShippingCompanies(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelShippingCompanyResponseModelDataTableResult> {

    return this.streamShippingCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelShippingCompanyResponseModelDataTableResult>) => r.body as DatabaseModelShippingCompanyResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamBudgetObservations
   */
  static readonly StreamBudgetObservationsPath = '/api/Admin/stream/budgetobservations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamBudgetObservations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamBudgetObservations$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelBudgetObservationResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamBudgetObservationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelBudgetObservationResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamBudgetObservations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamBudgetObservations(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelBudgetObservationResponseModelDataTableResult> {

    return this.streamBudgetObservations$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelBudgetObservationResponseModelDataTableResult>) => r.body as DatabaseModelBudgetObservationResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamObservations
   */
  static readonly StreamObservationsPath = '/api/Admin/stream/observations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamObservations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamObservations$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelUserObservationResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamObservationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUserObservationResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamObservations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamObservations(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelUserObservationResponseModelDataTableResult> {

    return this.streamObservations$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUserObservationResponseModelDataTableResult>) => r.body as DatabaseModelUserObservationResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamFavouriteRoutes
   */
  static readonly StreamFavouriteRoutesPath = '/api/Admin/stream/favouriteroutes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamFavouriteRoutes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFavouriteRoutes$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelUserFavouriteRouteResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamFavouriteRoutesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUserFavouriteRouteResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamFavouriteRoutes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamFavouriteRoutes(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelUserFavouriteRouteResponseModelDataTableResult> {

    return this.streamFavouriteRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUserFavouriteRouteResponseModelDataTableResult>) => r.body as DatabaseModelUserFavouriteRouteResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamSpecialPrices
   */
  static readonly StreamSpecialPricesPath = '/api/Admin/stream/specialprices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamSpecialPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamSpecialPrices$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelSpecialPricesResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamSpecialPricesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelSpecialPricesResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamSpecialPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamSpecialPrices(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelSpecialPricesResponseModelDataTableResult> {

    return this.streamSpecialPrices$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelSpecialPricesResponseModelDataTableResult>) => r.body as DatabaseModelSpecialPricesResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamTaggedTimetables
   */
  static readonly StreamTaggedTimetablesPath = '/api/Admin/stream/tagged-timetables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamTaggedTimetables()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTaggedTimetables$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelTaggedTimetablesResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamTaggedTimetablesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelTaggedTimetablesResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamTaggedTimetables$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamTaggedTimetables(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelTaggedTimetablesResponseModelDataTableResult> {

    return this.streamTaggedTimetables$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelTaggedTimetablesResponseModelDataTableResult>) => r.body as DatabaseModelTaggedTimetablesResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamShopifyCoupons
   */
  static readonly StreamShopifyCouponsPath = '/api/Admin/stream/shopify-coupons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamShopifyCoupons()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShopifyCoupons$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelShopifyCouponResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamShopifyCouponsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelShopifyCouponResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamShopifyCoupons$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamShopifyCoupons(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelShopifyCouponResponseModelDataTableResult> {

    return this.streamShopifyCoupons$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelShopifyCouponResponseModelDataTableResult>) => r.body as DatabaseModelShopifyCouponResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation streamUploadedRouteFiles
   */
  static readonly StreamUploadedRouteFilesPath = '/api/Admin/stream/uploaded-route-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamUploadedRouteFiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamUploadedRouteFiles$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelUploadedRouteFileResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.StreamUploadedRouteFilesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelUploadedRouteFileResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamUploadedRouteFiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamUploadedRouteFiles(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelUploadedRouteFileResponseModelDataTableResult> {

    return this.streamUploadedRouteFiles$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelUploadedRouteFileResponseModelDataTableResult>) => r.body as DatabaseModelUploadedRouteFileResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/Admin/update-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params?: {
    newPassword?: string;
    context?: HttpContext
    body?: DatabaseModelUser
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateUserPath, 'put');
    if (params) {
      rb.query('newPassword', params.newPassword, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params?: {
    newPassword?: string;
    context?: HttpContext
    body?: DatabaseModelUser
  }
): Observable<boolean> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getLogTypes
   */
  static readonly GetLogTypesPath = '/api/Admin/logtypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetLogTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTypes(params?: {
    context?: HttpContext
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getLogTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation addSpecialPrices
   */
  static readonly AddSpecialPricesPath = '/api/Admin/add-special-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSpecialPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSpecialPrices$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AddSpecialPricesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSpecialPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSpecialPrices(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.addSpecialPrices$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation editSpecialPrices
   */
  static readonly EditSpecialPricesPath = '/api/Admin/edit-special-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSpecialPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSpecialPrices$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.EditSpecialPricesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSpecialPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSpecialPrices(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.editSpecialPrices$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteSpecialPrices
   */
  static readonly DeleteSpecialPricesPath = '/api/Admin/delete-special-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteSpecialPrices$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteSpecialPrices$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteSpecialPrices$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteSpecialPrices$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteSpecialPrices$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteSpecialPrices$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteSpecialPrices$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteSpecialPrices$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteSpecialPrices(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteSpecialPrices$Xml$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteSpecialPrices$Xml(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSpecialPrices$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteSpecialPrices$Plain$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteSpecialPricesPath, 'delete');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSpecialPrices$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteSpecialPrices$Plain(params?: {
    context?: HttpContext
    body?: DatabaseModelSpecialPrices
  }
): Observable<boolean> {

    return this.deleteSpecialPrices$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation addTaggedTimetable
   */
  static readonly AddTaggedTimetablePath = '/api/Admin/add-tagged-timetable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTaggedTimetable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTaggedTimetable$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AddTaggedTimetablePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTaggedTimetable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTaggedTimetable(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<string> {

    return this.addTaggedTimetable$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateTaggedTimetable
   */
  static readonly UpdateTaggedTimetablePath = '/api/Admin/update-tagged-timetable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaggedTimetable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaggedTimetable$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateTaggedTimetablePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTaggedTimetable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaggedTimetable(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<string> {

    return this.updateTaggedTimetable$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation deleteTaggedTimetable
   */
  static readonly DeleteTaggedTimetablePath = '/api/Admin/delete-tagged-timetable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteTaggedTimetable$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteTaggedTimetable$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteTaggedTimetable$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteTaggedTimetable$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteTaggedTimetable$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTaggedTimetable$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTaggedTimetable(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteTaggedTimetable$Xml$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteTaggedTimetable$Xml(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaggedTimetable$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteTaggedTimetable$Plain$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteTaggedTimetablePath, 'delete');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTaggedTimetable$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteTaggedTimetable$Plain(params?: {
    context?: HttpContext
    body?: DatabaseModelTaggedTimetables
  }
): Observable<boolean> {

    return this.deleteTaggedTimetable$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getRouteDetailForRoute
   */
  static readonly GetRouteDetailForRoutePath = '/api/Admin/route-detail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRouteDetailForRoute()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteDetailForRoute$Response(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DatabaseModelRouteDetail>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetRouteDetailForRoutePath, 'get');
    if (params) {
      rb.query('routeId', params.routeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelRouteDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRouteDetailForRoute$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteDetailForRoute(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<DatabaseModelRouteDetail> {

    return this.getRouteDetailForRoute$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelRouteDetail>) => r.body as DatabaseModelRouteDetail)
    );
  }

  /**
   * Path part for operation addOrEditRouteDetail
   */
  static readonly AddOrEditRouteDetailPath = '/api/Admin/route-detail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrEditRouteDetail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrEditRouteDetail$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelRouteDetail
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AddOrEditRouteDetailPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOrEditRouteDetail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrEditRouteDetail(params?: {
    context?: HttpContext
    body?: DatabaseModelRouteDetail
  }
): Observable<boolean> {

    return this.addOrEditRouteDetail$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation uploadRouteSpecificFile
   */
  static readonly UploadRouteSpecificFilePath = '/api/Admin/upload-route-specific-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadRouteSpecificFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadRouteSpecificFile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.UploadRouteSpecificFilePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadRouteSpecificFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadRouteSpecificFile(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.uploadRouteSpecificFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteRouteSpecificFile
   */
  static readonly DeleteRouteSpecificFilePath = '/api/Admin/delete-route-specific-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deleteRouteSpecificFile$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deleteRouteSpecificFile$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deleteRouteSpecificFile$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteRouteSpecificFile$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteRouteSpecificFile(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteRouteSpecificFile$Xml$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deleteRouteSpecificFile$Xml(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRouteSpecificFile$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteRouteSpecificFile$Plain$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteRouteSpecificFilePath, 'delete');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRouteSpecificFile$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deleteRouteSpecificFile$Plain(params?: {
    context?: HttpContext
    body?: DatabaseModelUploadedRouteFile
  }
): Observable<boolean> {

    return this.deleteRouteSpecificFile$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation checkValidity
   */
  static readonly CheckValidityPath = '/api/Admin/check-validity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkValidity()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidity$Response(params?: {
    validFrom?: string;
    validTill?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.CheckValidityPath, 'get');
    if (params) {
      rb.query('validFrom', params.validFrom, {});
      rb.query('validTill', params.validTill, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkValidity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidity(params?: {
    validFrom?: string;
    validTill?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.checkValidity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation sendCustomerNotifications
   */
  static readonly SendCustomerNotificationsPath = '/api/Admin/send-customer-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCustomerNotifications$Response(params?: {
    context?: HttpContext
    body?: RequestModelCustomerNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.SendCustomerNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendCustomerNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCustomerNotifications(params?: {
    context?: HttpContext
    body?: RequestModelCustomerNotification
  }
): Observable<boolean> {

    return this.sendCustomerNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getKpIs
   */
  static readonly GetKpIsPath = '/api/Admin/kpis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKpIs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpIs$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<StringDoubleTuple>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetKpIsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringDoubleTuple>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getKpIs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpIs(params?: {
    context?: HttpContext
  }
): Observable<Array<StringDoubleTuple>> {

    return this.getKpIs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringDoubleTuple>>) => r.body as Array<StringDoubleTuple>)
    );
  }

  /**
   * Path part for operation isAuthorized
   */
  static readonly IsAuthorizedPath = '/api/Admin/isauthorized';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAuthorized()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAuthorized$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.IsAuthorizedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isAuthorized$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAuthorized(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.isAuthorized$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
