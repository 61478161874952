<div style="padding: 10px">
  <ng-container *ngIf="showFilters">
    <!--<h1>Report</h1>-->
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
      <app-deeplink-filter
        header-filter
        [filterStateService]="filterStateService"
        [withFunctionButtons]="true"
        [withBadges]="true"
      >
      </app-deeplink-filter>
    </mat-expansion-panel>
  </ng-container>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateAddedRef let-row="row">
          {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date :
          'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'routeId',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'airportStartFrom', name: 'Airport Start From' },
        { prop: 'airportStartTo', name: 'Airport Start To' },
        { prop: 'airportEndFrom', name: 'Airport End From' },
        { prop: 'airportEndTo', name: 'Airport End To' },
        { prop: 'priceFlightAdult', name: 'Price Flight Adult' },
        { prop: 'priceFlightChild', name: 'Price Flight Child' },
        { prop: 'dateAdded', name: 'Date Added', cellTemplate: cellDateAddedRef }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
