/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MailApiConfiguration, MailApiConfigurationInterface } from './mail-api-configuration';

import { AdminMsbEmailTemplatesService } from './services/admin-msb-email-templates.service';
import { MsbEmailTemplatesService } from './services/msb-email-templates.service';
import { RodomniaEmailTemplatesService } from './services/rodomnia-email-templates.service';

/**
 * Provider for all MailApi services, plus MailApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    MailApiConfiguration,
    AdminMsbEmailTemplatesService,
    MsbEmailTemplatesService,
    RodomniaEmailTemplatesService
  ],
})
export class MailApiModule {
  static forRoot(customParams: MailApiConfigurationInterface): ModuleWithProviders<MailApiModule> {
    return {
      ngModule: MailApiModule,
      providers: [
        {
          provide: MailApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
