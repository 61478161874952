/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelFcmNotification } from '../models/database-model-fcm-notification';
import { RepsonseModelNotificationHistory } from '../models/repsonse-model-notification-history';
import { RequestModelFcmSync } from '../models/request-model-fcm-sync';
import { RequestModelNotificationPersist } from '../models/request-model-notification-persist';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserNotifications
   */
  static readonly GetUserNotificationsPath = '/api/PushNotifications/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotifications$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<RepsonseModelNotificationHistory>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.GetUserNotificationsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RepsonseModelNotificationHistory>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotifications(params?: {
    context?: HttpContext
  }
): Observable<RepsonseModelNotificationHistory> {

    return this.getUserNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<RepsonseModelNotificationHistory>) => r.body as RepsonseModelNotificationHistory)
    );
  }

  /**
   * Path part for operation resetHistoryCount
   */
  static readonly ResetHistoryCountPath = '/api/PushNotifications/reset-history-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetHistoryCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetHistoryCount$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.ResetHistoryCountPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetHistoryCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetHistoryCount(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.resetHistoryCount$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation sendTestNotificationToTestUser
   */
  static readonly SendTestNotificationToTestUserPath = '/api/PushNotifications/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTestNotificationToTestUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendTestNotificationToTestUser$Response(params?: {
    title?: string;
    body?: string;
    additionalOptions?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.SendTestNotificationToTestUserPath, 'get');
    if (params) {
      rb.query('title', params.title, {});
      rb.query('body', params.body, {});
      rb.query('additionalOptions', params.additionalOptions, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendTestNotificationToTestUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendTestNotificationToTestUser(params?: {
    title?: string;
    body?: string;
    additionalOptions?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.sendTestNotificationToTestUser$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation sendNotificationToCustomers
   */
  static readonly SendNotificationToCustomersPath = '/api/PushNotifications/sendtocustomers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendNotificationToCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotificationToCustomers$Response(params?: {
    title?: string;
    body?: string;
    additionalOptions?: string;
    pw?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.SendNotificationToCustomersPath, 'get');
    if (params) {
      rb.query('title', params.title, {});
      rb.query('body', params.body, {});
      rb.query('additionalOptions', params.additionalOptions, {});
      rb.query('pw', params.pw, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendNotificationToCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotificationToCustomers(params?: {
    title?: string;
    body?: string;
    additionalOptions?: string;
    pw?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.sendNotificationToCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation syncToken
   */
  static readonly SyncTokenPath = '/api/PushNotifications/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncToken$Response(params?: {
    context?: HttpContext
    body?: RequestModelFcmSync
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.SyncTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `syncToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncToken(params?: {
    context?: HttpContext
    body?: RequestModelFcmSync
  }
): Observable<boolean> {

    return this.syncToken$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation persistNotification
   */
  static readonly PersistNotificationPath = '/api/PushNotifications/persist-notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `persistNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  persistNotification$Response(params?: {
    context?: HttpContext
    body?: RequestModelNotificationPersist
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.PersistNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `persistNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  persistNotification(params?: {
    context?: HttpContext
    body?: RequestModelNotificationPersist
  }
): Observable<boolean> {

    return this.persistNotification$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deletePushNotification
   */
  static readonly DeletePushNotificationPath = '/api/PushNotifications/delete-entry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deletePushNotification$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  deletePushNotification$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deletePushNotification$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  deletePushNotification$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deletePushNotification$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  deletePushNotification$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  deletePushNotification$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  deletePushNotification$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deletePushNotification$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deletePushNotification(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deletePushNotification$Xml$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  deletePushNotification$Xml(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePushNotification$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deletePushNotification$Plain$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeletePushNotificationPath, 'delete');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePushNotification$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  deletePushNotification$Plain(params?: {
    context?: HttpContext
    body?: DatabaseModelFcmNotification
  }
): Observable<boolean> {

    return this.deletePushNotification$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteAllNotifications
   */
  static readonly DeleteAllNotificationsPath = '/api/PushNotifications/delete-all-existing-entries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllNotifications$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.DeleteAllNotificationsPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAllNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllNotifications(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.deleteAllNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
