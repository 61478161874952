/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelComment } from '../models/database-model-comment';
import { DatabaseModelCommentResponseModelDataTableResult } from '../models/database-model-comment-response-model-data-table-result';
import { RequestModelPaged } from '../models/request-model-paged';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getComments
   */
  static readonly GetCommentsPath = '/api/Comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelComment>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.GetCommentsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelComment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelComment>> {

    return this.getComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelComment>>) => r.body as Array<DatabaseModelComment>)
    );
  }

  /**
   * Path part for operation getCommentsFiltered
   */
  static readonly GetCommentsFilteredPath = '/api/Comments/comments-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommentsFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCommentsFiltered$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelComment
  }
): Observable<StrictHttpResponse<Array<DatabaseModelComment>>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.GetCommentsFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelComment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCommentsFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCommentsFiltered(params?: {
    context?: HttpContext
    body?: DatabaseModelComment
  }
): Observable<Array<DatabaseModelComment>> {

    return this.getCommentsFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelComment>>) => r.body as Array<DatabaseModelComment>)
    );
  }

  /**
   * Path part for operation streamComments
   */
  static readonly StreamCommentsPath = '/api/Comments/stream/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `streamComments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamComments$Response(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<StrictHttpResponse<DatabaseModelCommentResponseModelDataTableResult>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.StreamCommentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DatabaseModelCommentResponseModelDataTableResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `streamComments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  streamComments(params?: {
    context?: HttpContext
    body?: RequestModelPaged
  }
): Observable<DatabaseModelCommentResponseModelDataTableResult> {

    return this.streamComments$Response(params).pipe(
      map((r: StrictHttpResponse<DatabaseModelCommentResponseModelDataTableResult>) => r.body as DatabaseModelCommentResponseModelDataTableResult)
    );
  }

  /**
   * Path part for operation addComment
   */
  static readonly AddCommentPath = '/api/Comments/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComment$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelComment
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.AddCommentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComment(params?: {
    context?: HttpContext
    body?: DatabaseModelComment
  }
): Observable<boolean> {

    return this.addComment$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteComment
   */
  static readonly DeleteCommentPath = '/api/Comments/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment$Response(params?: {
    commentId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CommentsService.DeleteCommentPath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment(params?: {
    commentId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.deleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
