import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import {
  INavigationStateService,
  ToStringArray,
  UltraDynamicFilterItemConfig
} from '../../navigation/navigation-state-handler.class';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTransformer'
})
export class DateTransformerPipe implements PipeTransform {
  transform(
    value: string,
    dateTransformerArgs?: {
      inputFormat?: string;
      outputFormat?: string;
    }
  ): string {
    const parsedInputValue = moment(value, dateTransformerArgs.inputFormat);
    const outputValue = parsedInputValue.format(
      dateTransformerArgs.outputFormat
    );
    return outputValue;
  }
}

@Component({
  selector: 'app-deeplink-filter',
  templateUrl: './deeplink-filter.component.html',
  styleUrls: ['./deeplink-filter.component.scss'],
  providers: [DateTransformerPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeeplinkFilterComponent implements OnInit {
  @Input() whitelist: Array<string> = [];
  @Input() filterStateService: INavigationStateService<any>;
  @Input() withFunctionButtons = true;
  @Input() withBadges = true;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  filters: UltraDynamicFilterItemConfig[];
  getFilterData$: Observable<any>;
  readonly dynamicSearchableFilterOptions: {
    [key: string]: Observable<any[]>; // was SelectListOption before..
  } = {};
  readonly dynamicSearchSubjects: { [key: string]: Subject<string> } = {};

  filterObserver$: Observable<void>;

  readonly dateRangeBSubjects: {
    [key: string]: {
      startDate$: BehaviorSubject<Date>;
      endDate$: BehaviorSubject<Date>;
    };
  } = {};

  readonly dateRangeObservables: {
    [key: string]: Observable<string>;
  } = {};

  readonly badgeTextObservables: {
    [key: string]: Observable<string>;
  } = {};

  constructor(
    readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _dateTransformerPipe: DateTransformerPipe,
    private readonly _datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.whitelist.length) {
      // dependent on whitelist
      this.filters = this.filterStateService.config.filters.filter((x) =>
        this.whitelist.some(
          (whitelistEntry) => x.matrixParameter === whitelistEntry
        )
      );
    } else {
      // show all
      this.filters = this.filterStateService.config.filters;
    }

    this.getFilterData$ = this.filterStateService.filterState.getFilterData$;

    const multiSelectWithSearchDOMTakeCount = 500;
    // dynamically construct search subjects for each MULTISELECT_WITH_SEARCH or SELECT_WITH_SEARCH filter configuration
    this.filters
      .filter(
        (x) =>
          x.type === 'MULTISELECT_WITH_SEARCH' ||
          x.type === 'SELECT_WITH_SEARCH'
      )
      .forEach((fltrCfg) => {
        this.dynamicSearchSubjects[fltrCfg.matrixParameter] =
          new Subject<string>();
        this.dynamicSearchableFilterOptions[fltrCfg.matrixParameter] =
          this.dynamicSearchSubjects[fltrCfg.matrixParameter].pipe(
            startWith(''),
            switchMap((searchTerm) =>
              this.filterStateService.filterState.filterStates[
                fltrCfg.matrixParameter
              ].filterSettings$.pipe(
                map((filterSettings) => {
                  let data =
                    filterSettings?.availableItems?.map((o) => ({
                      id: o.id,
                      label: o.label,
                      class: o?.class ?? null
                    })) ?? [];
                  if (typeof searchTerm === 'string' && searchTerm.length > 0) {
                    return (
                      data
                        ?.filter((option) =>
                          option?.label
                            ?.toLocaleLowerCase()
                            ?.includes(searchTerm.toLocaleLowerCase())
                        )
                        .slice(0, multiSelectWithSearchDOMTakeCount) ?? []
                    );
                  }
                  return data.slice(0, multiSelectWithSearchDOMTakeCount);
                })
              )
            ),
            shareReplay()
          );
      });

    // sync badges and dateranges with filter
    if (this.withBadges) {
      this.filters.forEach(async (fltrCfg) => {
        this.badgeTextObservables[fltrCfg.matrixParameter] =
          await this.getBadgeText$(fltrCfg);
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  buttonClick(filter) {
    console.log(filter);
  }

  multiSelectOnSelectionChange(
    optionSelectionChange: MatOptionSelectionChange,
    filterCfg: UltraDynamicFilterItemConfig
  ) {
    if (
      filterCfg?.multiSelectOnSelectionChange &&
      optionSelectionChange?.isUserInput
    ) {
      filterCfg.multiSelectOnSelectionChange(optionSelectionChange);
    }
  }

  sendFilters(): void {
    navigator.clipboard.writeText(location.href);
  }

  resetFilters(): void {
    this.filterStateService.filterState.resetFilters();

    const dateRangeKeys = Object.keys(this.dateRangeBSubjects);
    if (dateRangeKeys?.length) {
      dateRangeKeys.forEach((k) => {
        this.dateRangeBSubjects[k].startDate$.next(null);
        this.dateRangeBSubjects[k].endDate$.next(null);
      });
    }
  }

  resetSingleFilter(filter: UltraDynamicFilterItemConfig): void {
    this.filterStateService.filterState.filterStates[
      filter.matrixParameter
    ]?.resetFilter();
    // do we need to call fltrCfg?.ngModelChange($event) here or not?
    this.dateRangeBSubjects[filter.matrixParameter]?.startDate$.next(null);
    this.dateRangeBSubjects[filter.matrixParameter]?.endDate$.next(null);
  }

  private async getBadgeText$(
    config: UltraDynamicFilterItemConfig
  ): Promise<Observable<string>> {
    const filterState =
      this.filterStateService.filterState.filterStates[config.matrixParameter];
    return combineLatest([
      filterState.isFilterDefault$,
      filterState.filterData$,
      filterState.filterSettings$
    ]).pipe(
      switchMap(([isDefault, value, settings]) => {
        if (isDefault) {
          return of<string>(null);
        }

        const singleValue =
          (Array.isArray(value.selectedIds)
            ? value.selectedIds[0]
            : value.selectedIds) ?? null;
        const singleValueLabel = (val: string): string => {
          return (
            settings?.availableItems?.find((x) => x?.id === val)?.label ?? val
          );
        };

        if (['DROPDOWN'].includes(config.type)) {
          return of<string>(singleValueLabel(singleValue));
        } else if (['DATERANGE'].includes(config.type)) {
          return this.dateRangeObservables[config.matrixParameter];
        } else if (
          [
            'MULTISELECT',
            'MULTISELECT_LEGACY',
            'MULTISELECT_WITH_SEARCH'
          ].includes(config.type)
        ) {
          return of<string>(
            (ToStringArray(value.selectedIds) ?? [])
              .map(singleValueLabel)
              .join(', ')
          );
        } else if (
          ['SELECT_WITH_SEARCH', 'DATEPICKER', 'WEEK_PICKER'].includes(
            config.type
          )
        ) {
          return of<string>(
            singleValue && config.dateTransformerArgs
              ? this._dateTransformerPipe.transform(
                  singleValue,
                  config.dateTransformerArgs
                )
              : singleValueLabel(singleValue)
          );
        }

        return of<string>(singleValue);
      })
    );
  }
}
