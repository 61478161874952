import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GlobalLoadingService } from '../shared/services/global-loading.service';
import { GlobalDataService } from '../shared/services/global-data.service';
import { AuthService } from '../apis/msb/services';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dashboard-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  readonly shake$ = new BehaviorSubject(false);

  constructor(
    private formBuilder: FormBuilder,
    private _globalLoadingService: GlobalLoadingService,
    readonly globalDataService: GlobalDataService,
    readonly _authService: AuthService,
    readonly router: Router
  ) {}

  readonly loginForm = this.formBuilder.group({
    email: '',
    password: ''
  });

  ngOnInit(): void {
    this._globalLoadingService.release();
  }

  readonly delayMs = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  };

  async onSubmit(): Promise<void> {
    const response = await this._authService
      .login(this.loginForm.value)
      .toPromise();
    if (response.success) {
      this.globalDataService.AccessToken = response.data;
      localStorage.setItem('APP_AUTH_USER', response.data);
      await this.router.navigate(['/']);
      alert('Login erfolgreich');
    } else {
      alert('Login fehlgeschlagen. Bitte überprüfe deine Daten');
      this.shake$.next(true);
      await this.delayMs(1000);
      this.shake$.next(false);
    }
  }
}
