<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateDeletedRef let-row="row">
          {{row?.dateDeleted | date : 'dd.MM.yy'}} @ {{row?.dateDeleted | date :
          'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'userId', name: 'User-Id' },
        { prop: 'notificationId', name: 'Notification-Id' },
        { prop: 'dateDeleted', name: 'Date_deleted', cellTemplate: cellDateDeletedRef }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
