<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div
    *ngIf="{
  filterData: filter$ | async,
  chartOptions: chartData$ | async
  } as data"
  >
    <ng-container *ngIf="data?.chartOptions?.length">
      <form-loading-container
        [height]="'506px'"
        [loadingState]="chartLoading$ | async"
      >
        <div class="row">
          <div class="col-md-6" *ngFor="let options of data?.chartOptions">
            <ng-container
              *ngTemplateOutlet="chartTemplate; context: {options: options}"
            >
            </ng-container>
          </div>
        </div>
      </form-loading-container>
    </ng-container>
  </div>
</div>

<!--TODO: create a generic chart component for rendering all the highchart charts-->
<!--CHART TEMPLATE-->
<ng-template #chartTemplate let-options="options">
  <mat-card>
    <!--                    <mat-card-header>CHART_TITLE</mat-card-header>-->
    <mat-card-content>
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="options"
        style="width: 100%; display: block"
      >
      </highcharts-chart>
    </mat-card-content>
  </mat-card>
</ng-template>
