<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateAddedRef let-row="row">
          {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date :
          'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'routeName', name: 'Routenname' },
        { prop: 'validFrom', name: 'Gültig Von' },
        { prop: 'validTill', name: 'Gültig Bis' },
        { prop: 'dateAdded', name: 'Datum hinzugefügt' },
        { prop: 'promoCode', name: 'Promo Kurztext' },
        { prop: 'promoDescription', name: 'Promo Beschreibung' },
        { prop: 'bookingCode', name: 'Reederei Buchungscode' },
        { prop: 'specialInfo', name: 'Special Info' },
        { prop: 'note', name: 'Notiz' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            #dynamicTableRef
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
            <ng-template #rowDetailTemplateRef let-row="row">
              <div style="max-width: 90vw">
                <app-agency-special
                  [specialPrices]="row"
                  (saved)="dynamicTableRef.reloadTable()"
                >
                </app-agency-special>
              </div>
            </ng-template>
          </dynamic-table>

          <mat-accordion
            style="width: 100%"
            *ngIf="{ saved: false } as expData"
          >
            <mat-expansion-panel
              #expansionPanelRef
              (afterExpand)="expData.saved = false;"
            >
              <mat-expansion-panel-header>
                <mat-panel-title style="display: flex; justify-content: right">
                  Neue Sonderpreise hinzufügen
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <app-agency-special
                  *ngIf="!expData.saved"
                  (saved)="expData.saved = true;expansionPanelRef.close();dynamicTableRef.reloadTable()"
                >
                </app-agency-special>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>
