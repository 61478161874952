import { Component, Input } from '@angular/core';
import { ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalLoadingService } from 'src/app/shared/services/global-loading.service';
import { DynamicTableComponent } from 'src/app/shared/components/dynamic-table/dynamic-table.component';
import { RequestModelPaged } from 'src/app/apis/core/models';
import { KeyValuePairStringString } from 'src/app/apis/core/models';
import { ResponseModelDataTableResultDatabaseModelLogEntry } from 'src/app/apis/core/models';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { AdminService } from '../../../apis/msb/services';

@Component({
  selector: 'app-log-table',
  templateUrl: './log-table.component.html',
  styleUrls: ['./log-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogTableComponent {
  @Input() userId?: number;

  @ViewChild(DynamicTableComponent, { static: true })
  logTableRef: DynamicTableComponent;

  logTypeValue: string;

  get userIdFilter() {
    if (this.userId) {
      return {
        key: 'userId',
        value: this.userId.toString(10)
      };
    } else {
      return null;
    }
  }

  constructor(
    public _globalLoadingService: GlobalLoadingService,
    private _adminService: AdminService,
    private _excelService: ExcelService,
    public _globalDataService: GlobalDataService
  ) {}

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      if (this.userId) {
        const customFilterKey = 'userId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push(this.userIdFilter);
        }
      }
      return this.transformLogResponse(
        this._adminService.streamLogs({ body: request })
      );
    };
  }

  /**
   * Parses LogValues as JSON
   */
  transformLogResponse(
    fetchDataObservable: Observable<ResponseModelDataTableResultDatabaseModelLogEntry>
  ): Observable<ResponseModelDataTableResultDatabaseModelLogEntry> {
    return fetchDataObservable.pipe(
      map((response) => {
        if (response.data && response.data.length) {
          response.data.forEach((row: any) => {
            if (row.logValue) {
              row.logValue = JSON.parse(row.logValue);
            }
          });
        }
        return response;
      })
    );
  }

  /**
   * Select Change for custom filter Events
   */
  logEventSelectionChange($event): void {
    if ($event && $event.value) {
      const logType: KeyValuePairStringString = $event.value;
      this.logTableRef.customFilter = JSON.parse(
        JSON.stringify(
          [
            {
              key: logType.key
            },
            this.userIdFilter
          ].filter((x) => x)
        )
      );
      this.logTableRef.resetPagination();
      this.logTableRef.reloadTable();
    }
  }

  resetLogEventFilter($mouseEvent): void {
    if ($mouseEvent.stopPropagation) {
      $mouseEvent.stopPropagation();
    }
    this.logTypeValue = null;
    this.logEventSelectionChange({
      value: {
        key: null
      }
    });
  }

  export(): void {
    if (!this.logTableRef?.customFilter?.length) {
      alert('Full export denied. Please select event filter!');
      return;
    }
    const pagedParams = this._excelService.BuildPagedParams('logDate');
    pagedParams.request.customFilter = this.logTableRef.customFilter;
    pagedParams.request.searchValue = this.logTableRef.page.search;
    const logsObservable = this.transformLogResponse(
      this._adminService.streamLogs({ body: pagedParams.request })
    );
    this._excelService.Export(logsObservable, 'Logs', true);
  }
}
