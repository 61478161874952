import { Injectable } from '@angular/core';
import { Observable, of, timer, throwError } from 'rxjs';
import { delay, delayWhen, map, flatMap, merge, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingService {

  public isLoading = false;
  public globalLoadingStates = new GlobalLoadingStates();
  public globalLoadingConfiguration = {
    LatencyMs: 150,
    LoadingSpinnerMinimumDisplayMs: 500,
    ErrorRepeats: 0,
    ErrorDelay: 500
  };

  /**
   * Shows Global-Loading-Animation (only when it takes longer than configurated latency)
   */
  public showGlobalLoader(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.globalLoadingStates.navigationComplete = false;
      this.globalLoadingStates.componentInitialized = false;
      this.globalLoadingStates.showSpinner = false;
      const loadingObservable = of({}).pipe(delay(this.globalLoadingConfiguration.LatencyMs));
      const loadSubscription = this.smartLoadingObservable(loadingObservable).subscribe(result => {
        if (this.isOfTypeLoading(result)) {
          this.globalLoadingStates.loadStartTime = Date.now();
          this.globalLoadingStates.showSpinner = true;
        }
      }, () => { }, () => {
        loadSubscription.unsubscribe();
      });
    }
  }

  /**
   * Releases Global-Loading-Animation and ensures to show it at least configurated 'minimumLoadingTime'
   */
  public release(doneCallback?: Function): void {
    const releaseTime = this.globalLoadingConfiguration.LoadingSpinnerMinimumDisplayMs + this.globalLoadingStates.loadStartTime - Date.now();
    of({}).pipe(delayWhen(() => timer(releaseTime))).subscribe(() => {
      this.isLoading = false;
      this.globalLoadingStates.componentInitialized = true;
      this.globalLoadingStates.navigationComplete = true;
      this.globalLoadingStates.showSpinner = false;
      if (doneCallback) {
        setTimeout(doneCallback);
      }
    });
  }

  /**
   * Takes an observable request as parameter and executes it and return after loadingSpinnerDelay a loading indicator
   * model to show a loading spinner with a delay.
   *
   * @param request Must be an Observable of any type
   */
  public smartLoadingObservable(request: Observable<any>): Observable<RequestIndicator | any> {
    return timer(this.globalLoadingConfiguration.LatencyMs).pipe(
      map(() => new RequestIndicator({ isLoading: true })),
      merge(request),
      retryWhen(attempts => {
        let count = 0;
        return attempts.pipe(
          flatMap(
            error => ++count >= this.globalLoadingConfiguration.ErrorRepeats ? throwError(error) : timer(this.globalLoadingConfiguration.ErrorDelay)
          )
        )
      })
    );
  }

  /**
   * Check if the request is the request indicator
   *
   * @param {RequestIndicator | any} result
   *
   * @returns {boolean}
   */
  public isOfTypeLoading(result: RequestIndicator | any): boolean {
    return result instanceof RequestIndicator ? result.isLoading : false;
  }

}

export class GlobalLoadingStates {
  loadStartTime: number;
  navigationComplete: boolean;
  componentInitialized: boolean;
  showSpinner: boolean;
  constructor() {
    this.navigationComplete = false;
    this.componentInitialized = false;
    this.showSpinner = false;
  }
}

/**
 * RequestIndicator Model
 */
export class RequestIndicator {

  isLoading: boolean;
  hasError: boolean;

  /**
   * Constructs RequestIndicator instance
   * @param data Optional and must be of type any
   */
  constructor(data?: any) {
    data = data || {};
    this.isLoading = data.hasOwnProperty('isLoading') ? data.isLoading : true;
    this.hasError = data.hasOwnProperty('hasError') ? data.hasError : true;
  }
}

export class SmartContainerStates {
  loading: boolean;
  spinner: boolean;
  error: boolean;
  constructor() {
    this.loading = false;
    this.spinner = false;
    this.error = false;
  }
}
