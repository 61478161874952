import { isEqual, sortBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

export interface ItemFilterDataInterface {
  selectedIds: string | Array<string>;
}

export interface ItemFilterItemInterface {
  id: string;
  label: string;
  class?: string;
  disabled?: boolean;
  labelSuffix?: string;
  data?: any;
}

export interface ItemFilterSettingsInterface {
  availableItems: Array<ItemFilterItemInterface>;
  defaultValue?: string | Array<string>;
  emptyDataItemAllowed?: boolean;
}

export const ToNumArray = (arr: Array<string> | string): Array<number> => {
  let newArr;
  if (!Array.isArray(arr)) {
    newArr = [arr];
  } else {
    newArr = arr;
  }
  return newArr.map((x) => parseInt(x, 10));
};

export const ToStringArray = (arr: Array<string> | string): Array<string> => {
  let newArr;
  if (!Array.isArray(arr)) {
    newArr = [arr];
  } else {
    newArr = arr;
  }
  return newArr;
};

export const ParseDateRange = (dateRangeFilterState): { dateStart: Date; dateEnd: Date } => {
  const splittedDateRangeString = (Array.isArray(dateRangeFilterState.selectedIds) ? dateRangeFilterState.selectedIds[0] : (dateRangeFilterState.selectedIds as string)).split('_');
  return {
    dateStart: moment(splittedDateRangeString[0], 'YYYY-MM-DD').toDate(),
    dateEnd: moment(splittedDateRangeString[1], 'YYYY-MM-DD').toDate()
  };
};

export interface ItemFilterControlOptions {
  debounceTime?: number;
}

export class ItemFilterState {
  readonly search$ = new BehaviorSubject<string>(null);
  private _filterSettings$: BehaviorSubject<ItemFilterSettingsInterface> = new BehaviorSubject<ItemFilterSettingsInterface>(null);

  readonly filterSettings$ = this._filterSettings$.pipe(
    distinctUntilChanged((a, b) => !!a && !!b && isEqual(a, b)),
    shareReplay(1)
  );

  private _filterData$: BehaviorSubject<ItemFilterDataInterface> = new BehaviorSubject({ selectedIds: [] });
  readonly filterData$: Observable<ItemFilterDataInterface>;
  readonly filterDataMulti$: Observable<string[]>;
  readonly filterDataSingle$: Observable<string>;
  readonly filterDataLabelSingle$: Observable<string>;
  readonly filterDataLabelMulti$: Observable<string>;
  readonly isFilterDefault$: Observable<boolean>;

  constructor(filterSettings: ItemFilterSettingsInterface, readonly data: ItemFilterDataInterface, private filterSettingsItems$: Observable<ItemFilterItemInterface[]> = null, private controlOptions: ItemFilterControlOptions = null) {
    this._filterSettings$.next(filterSettings);
    this._filterData$.next(data);

    if (filterSettingsItems$) {
      filterSettingsItems$.pipe(distinctUntilChanged((a, b) => !!a && !!b && isEqual(a, b))).subscribe((items) => {
        const settings = this._filterSettings$.getValue();
        this._filterSettings$.next({
          ...settings,
          availableItems: items
        } as ItemFilterSettingsInterface);
      });
    }
    if (controlOptions?.debounceTime) {
      // with debounce time for example for search inputs
      this.filterData$ = this._filterData$.pipe(
        debounceTime(controlOptions.debounceTime),
        distinctUntilChanged((a, b) => !!a && !!b && isEqual(a, b)),
        shareReplay(1)
      );
    } else {
      // regular filterData$
      this.filterData$ = this._filterData$.pipe(
        distinctUntilChanged((a, b) => !!a && !!b && isEqual(a, b)),
        shareReplay(1)
      );
    }

    this.filterDataMulti$ = this.filterData$.pipe(
      map((x) => {
        if (x.selectedIds) {
          if (Array.isArray(x.selectedIds)) {
            return x.selectedIds;
          } else {
            if (x.selectedIds?.indexOf(',') !== -1) {
              return x.selectedIds.split(',');
            } else {
              return [x.selectedIds];
            }
          }
        } else {
          return [];
        }
      }),
      shareReplay(1)
    );

    this.filterDataSingle$ = this.filterData$.pipe(
      map((x) => {
        const selectedValue = (Array.isArray(x.selectedIds) ? x.selectedIds[0] : x.selectedIds) ?? null;
        return selectedValue;
      }),
      shareReplay(1)
    );

    this.filterDataLabelSingle$ = combineLatest([this.filterData$, this.filterSettings$]).pipe(
      map(([x, filterSettings]) => {
        const selectedValue = (Array.isArray(x.selectedIds) ? x.selectedIds[0] : x.selectedIds) ?? null;
        return filterSettings?.availableItems?.find((x) => x?.id === selectedValue)?.label ?? '';
      }),
      shareReplay(1)
    );

    this.filterDataLabelMulti$ = combineLatest([this.filterData$, this.filterSettings$]).pipe(
      map(([x, filterSettings]) => {
        const values = ToStringArray(x.selectedIds) ?? [];
        const label = values.map((value) => filterSettings?.availableItems?.find((x) => x?.id === value)?.label).join(', ');
        return label;
      }),
      shareReplay(1)
    );

    this.isFilterDefault$ = combineLatest([this.filterData$, this.filterSettings$]).pipe(
      filter(([d, s]) => !!s),
      map(([d, s]) => {
        return !(!d || !isEqual(sortBy(d.selectedIds), sortBy(s.defaultValue)));
      })
    );
  }

  clone(filterSettingsItems$: Observable<ItemFilterItemInterface[]> = null) {
    return new ItemFilterState(this._filterSettings$.getValue(), this._filterData$.getValue(), filterSettingsItems$);
  }

  resetFilter() {
    this._filterData$.next({
      selectedIds: this._filterSettings$.getValue().defaultValue ?? null
    });
  }

  updateFilterData(selection: ItemFilterDataInterface) {
    const v = this._filterData$.getValue();
    if (!isEqual(sortBy(v.selectedIds), sortBy(selection.selectedIds))) {
      // TODO: only allow valid values?
      /*
      if (
        this._filterSettings$
          .getValue()
          .availableItems.map(i => i.id)
          .indexOf(v.selectedIds as string) > -1
      ) {
        this._filterData$.next({
          ...selection
        });
      }
       */
      this._filterData$.next({
        ...selection
      });
    }
  }

  getFilterDataSetter() {
    return (s: string) => {
      const v = this._filterData$.getValue().selectedIds;
      if (Array.isArray(v)) {
        let sNew = s.split(',');
        if (!this._filterSettings$.getValue().emptyDataItemAllowed) {
          sNew = sNew.filter((i) => i !== '');
        }
        this._filterData$.next({ selectedIds: sNew });
      } else {
        this._filterData$.next({ selectedIds: s });
      }
    };
  }

  getFilterDataGetter() {
    return () => {
      const v = this._filterData$.getValue().selectedIds;
      if (Array.isArray(v)) {
        return v.join(',');
      } else {
        return v as string;
      }
    };
  }

  getFilterSettingDefaultGetter() {
    return () => {
      const v = this._filterSettings$.getValue();
      if (Array.isArray(v.defaultValue)) {
        return v.defaultValue.join(',');
      } else {
        return v.defaultValue as string;
      }
    };
  }

  getDefaultSetter(defaultValue: string | string[]) {
    return () => {
      const v = this._filterSettings$.getValue();
      if (typeof defaultValue !== 'undefined') {
        this._filterSettings$.next({
          ...v,
          defaultValue
        });
      }
    };
  }
}
