import { Pipe, PipeTransform } from '@angular/core';
import { DatabaseModelUser } from '../../apis/msb/models/database-model-user';
import { FormioForm } from '@formio/angular';

@Pipe({
  name: 'accountDetailsForm'
})
export class AccountDetailsFormPipe implements PipeTransform {
  transform(user: DatabaseModelUser): FormioForm {
    let form: FormioForm = {};
    if (!user?.roles?.some((x) => x === 'ADMIN')) {
      // regular EDIT form
      form = {
        title: '',
        components: [
          {
            label: 'Email',
            tableView: true,
            key: 'email',
            type: 'email',
            defaultValue: user.email,
            validate: {
              required: true
            },
            input: true
          },
          {
            label: 'E-Mail Bestätigt',
            tableView: true,
            key: 'emailConfirmed',
            type: 'checkbox',
            input: true,
            defaultValue: user.emailConfirmed
          },
          {
            label: 'Vorname',
            tableView: true,
            validate: {
              required: true
            },
            key: 'firstName',
            type: 'textfield',
            input: true,
            defaultValue: user.firstName
          },
          {
            label: 'Nachname',
            tableView: true,
            validate: {
              required: true
            },
            key: 'lastName',
            type: 'textfield',
            input: true,
            defaultValue: user.lastName
          },
          {
            label: 'Rolle',
            widget: 'choicesjs',
            tableView: true,
            multiple: true,
            data: {
              values: [
                {
                  label: 'Registrierter Benutzer (FREE)',
                  value: 'FREE'
                },
                {
                  label: 'Kunde (CUSTOMER)',
                  value: 'CUSTOMER'
                }
              ]
            },
            key: 'roles',
            type: 'select',
            input: true,
            defaultValue: user.roles
          },
          {
            label: 'Max. Überwachungen',
            mask: false,
            tableView: false,
            delimiter: false,
            requireDecimal: false,
            inputFormat: 'plain',
            truncateMultipleSpaces: false,
            validate: {
              min: 1,
              max: 10,
              required: true
            },
            key: 'maxObservations',
            type: 'number',
            input: true,
            defaultValue: user.maxObservations
          },
          {
            label: 'Max. Favoriten',
            mask: false,
            tableView: false,
            delimiter: false,
            requireDecimal: false,
            inputFormat: 'plain',
            truncateMultipleSpaces: false,
            validate: {
              min: 1,
              max: 50,
              required: true
            },
            key: 'maxFavourites',
            type: 'number',
            input: true,
            defaultValue: user.maxFavourites
          },
          {
            label: 'Passwort (nur setzen wenn geändert werden soll!)',
            tableView: true,
            key: 'tmpPassword',
            type: 'textfield',
            input: true
          },
          {
            input: true,
            label: 'Speichern',
            tableView: false,
            size: 'md',
            leftIcon: '',
            rightIcon: '',
            block: false,
            action: 'submit',
            disableOnInvalid: true,
            theme: 'primary',
            type: 'button'
          }
        ]
      };
    } else {
      // no form at all. ADMIN account detected. we do NOT allow to edit admins!
      form = {
        title: '',
        components: [
          {
            html: '<p>Hier handelt es sich um einen ADMIN Benutzer.<br />Es ist nicht erlaubt ADMIN Accounts zu bearbeiten.</p>',
            label: 'Content',
            refreshOnChange: false,
            key: 'content',
            type: 'content',
            input: false,
            tableView: false
          }
        ]
      };
    }
    return form;
  }
}
