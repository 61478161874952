/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PortActivity } from '../models/port-activity';
import { RequestModelLandtripSearchFilterParams } from '../models/request-model-landtrip-search-filter-params';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class LandtripsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation filterLandtrips
   */
  static readonly FilterLandtripsPath = '/api/Landtrips/filter-landtrips';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filterLandtrips()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLandtrips$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<PortActivity>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.FilterLandtripsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PortActivity>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filterLandtrips$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLandtrips(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<PortActivity>> {

    return this.filterLandtrips$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PortActivity>>) => r.body as Array<PortActivity>)
    );
  }

  /**
   * Path part for operation filterLandtripIds
   */
  static readonly FilterLandtripIdsPath = '/api/Landtrips/filter-landtrip-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filterLandtripIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLandtripIds$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.FilterLandtripIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filterLandtripIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filterLandtripIds(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<string>> {

    return this.filterLandtripIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getLandtripsByIds
   */
  static readonly GetLandtripsByIdsPath = '/api/Landtrips/get-landtrips-by-guids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLandtripsByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLandtripsByIds$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<Array<PortActivity>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetLandtripsByIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PortActivity>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLandtripsByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLandtripsByIds(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<Array<PortActivity>> {

    return this.getLandtripsByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PortActivity>>) => r.body as Array<PortActivity>)
    );
  }

  /**
   * Path part for operation getFilterOptionsDifficultyLevel
   */
  static readonly GetFilterOptionsDifficultyLevelPath = '/api/Landtrips/filter-options-difficultylevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsDifficultyLevel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsDifficultyLevel$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsDifficultyLevelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsDifficultyLevel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsDifficultyLevel(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsDifficultyLevel$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsLanguages
   */
  static readonly GetFilterOptionsLanguagesPath = '/api/Landtrips/filter-options-languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsLanguages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsLanguages$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsLanguagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsLanguages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsLanguages(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsLanguages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsCatering
   */
  static readonly GetFilterOptionsCateringPath = '/api/Landtrips/filter-options-catering';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsCatering()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsCatering$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsCateringPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsCatering$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsCatering(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsCatering$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsExcursionDuration
   */
  static readonly GetFilterOptionsExcursionDurationPath = '/api/Landtrips/filter-options-excursion_duration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsExcursionDuration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsExcursionDuration$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsExcursionDurationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsExcursionDuration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsExcursionDuration(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsExcursionDuration$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsShip
   */
  static readonly GetFilterOptionsShipPath = '/api/Landtrips/filter-options-ship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsShip()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsShip$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsShipPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsShip$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsShip(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsShip$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsCompany
   */
  static readonly GetFilterOptionsCompanyPath = '/api/Landtrips/filter-options-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsCompany$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsCompany(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsTimetable
   */
  static readonly GetFilterOptionsTimetablePath = '/api/Landtrips/filter-options-timetable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsTimetable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsTimetable$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsTimetablePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsTimetable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsTimetable(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsTimetable$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

  /**
   * Path part for operation getFilterOptionsPort
   */
  static readonly GetFilterOptionsPortPath = '/api/Landtrips/filter-options-port';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptionsPort()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsPort$Response(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, LandtripsService.GetFilterOptionsPortPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilterOptionsPort$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFilterOptionsPort(params?: {
    context?: HttpContext
    body?: RequestModelLandtripSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getFilterOptionsPort$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

}
