/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelCruiseport } from '../models/database-model-cruiseport';
import { RequestModelSearchFilterParams } from '../models/request-model-search-filter-params';

@Injectable({
  providedIn: 'root',
})
export class CruiseportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCruiseports
   */
  static readonly GetCruiseportsPath = '/api/Cruiseports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCruiseports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCruiseports$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelCruiseport>>> {

    const rb = new RequestBuilder(this.rootUrl, CruiseportsService.GetCruiseportsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelCruiseport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCruiseports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCruiseports(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelCruiseport>> {

    return this.getCruiseports$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelCruiseport>>) => r.body as Array<DatabaseModelCruiseport>)
    );
  }

  /**
   * Path part for operation getCruiseportsFiltered
   */
  static readonly GetCruiseportsFilteredPath = '/api/Cruiseports/cruiseports-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCruiseportsFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCruiseportsFiltered$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<DatabaseModelCruiseport>>> {

    const rb = new RequestBuilder(this.rootUrl, CruiseportsService.GetCruiseportsFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelCruiseport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCruiseportsFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCruiseportsFiltered(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<Array<DatabaseModelCruiseport>> {

    return this.getCruiseportsFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelCruiseport>>) => r.body as Array<DatabaseModelCruiseport>)
    );
  }

}
