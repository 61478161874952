import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateFavouriteRoutesService } from './filter-state-favourite-routes.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-manage-favourite-routes',
  templateUrl: './favourite-routes.page.html',
  styleUrls: ['./favourite-routes.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavouriteRoutesComponent implements OnInit {
  @Input() userId?: number;
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  constructor(
    readonly filterStateService: FilterStateFavouriteRoutesService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      if (this.userId) {
        const customFilterKey = 'userId';
        if (!request.customFilter.some((x) => customFilterKey)) {
          request.customFilter.push({
            key: customFilterKey,
            value: this.userId.toString(10)
          });
        }
      }
      return this.adminService.streamFavouriteRoutes({ body: request });
    };
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }
}
