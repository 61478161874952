import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  delay,
  filter,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';

import theme from 'highcharts/themes/brand-dark';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';
import { HighchartsOptions } from '../../../shared/utils/highcharts-utils';

import { FilterStateOverviewService } from './filter-state-overview.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { BookingsKpiIndependentData } from '../../../apis/msb/models/bookings-kpi-independent-data';
import { BookingsKpiDependentData } from '../../../apis/msb/models/bookings-kpi-dependent-data';
import { RequestModelHighChartData } from '../../../apis/msb/models/request-model-high-chart-data';
import * as moment from 'moment';
import {
  HighChartOptions,
  LabelFormatter
} from '../../../shared/utils/chart.utils';
import { BookingsFilterState } from '../bookings/filter-state-bookings.service';

export const OverviewChartConfig = (
  startDate: string,
  endDate: string
): RequestModelHighChartData => {
  const chartConfig: RequestModelHighChartData = {
    startDate: startDate,
    endDate: endDate,
    dimension: 'yyyy-MM-dd',
    chartconfigs: [
      {
        title: 'Buchungen',
        columns: ['Booked', 'Canceled'],
        type: 'column'
      }
    ]
  };
  return chartConfig;
};

@Component({
  selector: 'app-analytics-overview',
  templateUrl: './overview.page.html',
  styleUrls: ['./overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit {
  readonly Highcharts = Highcharts;

  LabelFormatter = LabelFormatter;

  readonly currentMonthChartConfig$ = of({}).pipe(
    map(() => {
      const dateFrom = moment().startOf('month').format('YYYY-MM-DD');
      const dateTo = moment().endOf('month').format('YYYY-MM-DD');
      return OverviewChartConfig(dateFrom, dateTo);
    }),
    shareReplay(1)
  );

  readonly currentWeekChartConfig$ = of({}).pipe(
    map(() => {
      const dateFrom = moment().startOf('week').format('YYYY-MM-DD');
      const dateTo = moment().endOf('week').format('YYYY-MM-DD');
      return OverviewChartConfig(dateFrom, dateTo);
    }),
    shareReplay(1)
  );

  readonly kpiIndependentData$: Observable<BookingsKpiIndependentData> =
    this.adminService.getBookingKpiIndependentData().pipe(shareReplay(1));

  readonly kpiDependentData$: Observable<BookingsKpiDependentData> =
    this.currentMonthChartConfig$.pipe(
      filter((config) => !!config),
      switchMap((config) => {
        return this.adminService.getBookingKpiDependentData({
          body: config
        });
      }),
      // tap(() => this.kpiDataLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly currentWeekChartOptions$ = this.currentWeekChartConfig$.pipe(
    switchMap((config) => {
      return this.adminService.getBookingVolumeChartData({ body: config }).pipe(
        map((hcData): Highcharts.Options => {
          const fltr: BookingsFilterState = {
            dimension: 'yyyy-MM-dd'
          };
          return HighChartOptions(fltr, hcData, false, 'Buchungsvolume');
        })
      );
    }),
    shareReplay(1)
  );

  constructor(
    readonly filterStateService: FilterStateOverviewService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    theme(Highcharts);
    this.globalLoadingService.release();
  }
}
