import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoadingStates } from '../loading-container/loading-container.component';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { CruiseMetadataService } from '../../services/cruise-metadata.service';
import { DatabaseModelRouteTimetable } from '../../../apis/msb/models/database-model-route-timetable';
import * as moment from 'moment';
import { ExtendedComponentSchema, FormioForm } from '@formio/angular';
import { TakeOne$ } from '../../utils/asyn.utils';
import { GlobalLoadingService } from '../../services/global-loading.service';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { DatabaseModelTaggedTimetables } from '../../../apis/msb/models/database-model-tagged-timetables';

@Component({
  selector: 'app-tagged-timetables-form',
  templateUrl: './tagged-timetables-form.component.html',
  styleUrls: ['./tagged-timetables-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaggedTimetablesFormComponent {
  @Input() taggedTimetable: DatabaseModelTaggedTimetables;
  @Output() saved = new EventEmitter();

  get isEdit(): boolean {
    return !!this.taggedTimetable?.id;
  }

  readonly loading$ = new BehaviorSubject<LoadingStates>(LoadingStates.LOADING);

  readonly selectedTimetables$ = new BehaviorSubject<
    DatabaseModelRouteTimetable[]
  >(null);

  readonly selectedCruiseIds$: Observable<string[]> =
    this.selectedTimetables$.pipe(
      map((timetables) => timetables?.map((t) => t.cruiseId) ?? []),
      shareReplay(1)
    );

  groupByFn = (item: DatabaseModelRouteTimetable) => item?.route?.name;

  searchFn(term: string, item: DatabaseModelRouteTimetable) {
    term = term.toLowerCase();
    return (
      item?.route?.name?.toLowerCase()?.includes(term) ||
      item?.route?.ship?.shipname?.toLowerCase()?.includes(term) ||
      moment(item?.startDate)?.format('DD.MM.YYYY')?.includes(term)
    );
  }

  readonly formConfig$: Observable<FormioForm> =
    this.cruiseMetadataService.timetables$.pipe(
      filter((timetables) => !!timetables?.length),
      tap((timetables) => {
        if (this.isEdit) {
          const selectedTimetables = this.taggedTimetable.cruiseIds
            .map((cruiseId) => timetables?.find((x) => x.cruiseId === cruiseId))
            .filter((x) => !!x);
          if (selectedTimetables?.length) {
            this.selectedTimetables$.next(selectedTimetables);
          }
        }
        this.loading$.next(LoadingStates.LOADED);
      }),
      map(() => {
        const promoFormConfig: FormioForm & {
          components?: Array<
            ExtendedComponentSchema & {
              key: keyof DatabaseModelTaggedTimetables | 'submit';
            }
          >;
        } = {
          display: 'form',
          components: [
            {
              label: 'PROMO Code',
              labelPosition: 'left',
              labelWidth: 30,
              placeholder: 'Interner PROMO Code',
              tableView: true,
              key: 'offerType',
              type: 'textfield',
              input: true,
              validate: {
                required: true
              },
              defaultValue: this.isEdit ? this.taggedTimetable?.offerType : null
            },
            {
              label: 'PROMO Code Anzeigelabel',
              labelPosition: 'left',
              labelWidth: 30,
              placeholder: 'Anzeigelabel für Buchungsportal und App',
              tableView: true,
              key: 'offerTypeLabel',
              type: 'textfield',
              input: true,
              validate: {
                required: true
              },
              defaultValue: this.isEdit
                ? this.taggedTimetable?.offerTypeLabel
                : null
            },
            {
              label: 'PROMO Code Dasboard Titel',
              labelPosition: 'left',
              labelWidth: 30,
              placeholder: 'Dashboard Title für App',
              tableView: true,
              key: 'dashboardTitle',
              type: 'textfield',
              input: true,
              validate: {
                required: true
              },
              defaultValue: this.isEdit
                ? this.taggedTimetable?.dashboardTitle
                : null
            },
            {
              label: 'Gültig von',
              labelPosition: 'left',
              labelWidth: 30,
              tableView: false,
              enableMinDateInput: true,
              datePicker: {
                disableWeekends: false,
                disableWeekdays: false,
                minDate: moment().subtract(4, 'weeks').format()
              },
              validate: {
                required: true
              },
              enableMaxDateInput: false,
              defaultValue: this.isEdit ? this.taggedTimetable.validFrom : null,
              key: 'validFrom',
              type: 'datetime',
              input: true,
              widget: {
                type: 'calendar',
                displayInTimezone: 'viewer',
                locale: 'en',
                useLocaleSettings: false,
                allowInput: true,
                mode: 'single',
                enableTime: true,
                noCalendar: false,
                hourIncrement: 1,
                minuteIncrement: 1,
                time_24hr: false,
                minDate: null,
                disableWeekends: false,
                disableWeekdays: false,
                maxDate: null
              }
            },
            {
              label: 'Gültig bis',
              labelPosition: 'left',
              labelWidth: 30,
              tableView: false,
              enableMinDateInput: true,
              datePicker: {
                disableWeekends: false,
                disableWeekdays: false,
                minDate: moment().format()
              },
              validate: {
                required: true
              },
              enableMaxDateInput: false,
              defaultValue: this.isEdit ? this.taggedTimetable.validTill : null,
              key: 'validTill',
              type: 'datetime',
              input: true,
              widget: {
                type: 'calendar',
                displayInTimezone: 'viewer',
                locale: 'en',
                useLocaleSettings: false,
                allowInput: true,
                mode: 'single',
                enableTime: true,
                noCalendar: false,
                hourIncrement: 1,
                minuteIncrement: 1,
                time_24hr: false,
                minDate: null,
                disableWeekends: false,
                disableWeekdays: false,
                maxDate: null
              }
            },
            {
              label: 'Notiz',
              labelPosition: 'left',
              labelWidth: 30,
              autoExpand: false,
              tableView: true,
              key: 'note',
              type: 'textarea',
              input: true,
              defaultValue: this.isEdit ? this.taggedTimetable.note : null
            },
            {
              type: 'button',
              label: this.isEdit ? 'Änderungen Speichern' : 'Speichern',
              key: 'submit',
              disableOnInvalid: true,
              input: true
            }
          ]
        };
        return promoFormConfig;
      }),
      shareReplay()
    );

  constructor(
    readonly cruiseMetadataService: CruiseMetadataService,
    private readonly _globalLoadingService: GlobalLoadingService,
    private readonly _adminService: AdminService
  ) {}

  async saveChanges(data) {
    const cruiseIds = await TakeOne$(this.selectedCruiseIds$);
    this._globalLoadingService.showGlobalLoader();
    const taggedTimetablePayload: DatabaseModelTaggedTimetables = {
      cruiseIds,
      offerType: data.offerType,
      offerTypeLabel: data.offerTypeLabel,
      dashboardTitle: data.dashboardTitle,
      note: data.note,
      validFrom: moment.utc(data.validFrom).format(),
      validTill: moment.utc(data.validTill).format()
    };
    let response: string;
    if (this.taggedTimetable?.id) {
      // update existing
      response = await this._adminService
        .updateTaggedTimetable({
          body: {
            ...taggedTimetablePayload,
            id: this.taggedTimetable.id
          }
        })
        .toPromise();
    } else {
      // add new
      response = await this._adminService
        .addTaggedTimetable({ body: taggedTimetablePayload })
        .toPromise();
    }
    if (response === 'OK') {
      this.saved.next();
    } else {
      alert(response);
    }
    alert(
      'Speichern erfolgreich! Es kann bis zu 15 Minuten dauern, bis die Angebote in der App und im Buchungsportal sichtbar sind!'
    );
    this._globalLoadingService.release();
  }

  async delete() {
    let success = false;
    try {
      success = await this._adminService
        .deleteTaggedTimetable({ body: this.taggedTimetable })
        .toPromise();
    } catch (e) {}
    if (!success) {
      alert('Fehler beim Löschen');
      return;
    }
    this.saved.next();
  }
}
