/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelCabin } from '../models/database-model-cabin';
import { RequestModelCabinSearchFilterParams } from '../models/request-model-cabin-search-filter-params';
import { RequestModelPriceRecalculation } from '../models/request-model-price-recalculation';
import { RequestModelVacanyCheck } from '../models/request-model-vacany-check';
import { ResponseCabinCategoryOption } from '../models/response-cabin-category-option';
import { ResponseModelCabin } from '../models/response-model-cabin';
import { ResponseModelCabinDetail } from '../models/response-model-cabin-detail';
import { ResponseModelPriceModelMapEntry } from '../models/response-model-price-model-map-entry';
import { ResponseModelPriceRecalculation } from '../models/response-model-price-recalculation';
import { XmlApiAvailableCabinOffer } from '../models/xml-api-available-cabin-offer';
import { XmlApiAvailableCabinsOfTypeOnShip } from '../models/xml-api-available-cabins-of-type-on-ship';

@Injectable({
  providedIn: 'root',
})
export class CabinsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCabinCategoryOptions
   */
  static readonly GetCabinCategoryOptionsPath = '/api/Cabins/cabinCategories/filter/options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabinCategoryOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinCategoryOptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseCabinCategoryOption>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCabinCategoryOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseCabinCategoryOption>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCabinCategoryOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinCategoryOptions(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseCabinCategoryOption>> {

    return this.getCabinCategoryOptions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseCabinCategoryOption>>) => r.body as Array<ResponseCabinCategoryOption>)
    );
  }

  /**
   * Path part for operation getPriceModelsForShippingCompany
   */
  static readonly GetPriceModelsForShippingCompanyPath = '/api/Cabins/pricemodels/shipping-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPriceModelsForShippingCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceModelsForShippingCompany$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseModelPriceModelMapEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetPriceModelsForShippingCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelPriceModelMapEntry>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPriceModelsForShippingCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPriceModelsForShippingCompany(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseModelPriceModelMapEntry>> {

    return this.getPriceModelsForShippingCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelPriceModelMapEntry>>) => r.body as Array<ResponseModelPriceModelMapEntry>)
    );
  }

  /**
   * Path part for operation getCabinsForShip
   */
  static readonly GetCabinsForShipPath = '/api/Cabins/all/cabins/forShip/{shipId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabinsForShip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinsForShip$Response(params: {
    shipId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelCabin>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCabinsForShipPath, 'get');
    if (params) {
      rb.path('shipId', params.shipId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelCabin>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCabinsForShip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinsForShip(params: {
    shipId: number;
    context?: HttpContext
  }
): Observable<Array<DatabaseModelCabin>> {

    return this.getCabinsForShip$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelCabin>>) => r.body as Array<DatabaseModelCabin>)
    );
  }

  /**
   * Path part for operation getCabinsCategoriesFiltered
   */
  static readonly GetCabinsCategoriesFilteredPath = '/api/Cabins/cabin-categories-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabinsCategoriesFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCabinsCategoriesFiltered$Response(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<ResponseModelCabin>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCabinsCategoriesFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelCabin>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCabinsCategoriesFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCabinsCategoriesFiltered(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<Array<ResponseModelCabin>> {

    return this.getCabinsCategoriesFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelCabin>>) => r.body as Array<ResponseModelCabin>)
    );
  }

  /**
   * Path part for operation getCabins
   */
  static readonly GetCabinsPath = '/api/Cabins/calculated-cabins-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabins()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCabins$Response(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<ResponseModelCabinDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCabinsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelCabinDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCabins$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCabins(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<Array<ResponseModelCabinDetail>> {

    return this.getCabins$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelCabinDetail>>) => r.body as Array<ResponseModelCabinDetail>)
    );
  }

  /**
   * Path part for operation getCheapestPriceForCabinCategory
   */
  static readonly GetCheapestPriceForCabinCategoryPath = '/api/Cabins/cheapest-price-for-cabin-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheapestPriceForCabinCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCheapestPriceForCabinCategory$Response(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<StrictHttpResponse<ResponseModelCabin>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCheapestPriceForCabinCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseModelCabin>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheapestPriceForCabinCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCheapestPriceForCabinCategory(params?: {
    context?: HttpContext
    body?: RequestModelCabinSearchFilterParams
  }
): Observable<ResponseModelCabin> {

    return this.getCheapestPriceForCabinCategory$Response(params).pipe(
      map((r: StrictHttpResponse<ResponseModelCabin>) => r.body as ResponseModelCabin)
    );
  }

  /**
   * Path part for operation recalculateCabinPrice
   */
  static readonly RecalculateCabinPricePath = '/api/Cabins/recalculate-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recalculateCabinPrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recalculateCabinPrice$Response(params?: {
    context?: HttpContext
    body?: RequestModelPriceRecalculation
  }
): Observable<StrictHttpResponse<ResponseModelPriceRecalculation>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.RecalculateCabinPricePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseModelPriceRecalculation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `recalculateCabinPrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recalculateCabinPrice(params?: {
    context?: HttpContext
    body?: RequestModelPriceRecalculation
  }
): Observable<ResponseModelPriceRecalculation> {

    return this.recalculateCabinPrice$Response(params).pipe(
      map((r: StrictHttpResponse<ResponseModelPriceRecalculation>) => r.body as ResponseModelPriceRecalculation)
    );
  }

  /**
   * Path part for operation getCabinByTypeAndShip
   */
  static readonly GetCabinByTypeAndShipPath = '/api/Cabins/{cabinType}/{shipId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCabinByTypeAndShip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinByTypeAndShip$Response(params: {
    cabinType: number;
    shipId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelCabin>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetCabinByTypeAndShipPath, 'get');
    if (params) {
      rb.path('cabinType', params.cabinType, {});
      rb.path('shipId', params.shipId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelCabin>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCabinByTypeAndShip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCabinByTypeAndShip(params: {
    cabinType: number;
    shipId: number;
    context?: HttpContext
  }
): Observable<Array<DatabaseModelCabin>> {

    return this.getCabinByTypeAndShip$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelCabin>>) => r.body as Array<DatabaseModelCabin>)
    );
  }

  /**
   * Path part for operation getAvailableCabinsOfferIds
   */
  static readonly GetAvailableCabinsOfferIdsPath = '/api/Cabins/available-cabins-offerIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableCabinsOfferIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCabinsOfferIds$Response(params?: {
    cruiseId?: string;
    airport?: string;
    adults?: number;
    childAges?: Array<number>;
    childBirthdays?: Array<string>;
    cabinCode?: string;
    promoCode?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<XmlApiAvailableCabinOffer>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetAvailableCabinsOfferIdsPath, 'get');
    if (params) {
      rb.query('cruiseId', params.cruiseId, {});
      rb.query('airport', params.airport, {});
      rb.query('adults', params.adults, {});
      rb.query('childAges', params.childAges, {});
      rb.query('childBirthdays', params.childBirthdays, {});
      rb.query('cabinCode', params.cabinCode, {});
      rb.query('promoCode', params.promoCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XmlApiAvailableCabinOffer>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableCabinsOfferIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCabinsOfferIds(params?: {
    cruiseId?: string;
    airport?: string;
    adults?: number;
    childAges?: Array<number>;
    childBirthdays?: Array<string>;
    cabinCode?: string;
    promoCode?: string;
    context?: HttpContext
  }
): Observable<Array<XmlApiAvailableCabinOffer>> {

    return this.getAvailableCabinsOfferIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<XmlApiAvailableCabinOffer>>) => r.body as Array<XmlApiAvailableCabinOffer>)
    );
  }

  /**
   * Path part for operation getAvailableVacancyBaid
   */
  static readonly GetAvailableVacancyBaidPath = '/api/Cabins/vacancy-check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableVacancyBaid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAvailableVacancyBaid$Response(params?: {
    context?: HttpContext
    body?: RequestModelVacanyCheck
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetAvailableVacancyBaidPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableVacancyBaid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAvailableVacancyBaid(params?: {
    context?: HttpContext
    body?: RequestModelVacanyCheck
  }
): Observable<string> {

    return this.getAvailableVacancyBaid$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAvailableCabinsOfTypeOnShip
   */
  static readonly GetAvailableCabinsOfTypeOnShipPath = '/api/Cabins/available-cabins-of-type-on-ship';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableCabinsOfTypeOnShip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCabinsOfTypeOnShip$Response(params?: {
    oid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<XmlApiAvailableCabinsOfTypeOnShip>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetAvailableCabinsOfTypeOnShipPath, 'get');
    if (params) {
      rb.query('oid', params.oid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<XmlApiAvailableCabinsOfTypeOnShip>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableCabinsOfTypeOnShip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCabinsOfTypeOnShip(params?: {
    oid?: string;
    context?: HttpContext
  }
): Observable<XmlApiAvailableCabinsOfTypeOnShip> {

    return this.getAvailableCabinsOfTypeOnShip$Response(params).pipe(
      map((r: StrictHttpResponse<XmlApiAvailableCabinsOfTypeOnShip>) => r.body as XmlApiAvailableCabinsOfTypeOnShip)
    );
  }

  /**
   * Path part for operation getAvailablePriceModels
   */
  static readonly GetAvailablePriceModelsPath = '/api/Cabins/available-price-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailablePriceModels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePriceModels$Response(params?: {
    shippingCompanyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CabinsService.GetAvailablePriceModelsPath, 'get');
    if (params) {
      rb.query('shippingCompanyId', params.shippingCompanyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailablePriceModels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePriceModels(params?: {
    shippingCompanyId?: number;
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getAvailablePriceModels$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
