/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DashboardConfigEntry } from '../models/dashboard-config-entry';
import { RequestModelSearchFilterParams } from '../models/request-model-search-filter-params';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDynamicDashboardV2Config
   */
  static readonly GetDynamicDashboardV2ConfigPath = '/api/Dashboard/dynamic-dashboard-v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDynamicDashboardV2Config()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDynamicDashboardV2Config$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<DashboardConfigEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetDynamicDashboardV2ConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DashboardConfigEntry>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDynamicDashboardV2Config$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDynamicDashboardV2Config(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<Array<DashboardConfigEntry>> {

    return this.getDynamicDashboardV2Config$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DashboardConfigEntry>>) => r.body as Array<DashboardConfigEntry>)
    );
  }

}
