<div style="padding: 10px">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>Neuer Coupon</mat-expansion-panel-header>
      <div style="display: flex; padding: 20px; column-gap: 20px">
        <ng-container *ngIf="couponResult$ | async as couponResult">
          <ul>
            <li *ngFor="let coupon of couponResult">
              {{coupon.email}}
              <textarea [ngModel]="coupon.emailText"></textarea>
            </li>
          </ul>
        </ng-container>
        <mat-form-field appearance="standard">
          <mat-label>MyJack E-Mail</mat-label>
          <input
            matInput
            placeholder="Placeholder"
            autocomplete="off"
            [(ngModel)]="couponRequest.email"
            placeholder="E-Mail"
          />
          <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Datum im Format YYYY-MM-DD</mat-label>
          <input
            matInput
            placeholder="Placeholder"
            autocomplete="off"
            [(ngModel)]="couponRequest.date"
            placeholder="YYYY-MM-DD"
          />
          <mat-icon matSuffix>date_range</mat-icon>
        </mat-form-field>
        <button mat-raised-button (click)="addCoupon()">Coupon Anlegen</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header
        >MyJACK Abreisen durchsuchen</mat-expansion-panel-header
      >
      <mat-form-field appearance="standard">
        <mat-label>Abreisedatum im Format YYYY-MM-DD</mat-label>
        <input
          matInput
          placeholder="Placeholder"
          autocomplete="off"
          [ngModel]="myJackSearch$ | async"
          (ngModelChange)="myJackSearch$.next($event);"
          placeholder="YYYY-MM-DD"
        />
        <mat-icon matSuffix>date_range</mat-icon>
      </mat-form-field>

      <ng-container
        *ngIf="{ dataSource: myJackSearchResults$ | async } as myJackData"
      >
        <ng-template #MyJackNoFoldersTemplateRef>
          Keine Abreisen in MyJACK gefunden...
        </ng-template>
        <ng-container
          *ngIf="myJackData?.dataSource?.length; else MyJackNoFoldersTemplateRef"
        >
          <table
            mat-table
            style="width: 100%"
            [dataSource]="myJackData.dataSource"
          >
            <ng-container matColumnDef="folder_number">
              <th mat-header-cell *matHeaderCellDef>Vorgang</th>
              <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>
            <ng-container matColumnDef="start_date">
              <th mat-header-cell *matHeaderCellDef>Abreisedatum</th>
              <td mat-cell *matCellDef="let element">
                {{element.start_date | date : 'dd.MM.yy'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
              <td mat-cell *matCellDef="let element">
                {{element.description}}
              </td>
            </ng-container>
            <ng-container matColumnDef="customerEmail">
              <th mat-header-cell *matHeaderCellDef>E-Mail</th>
              <td mat-cell *matCellDef="let element">
                {{element.customer?.email}}
              </td>
            </ng-container>
            <ng-container matColumnDef="customerFirstName">
              <th mat-header-cell *matHeaderCellDef>Vorname</th>
              <td mat-cell *matCellDef="let element">
                {{element.customer?.first_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="customerLastName">
              <th mat-header-cell *matHeaderCellDef>Nachname</th>
              <td mat-cell *matCellDef="let element">
                {{element.customer?.last_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="tourOperator">
              <th mat-header-cell *matHeaderCellDef>Veranstalter</th>
              <td mat-cell *matCellDef="let element">
                {{getTourOperator(element)}}
              </td>
            </ng-container>
            <ng-container matColumnDef="webCode">
              <th mat-header-cell *matHeaderCellDef>WebCode</th>
              <td mat-cell *matCellDef="let element">
                <a
                  [href]="'https://meinereisedaten.de/' + element.web_code"
                  target="_blank"
                >
                  {{element.web_code}}
                </a>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="myJackSearchDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: myJackSearchDisplayedColumns;"
            ></tr>
          </table>
        </ng-container>
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
      <app-deeplink-filter
        header-filter
        [filterStateService]="filterStateService"
        [withFunctionButtons]="true"
        [withBadges]="true"
      >
      </app-deeplink-filter>
    </mat-expansion-panel>
  </mat-accordion>

  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #rowDetailTemplateRef let-row="row">
          <div style="width: 90vw">
            <form-loading-container
              [loadingState]="tableLoading$ | async"
              [height]="'500px'"
            >
              <div style="max-height: 500px; overflow-y: auto">
                <div
                  style="border-bottom: 1px dashed black; padding: 10px 0 20px"
                >
                  <h3 style="text-align: center">E-Mail Text</h3>
                  <div style="white-space: pre-line">{{row?.emailText}}</div>
                </div>
                <br />
                <div>
                  <h3 style="text-align: center">Folder Data</h3>
                  <pre>{{row?.rawJson | json}}</pre>
                </div>
              </div>
            </form-loading-container>
          </div>
        </ng-template>

        <ng-template #cellValidTillRef let-row="row">
          {{row?.validTill?.day}}.{{row?.validTill?.month}}.{{row?.validTill?.year}}
<!--          {{row?.validTill | date : 'dd.MM.yy'}} @ {{row?.ValidTill | date : 'shortTime'}}-->
        </ng-template>

        <ng-template #cellEMailSentOnRef let-row="row">
          {{row?.emailSentOn | date : 'dd.MM.yy'}} @ {{row?.eMailSentOn | date : 'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
  defaultOrder: 'emailSentOn',
  defaultOrderDirection: 'desc',
  pageLength: 5,
  columns: [
    { prop: 'id', name: 'Id' },
    { prop: 'bewotecCustomerId', name: 'Bewotec Customer Id' },
    { prop: 'bookingNumber', name: 'Vorgangsnummer' },
    { prop: 'couponCode', name: 'Gutscheincode' },
    { prop: 'couponValue', name: 'Gutschein Wert' },
    { prop: 'validTill', name: 'Gültig bis', cellTemplate: cellValidTillRef  },
    { prop: 'email', name: 'E-Mail' },
    { prop: 'emailText', name: 'E-Mail content' },
    { prop: 'emailSentOn', name: 'E-Mail gesendet am', cellTemplate: cellEMailSentOnRef }
  ],
  fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
