import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { debounceTime, filter, map, shareReplay } from 'rxjs/operators';
import { PATHS } from './shared/enums/paths.enum';
import { ROUTE } from './shared/enums/route.enum';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  readonly isHandset$: Observable<boolean> = this._breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  readonly navItems = [
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.ANALYTICS_OVERVIEW}`,
    //   icon: 'auto_graph',
    //   title: 'Übersicht'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.ANALYTICS_CUSTOMER_INTERACTION}`,
    //   icon: 'auto_graph',
    //   title: 'Verhalten'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.ANALYTICS_BOOKINGS}`,
    //   icon: 'auto_graph',
    //   title: 'Buchungsstatistik'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_ACCOUNTS}`,
    //   icon: 'supervisor_account',
    //   title: 'Accounts'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_FCM_TOKENS}`,
    //   icon: 'vpn_key',
    //   title: 'Geräte'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_NOTIFICATIONS_SENT}`,
    //   icon: 'notifications',
    //   title: 'Benachrichtigungen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_NOTIFICATIONS_DELETED}`,
    //   icon: 'notifications',
    //   title: 'Gelöschte Benachrichtigungen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_SHOPIFY_COUPONS}`,
    //   icon: 'local_offer',
    //   title: 'Dankeschön-Paket Gutscheine'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_BOOKINGS_TABLE}`,
    //   icon: 'book',
    //   title: 'Buchungen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_ROUTES}`,
    //   icon: 'directions',
    //   title: 'Routen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_TIMETABLES}`,
    //   icon: 'timeline',
    //   title: 'Abreisetermine'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_LIVE_PRICES}`,
    //   icon: 'euro_symbol',
    //   title: 'Preise (Aktuell)'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_LIVE_PRICE_DROPS}`,
    //   icon: 'euro_symbol',
    //   title: 'Preisestürze'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_HISTORICAL_PRICES}`,
    //   icon: 'euro_symbol',
    //   title: 'Preise (Historisch)'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_FLIGHT_PRICES}`,
    //   icon: 'flight',
    //   title: 'Flug Preise (Aktuell)'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_SPECIAL_PRICES}`,
    //   icon: 'star',
    //   title: 'Sonderkondigente'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_TAGGED_TIMETABLES}`,
    //   icon: 'star',
    //   title: 'Angebots Tagging'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_SHIPPING_COMPANIES}`,
    //   icon: 'directions_boat',
    //   title: 'Reedereien'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_SHIPS}`,
    //   icon: 'directions_boat',
    //   title: 'Schiffe'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_CABINS}`,
    //   icon: 'meeting_room',
    //   title: 'Kabinen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_BUDGET_OBSERVATIONS}`,
    //   icon: 'remove_red_eye',
    //   title: 'Budget Überwachungen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_OBSERVATIONS}`,
    //   icon: 'remove_red_eye',
    //   title: 'Überwachungen'
    // },
    // {
    //   route: `/${PATHS.MSB}/${ROUTE.MANAGE_FAVOURITE_ROUTES}`,
    //   icon: 'star',
    //   title: 'Favouriten'
    // },
    {
      route: `/${PATHS.MSB}/${ROUTE.MANAGE_PRICE_PREDICTIONS}`,
      icon: 'auto_graph',
      title: 'Prognosen'
    }
  ];

  readonly activeRoute$ = this._router.events.pipe(
    filter((routerEvent) => routerEvent instanceof NavigationEnd),
    debounceTime(0),
    map((routerEvent: NavigationEnd) => {
      return (
        this.navItems.find((x) =>
          x?.route?.startsWith(routerEvent?.url?.split(';')?.[0])
        ) ?? ''
      );
    }),
    shareReplay()
  );

  constructor(
    private readonly _breakpointObserver: BreakpointObserver,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {}
}
