<!--<h1>MyJACK Analytics</h1>-->
<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header> Filter Optionen </mat-expansion-panel-header>
  <app-deeplink-filter
    header-filter
    [filterStateService]="filterStateService"
    [withFunctionButtons]="true"
    [withBadges]="true"
  >
  </app-deeplink-filter>
</mat-expansion-panel>
<div>
  <!--  -->
  <ng-container
    *ngIf="{kpiIndependentData : kpiIndependentData$ | async, kpiDependentData: kpiDependentData$ | async,  kpiDataLoading: kpiDataLoading$ | async} as kpiData"
  >
    <form-loading-container
      [height]="'506px'"
      [loadingState]="kpiData?.kpiDataLoading"
    >
      <div
        class="row"
        style="
          margin: 20px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.bookingsToday, text: 'Buchungen Heute', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.totalBookings, text: 'Gesamt Buchungsanzahl', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.totalBookingVolume, text: 'Gesamt Buchungsvolume', valueType: 'million'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.totalCustomers, text: 'Kundenanzahl', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.dailyBookingAvg, text: 'Täglicher Buchungsdurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.weeklyBookingAvg, text: 'Wochentlicher Buchungsdurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.monthlyBookingAvg, text: 'Monatlicher Buchungsdurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.yearlyBookingAvg, text: 'Jahrlicher Buchungsdurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.dailyNewCustomersAvg, text: 'Täglicher Neukundendurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.weeklyNewCustomersAvg, text: 'Wochentlicher Neukundendurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.monthlyNewCustomersAvg, text: 'Monatlicher Neukundendurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiIndependentData?.yearlyNewCustomersAvg, text: 'Jahrlicher Neukundendurchschnitt', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiDependentData?.totalBookingsDuringPeriod, text: 'Anzahl der Buchungen im Zeitraum', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiDependentData?.bookingVolumeDuringPeriod, text: 'Buchungsvolumen im Zeitraum', valueType: 'million'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiDependentData?.bookingAvgDuringPeriod, text: 'Durchschnittliche Buchung während des Zeitraums (Tag)', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiDependentData?.newCustomersDuringPeriod, text: 'Neukunden im Zeitraum', valueType: 'number'}"
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="kpiTemplate; context: {value: kpiData?.kpiDependentData?.newCustomersAvgDuringPeriod, text: 'Durchschnittliche Neukunden im Zeitraum (Tag)', valueType: 'number'}"
        ></ng-container>
      </div>
    </form-loading-container>
  </ng-container>
  <ng-container *ngIf="filterValid$ | async; else FilterInvalidTemplateRef">
    <ng-container
      *ngIf=" {
       bookingVolumeChartOptions : bookingVolumeChartOptions$ | async,
       bookedCountChartOptions : bookedCountChartOptions$ | async,
       bookingVolumePieChartOptions: bookingVolumePieChartOptions$ | async,
       bookingCountPieChartOptions: bookingStatsPieChartOptions$ | async,
       customersCountChartOptions: customersCountChartOptions$ | async
      } as chartOptions"
    >
      <ng-container
        *ngIf="isHighcharts && chartOptions?.bookingVolumeChartOptions"
      >
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: chartOptions?.bookingVolumeChartOptions, loading: bookingVolumeChartLoading$ | async}"
        >
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="isHighcharts && chartOptions?.bookingVolumePieChartOptions"
      >
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: chartOptions?.bookingVolumePieChartOptions, loading: bookingVolumePieChartLoading$ | async}"
        >
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="isHighcharts && chartOptions?.bookedCountChartOptions"
      >
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: chartOptions?.bookedCountChartOptions, loading: bookedCountChartLoading$ | async}"
        >
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="isHighcharts && chartOptions?.bookingCountPieChartOptions"
      >
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: chartOptions?.bookingCountPieChartOptions, loading: bookingsStatsPieChartLoading$ | async}"
        >
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="isHighcharts && chartOptions?.customersCountChartOptions"
      >
        <ng-container
          *ngTemplateOutlet="chartTemplate; context: {options: chartOptions?.customersCountChartOptions, loading: customersCountChartLoading$ | async}"
        >
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #FilterInvalidTemplateRef> Filter ungültig </ng-template>
</div>

<!--KPI DATA TEMPLATE-->
<ng-template
  #kpiTemplate
  let-entityValue="value"
  let-text="text"
  let-valueType="valueType"
>
  <div class="col-6 col-sm-4 col-md-3 col-xl-2" style="margin: 0 0 10px">
    <mat-card
      [class.mat-elevation-z8]="true"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <mat-card-header
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        "
        ><b>{{text}}</b></mat-card-header
      >
      <mat-card-content
        style="text-align: center; padding: 1rem 0; font-size: 2rem"
      >
        <ng-container [ngSwitch]="valueType">
          <p class="label size-xl bold w-auto" *ngSwitchCase="'number'">
            {{ isNumber(entityValue) ? (entityValue | number) : '-' }}
          </p>
          <p *ngSwitchCase="'million'" class="label size-xl bold w-auto">
            {{ isNumber(entityValue) ? (entityValue | millionsNumberFormat) :
            '-' }}M €
          </p>
          <!-- number pipe instead of percent pipe to prevent % sign -->
          <p *ngSwitchCase="'percent'" class="label size-xl bold w-auto">
            {{ isNumber(entityValue) ? (entityValue * 100 | number: '1.2-2') :
            '-' }}
          </p>
          <p *ngSwitchCase="'money'" class="label size-xl bold w-auto">
            {{ isNumber(entityValue) ? (entityValue | number: '1.2-2') : '-' }}
          </p>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<!--CHART TEMPLATE-->
<ng-template
  #chartTemplate
  let-options="options"
  let-loadingState="loadingState"
>
  <div class="row">
    <div class="col-12 col-xl">
      <form-loading-container [height]="'506px'" [loadingState]="loadingState">
        <div class="row">
          <div class="col-md-12">
            <mat-card>
              <!--                    <mat-card-header>CHART_TITLE</mat-card-header>-->
              <mat-card-content>
                <highcharts-chart
                  [Highcharts]="Highcharts"
                  [options]="options"
                  style="width: 100%; display: block"
                >
                </highcharts-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form-loading-container>
    </div>
  </div>
</ng-template>
