/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelPrice } from '../models/database-model-price';
import { RequestModelSearchFilterParams } from '../models/request-model-search-filter-params';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class OfferService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllOffers
   */
  static readonly GetAllOffersPath = '/api/Offer/getalloffers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOffers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Array<DatabaseModelPrice>>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferService.GetAllOffersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Array<DatabaseModelPrice>>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOffers(params?: {
    context?: HttpContext
  }
): Observable<Array<Array<DatabaseModelPrice>>> {

    return this.getAllOffers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Array<DatabaseModelPrice>>>) => r.body as Array<Array<DatabaseModelPrice>>)
    );
  }

  /**
   * Path part for operation getOfferTypesFiltered
   */
  static readonly GetOfferTypesFilteredPath = '/api/Offer/offertypes-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfferTypesFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOfferTypesFiltered$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferService.GetOfferTypesFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOfferTypesFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOfferTypesFiltered(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getOfferTypesFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

}
