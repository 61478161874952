import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';
import { HighchartsOptions } from '../../../shared/utils/highcharts-utils';

import { FilterStateTaggedTimetablesService } from './filter-state-tagged-timetables.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import * as moment from 'moment';

@Component({
  selector: 'app-manage-tagged-timetables',
  templateUrl: './tagged-timetables.page.html',
  styleUrls: ['./tagged-timetables.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaggedTimetablesComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  constructor(
    readonly filterStateService: FilterStateTaggedTimetablesService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) =>
      this.adminService.streamTaggedTimetables({ body: request }).pipe(
        map((response) => {
          response?.data?.forEach((row) => {
            if (row?.validFrom?.length) {
              row.validFrom = moment.utc(row.validFrom).local().format();
            }
            if (row?.validTill?.length) {
              row.validTill = moment.utc(row.validTill).local().format();
            }
          });
          return response;
        })
      );
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }
}
