/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestModelSearchFilterParams } from '../models/request-model-search-filter-params';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class PriceModelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPriceModelsFiltered
   */
  static readonly GetPriceModelsFilteredPath = '/api/PriceModel/price-models-filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPriceModelsFiltered()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPriceModelsFiltered$Response(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceModelService.GetPriceModelsFilteredPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPriceModelsFiltered$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPriceModelsFiltered(params?: {
    context?: HttpContext
    body?: RequestModelSearchFilterParams
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getPriceModelsFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

}
