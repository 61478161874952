import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStateShopifyCouponsService } from './filter-state-shopify-coupons.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { Folder } from '../../../apis/msb/models/folder';
import { ShopifyCouponResult } from '../../../apis/msb/models/shopify-coupon-result';
import { ShopifyCouponRequest } from '../../../apis/msb/models/shopify-coupon-request';
import * as moment from 'moment';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-manage-shopify-coupons',
  templateUrl: './shopify-coupons.page.html',
  styleUrls: ['./shopify-coupons.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopifyCouponsComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  readonly myJackSearch$ = new BehaviorSubject<string>(null);
  // first_name
  readonly myJackSearchDisplayedColumns: Array<
    | keyof Folder
    | 'customerEmail'
    | 'customerFirstName'
    | 'customerLastName'
    | 'tourOperator'
    | 'webCode'
  > = [
    'folder_number',
    'start_date',
    'description',
    'customerEmail',
    'customerFirstName',
    'customerLastName',
    'tourOperator',
    'webCode'
  ];

  readonly couponResult$ = new BehaviorSubject<ShopifyCouponResult[]>([]);
  couponRequest: ShopifyCouponRequest = { email: '' };

  readonly myJackSearchResults$: Observable<Folder[]> = this.myJackSearch$.pipe(
    switchMap((date) => {
      if (date?.length === 10) {
        return this.adminService.getDeparturesForDate({
          date
        });
      } else {
        return of([]);
      }
    }),
    shareReplay()
  );

  constructor(
    readonly filterStateService: FilterStateShopifyCouponsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) =>
      this.adminService.streamShopifyCoupons({
        body: request
      });
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }

  getTourOperator(folder: Folder): string {
    let tourOperator = '';
    if (folder?.reservations?.length) {
      tourOperator =
        folder.reservations?.find((x) => x?.tour_operator).tour_operator ?? '';
    }
    return tourOperator;
  }

  async addCoupon(): Promise<void> {
    this.couponResult$.next([]);

    if (!this.couponRequest?.email) {
      alert('Bitte E-Mail angeben');
      return;
    }

    if (
      !this.couponRequest?.date ||
      this.couponRequest?.date?.length !== 10 ||
      !moment(this.couponRequest?.date, 'YYYY-MM-DD').isValid()
    ) {
      alert('Bitte ein gültiges Datum angeben');
      return;
    }

    try {
      const response = await this.adminService
        .createShopifyCoupon({
          body: {
            ...this.couponRequest
          }
        })
        .toPromise();
      this.couponResult$.next(response);
    } catch (e) {
      alert(`Fehler beim Anlegen des Gutscheincodes ${e}`);
    }
  }
}
