import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { GlobalLoadingService } from './global-loading.service';
import { flattenExport } from '../utils/json-utils.class';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor(
        private _globalLoadingService: GlobalLoadingService,
        private _datePipe: DatePipe
    ) { }

    public BuildPagedParams(sortColumn: string) {
        return {
            request: {
                customFilter: [],
                draw: 1,
                length: 100000000,
                searchValue: '',
                sortColumn: sortColumn,
                sortDirection: 'desc',
                start: 0
            }
        }
    }

    public Export(
        pagedObservable: Observable<any>,
        exportName: string,
        flatten?: boolean
    ): void {
        this._globalLoadingService.showGlobalLoader();
        pagedObservable.subscribe(cryptNotes => {
            let columns: any = null;
            let rows: any = null;
            if (flatten) {
                // flaten export very detailed
                const flattenExportResult = flattenExport(cryptNotes);
                columns = flattenExportResult.columns;
                rows = flattenExportResult.rows;
            } else {
                // regular generic export
                const objectKeys = Object.keys(cryptNotes.data[0]);
                columns = objectKeys.map(x => ({
                    name: x,
                    filterButton: true
                }));
                rows = cryptNotes.data.map(c => objectKeys.map(k => c[k]));
            }
            const workbook = new Workbook();
            workbook.creator = 'Admin';
            workbook.lastModifiedBy = 'Admin';
            workbook.created = new Date();
            workbook.modified = new Date();
            workbook.lastPrinted = new Date();
            const worksheet = workbook.addWorksheet(exportName);
            worksheet.addTable({
                name: 'ExportData',
                ref: 'B2',
                headerRow: true,
                totalsRow: true,
                style: {
                    theme: 'TableStyleLight9',
                    showRowStripes: true,
                },
                columns,
                rows
            });
            workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer]), `Export_${this._datePipe.transform(new Date(), 'yyyy-MM-dd')}_${exportName}.xlsx`);
                setTimeout(() => this._globalLoadingService.release());
            });
        });
    }

}
