<ng-container
  *ngIf="{
    timetables: cruiseMetadataService.timetables$ |async,
    selectedTimetables: selectedTimetables$ | async,
    loading: loading$ | async
} as data"
>
  <form-loading-container
    class="tagged-timetables-form"
    [loadingState]="data.loading"
    [height]="'90vh'"
  >
    <div class="row" style="margin: 0 10px">
      <div class="col-sm-9">
        <form>
          <div class="form-group row">
            <label class="col-3 col-form-label">Abreisetermine</label>
            <div class="col-12">
              <ng-select
                placeholder="Einträge auswählen"
                [items]="data?.timetables"
                [ngModel]="data.selectedTimetables"
                (ngModelChange)="selectedTimetables$.next($event)"
                [ngModelOptions]="{standalone: true}"
                bindLabel="title"
                [searchable]="true"
                [clearable]="true"
                [appendTo]="'body'"
                [multiple]="true"
                [groupBy]="groupByFn"
                [searchFn]="searchFn"
                [virtualScroll]="true"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span
                    [title]="item?.route?.ship.shipname + ' (' + (item?.startDate | date :
                  'dd.MM.yy') + ') ' + '('+ item?.duration + ' Nächte)' + item?.route?.name"
                  >
                    {{item?.route?.name | slice : 0 : 6}}...
                  </span>
                  <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                  >
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <span
                    >{{item?.route?.ship.shipname}} ( {{item?.startDate | date :
                    'dd.MM.yy'}}) ({{item?.duration}} Nächte) -
                    {{item?.route?.name}}</span
                  >
                </ng-template>
              </ng-select>
            </div>
          </div>
          <ul *ngIf="data?.selectedTimetables?.length">
            <li *ngFor="let timetable of data.selectedTimetables">
              {{timetable?.route?.ship.shipname}} am
              <strong>{{timetable?.startDate | date : 'dd.MM.yy'}}</strong>
              ({{timetable?.duration}} Nächte) - {{timetable?.route?.name}}
            </li>
          </ul>
          <div class="col-sm-6 promo-form">
            <formio
              [form]="formConfig$ | async"
              promoFormRef
              id="promo-form"
              (submit)="saveChanges($event?.data)"
            ></formio>
            <button *ngIf="isEdit" (click)="delete()" class="btn btn-danger">
              Löschen
            </button>
          </div>
        </form>
      </div>
    </div>
  </form-loading-container>
</ng-container>
