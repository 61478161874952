import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { GlobalLoadingService } from '../../services/global-loading.service';
import { RequestModelPaged } from '../../../apis/core/models/request-model-paged';
import { KeyValuePairStringString } from 'src/app/apis/core/models';

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTableComponent implements OnInit, OnDestroy {
  @ViewChild('dynamicTableRef', { static: false })
  dynamicTableRef: DatatableComponent;
  @ViewChild('searchInputRef', { static: false })
  searchInputRef: ElementRef<any>;
  @Input() hideFilters = false;
  @Input() config: UltraDynamicTableConfig;
  @Input() hasButtonContainer: boolean;
  @Input() disableSearch: boolean;
  @Input() enableRowDetails: boolean;
  @Input() rowDetailTemplateRef: TemplateRef<any>;

  @Output() selected = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() rowMouseEnter = new EventEmitter();
  @Output() tableDraw = new EventEmitter();

  rowsPerPage: Array<number> = [5, 10, 20, 100, 1000];
  errorStatus = 0;
  error = false;
  loading = true;
  observers: UltraDynamicTableObservers;
  page: UltraDynamicTablePage;
  rows: object[];
  searchSubject = new Subject<string>();
  subscriptions = new Array<Subscription>();
  customFilter?: Array<KeyValuePairStringString> = [];

  constructor(private globalLoadingService: GlobalLoadingService) {}

  /**
   * Returns order from config, if none specified => returns 'asc' as default order direction
   */
  private static getDefaultOrderFromConfig(
    config: UltraDynamicTableConfig
  ): string {
    let orderDir = 'asc';
    if (
      config.defaultOrderDirection === 'asc' ||
      config.defaultOrderDirection === 'desc'
    ) {
      orderDir = config.defaultOrderDirection;
    }
    return orderDir;
  }

  ngOnInit() {
    this.config.pageLength = this.config.pageLength
      ? this.config.pageLength
      : 10;
    this.initPageObject();
    this.pageCallback({ offset: 0 });
    this.initSearch();
    if (!this.observers) {
      this.observers = new UltraDynamicTableObservers(
        !!(this.selected && this.selected.observers.length),
        !!(this.tableDraw && this.tableDraw.observers.length)
      );
    }
  }

  pageLengthSelectionChange($event) {
    if ($event && $event.value) {
      this.page.limit = $event.value;
      this.reloadTable();
    }
  }

  focusSearchInput(): void {
    if (this.searchInputRef && this.searchInputRef.nativeElement) {
      if (this.searchInputRef.nativeElement.focus) {
        setTimeout(() => {
          this.searchInputRef.nativeElement.focus();
        });
      }
    }
  }

  initPageObject(): void {
    this.page = {
      draw: 1,
      limit: this.config.pageLength,
      count: 0,
      offset: 0,
      orderBy: this.config.defaultOrder,
      orderDir: DynamicTableComponent.getDefaultOrderFromConfig(this.config),
      search: ''
    };
  }

  onRowActivate($event) {
    if ($event.type === 'click') {
      if (
        this.enableRowDetails &&
        this.dynamicTableRef &&
        this.dynamicTableRef.rowDetail &&
        this.dynamicTableRef.rowDetail.toggleExpandRow
      ) {
        this.dynamicTableRef.rowDetail.toggleExpandRow($event.row);
      }
      this.rowClick.emit($event.row);
    } else if ($event.type === 'mouseenter') {
      this.rowMouseEnter.emit($event.row);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  initSearch() {
    this.subscriptions.push(
      this.searchSubject
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.resetPagination();
          this.reloadTable();
        })
    );
  }

  /**
   * Called whenever the user changes page
   */
  pageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  /**
   * Called whenever the user changes the sort order
   */
  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  /**
   * Resets pagination ( required e.g. when setting filters )
   */
  public resetPagination(): void {
    this.page.draw = 1;
    this.page.count = 0;
    this.page.offset = 0;
  }

  /**
   * Renders the table once at the beginning in ngOnInit()
   * and then every time the page OR the sort order are changed
   */
  public reloadTable(): void {
    if (this.rows) {
      this.globalLoadingService.showGlobalLoader();
    }
    this.errorStatus = 0;
    this.error = false;
    this.loading = true;
    const getRequestObservable = this.loadData();
    const fetchDataObservable = this.config.chainObservable
      ? this.config.chainObservable(getRequestObservable)
      : getRequestObservable;
    this.subscriptions.push(
      fetchDataObservable.subscribe(
        (response) => {
          this.page.count = response.recordsFiltered;
          this.page.draw = response.draw + 1;
          this.rows = response.data;
          this.loading = false;
          this.tableDraw.emit();
        },
        (error) => {
          if (error && error.status) {
            this.errorStatus = error.status;
          }
          this.error = true;
          this.loading = false;
          this.tableDraw.emit();
        },
        () => {
          this.globalLoadingService.release();
        }
      )
    );
  }

  /**
   * Observable for server-side data fetching
   */
  loadData(): Observable<any> {
    return this.config.fetchData({
      start: this.page.offset * this.page.limit,
      sortDirection: this.page.orderDir,
      sortColumn: this.page.orderBy,
      searchValue: this.page.search ? this.page.search.toLowerCase() : '',
      length: this.page.limit,
      draw: this.page.draw,
      customFilter: this.customFilter
    });
  }
}

class UltraDynamicTableObservers {
  constructor(public multiSelection: boolean, public draw: boolean) {}
}

export interface UltraDynamicTableConfig {
  fetchData: (request: RequestModelPaged) => Observable<any>;
  defaultOrder: string;
  columns: Array<TableColumn>;
  pageLength?: number;
  defaultOrderDirection?: string;
  chainObservable?: Function;
  ownRendering?: boolean;
  searchPlaceholder?: string;
}

export interface UltraDynamicTablePage {
  draw: number;
  limit: number;
  count: number;
  offset: number;
  orderBy: string;
  orderDir: string;
  search: string;
}
