/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FormTemplateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBookingFormTemplate
   */
  static readonly GetBookingFormTemplatePath = '/api/FormTemplate/booking-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetBookingFormTemplatePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getBookingFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getForgotPasswordFormTemplate
   */
  static readonly GetForgotPasswordFormTemplatePath = '/api/FormTemplate/forgot-password-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForgotPasswordFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForgotPasswordFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetForgotPasswordFormTemplatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getForgotPasswordFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForgotPasswordFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getForgotPasswordFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getConfirmCodeFormTemplate
   */
  static readonly GetConfirmCodeFormTemplatePath = '/api/FormTemplate/confirm-code-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfirmCodeFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfirmCodeFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetConfirmCodeFormTemplatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConfirmCodeFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfirmCodeFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getConfirmCodeFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getForgetPasswordResetFormTemplate
   */
  static readonly GetForgetPasswordResetFormTemplatePath = '/api/FormTemplate/forgetpw-reset-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForgetPasswordResetFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForgetPasswordResetFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetForgetPasswordResetFormTemplatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getForgetPasswordResetFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForgetPasswordResetFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getForgetPasswordResetFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getChangePasswordFormTemplate
   */
  static readonly GetChangePasswordFormTemplatePath = '/api/FormTemplate/change-password-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangePasswordFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangePasswordFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetChangePasswordFormTemplatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChangePasswordFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangePasswordFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getChangePasswordFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getEditCustomerInfoFormTemplate
   */
  static readonly GetEditCustomerInfoFormTemplatePath = '/api/FormTemplate/edit-customer-info-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditCustomerInfoFormTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditCustomerInfoFormTemplate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormTemplateService.GetEditCustomerInfoFormTemplatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEditCustomerInfoFormTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditCustomerInfoFormTemplate(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getEditCustomerInfoFormTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
