/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestModelBookingFormData } from '../models/request-model-booking-form-data';
import { ResponseModelBookedTrip } from '../models/response-model-booked-trip';
import { ResponseModelBookingPreflightData } from '../models/response-model-booking-preflight-data';

@Injectable({
  providedIn: 'root',
})
export class UserBookedTripsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation preflightBooking
   */
  static readonly PreflightBookingPath = '/api/UserBookedTrips/preflight-booking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preflightBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  preflightBooking$Response(params: {
    'route-id': number;
    'cruise-id': string;
    adults: number;
    children?: number;
    'child-birthdays'?: Array<string>;
    'cabin-baid': string;
    'cabin-number': string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResponseModelBookingPreflightData>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookedTripsService.PreflightBookingPath, 'get');
    if (params) {
      rb.query('route-id', params['route-id'], {});
      rb.query('cruise-id', params['cruise-id'], {});
      rb.query('adults', params.adults, {});
      rb.query('children', params.children, {});
      rb.query('child-birthdays', params['child-birthdays'], {});
      rb.query('cabin-baid', params['cabin-baid'], {});
      rb.query('cabin-number', params['cabin-number'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseModelBookingPreflightData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preflightBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preflightBooking(params: {
    'route-id': number;
    'cruise-id': string;
    adults: number;
    children?: number;
    'child-birthdays'?: Array<string>;
    'cabin-baid': string;
    'cabin-number': string;
    context?: HttpContext
  }
): Observable<ResponseModelBookingPreflightData> {

    return this.preflightBooking$Response(params).pipe(
      map((r: StrictHttpResponse<ResponseModelBookingPreflightData>) => r.body as ResponseModelBookingPreflightData)
    );
  }

  /**
   * Path part for operation submitBooking
   */
  static readonly SubmitBookingPath = '/api/UserBookedTrips/submit-booking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitBooking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitBooking$Response(params?: {
    context?: HttpContext
    body?: RequestModelBookingFormData
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookedTripsService.SubmitBookingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitBooking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitBooking(params?: {
    context?: HttpContext
    body?: RequestModelBookingFormData
  }
): Observable<boolean> {

    return this.submitBooking$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getAllBookedTrips
   */
  static readonly GetAllBookedTripsPath = '/api/UserBookedTrips/bookedTrips';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBookedTrips()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookedTrips$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseModelBookedTrip>>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookedTripsService.GetAllBookedTripsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelBookedTrip>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBookedTrips$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBookedTrips(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseModelBookedTrip>> {

    return this.getAllBookedTrips$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelBookedTrip>>) => r.body as Array<ResponseModelBookedTrip>)
    );
  }

}
