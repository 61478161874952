<div class="login-form-container">
  <mat-card class="login-card" [ngClass]="{ 'shake' : shake$ | async }">
    <mat-card-title>Login</mat-card-title>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail Adresse</mat-label>
          <input matInput id="email" formControlName="email" type="email" [email]="true" placeholder="user@example.com" />
          <mat-icon matSuffix>account_box</mat-icon>
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input matInput id="password" formControlName="password" type="password" />
          <mat-icon matSuffix>lock</mat-icon>
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button type="submit" mat-raised-button color="primary">Login</button>
      </mat-card-actions>
    </form>
    <mat-card-footer></mat-card-footer>
  </mat-card>
</div>
