/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelUserBookingWish } from '../models/database-model-user-booking-wish';
import { DatabaseModelUserBookingWishExtended } from '../models/database-model-user-booking-wish-extended';
import { RequestModelBookingFormData } from '../models/request-model-booking-form-data';

@Injectable({
  providedIn: 'root',
})
export class UserBookingWishService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBookingWishes
   */
  static readonly GetBookingWishesPath = '/api/UserBookingWish/booking-wishes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingWishes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingWishes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelUserBookingWish>>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookingWishService.GetBookingWishesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelUserBookingWish>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingWishes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingWishes(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelUserBookingWish>> {

    return this.getBookingWishes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelUserBookingWish>>) => r.body as Array<DatabaseModelUserBookingWish>)
    );
  }

  /**
   * Path part for operation getBookingWishesWithCabinData
   */
  static readonly GetBookingWishesWithCabinDataPath = '/api/UserBookingWish/booking-wishes-with-cabin-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingWishesWithCabinData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingWishesWithCabinData$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelUserBookingWishExtended>>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookingWishService.GetBookingWishesWithCabinDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelUserBookingWishExtended>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingWishesWithCabinData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingWishesWithCabinData(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelUserBookingWishExtended>> {

    return this.getBookingWishesWithCabinData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelUserBookingWishExtended>>) => r.body as Array<DatabaseModelUserBookingWishExtended>)
    );
  }

  /**
   * Path part for operation submitBookingWish
   */
  static readonly SubmitBookingWishPath = '/api/UserBookingWish/submit-booking-wish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitBookingWish()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitBookingWish$Response(params?: {
    context?: HttpContext
    body?: RequestModelBookingFormData
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookingWishService.SubmitBookingWishPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitBookingWish$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitBookingWish(params?: {
    context?: HttpContext
    body?: RequestModelBookingFormData
  }
): Observable<boolean> {

    return this.submitBookingWish$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteBookingWish
   */
  static readonly DeleteBookingWishPath = '/api/UserBookingWish/delete-booking-wish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBookingWish()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBookingWish$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserBookingWishService.DeleteBookingWishPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBookingWish$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBookingWish(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.deleteBookingWish$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
