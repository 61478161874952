import * as Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';

NoData(Highcharts);

export function deepMergeObjects<T>(...objects: T[]): T {
  let target = {};

  const merger = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // if the property is a nested object
          target[prop] = deepMergeObjects(target[prop], obj[prop]);
        } else {
          // for regular property
          target[prop] = obj[prop];
        }
      }
    }
  };

  for (let i = 0; i < objects.length; i++) {
    merger(objects[i]);
  }

  return target as T;
}

export const DEFAULT_Y_AXIS: Highcharts.YAxisOptions = {
  title: {
    text: '',
    // style: {
    //   ...DEFAULT_TEXT_STYLE
    // },
    align: 'low'
  },
  lineWidth: 2,
  labels: {
    // style: {
    //   ...DEFAULT_TEXT_STYLE
    // }
  },
  stackLabels: {
    // style: {
    //   ...DEFAULT_TEXT_STYLE,
    //   fontWeight: CssVar.FONT_WEIGHT_STRONG
    // },
    align: 'center',
    overflow: 'allow',
    crop: false
  },
  gridLineColor: 'white'
};

enum EXPORT_KEYS {
  CUSTOM_EXPORT_SVG = 'CUSTOM_EXPORT_SVG',
  CUSTOM_EXPORT_PNG = 'CUSTOM_EXPORT_PNG'
}

export const DEFAULT_HIGHCHARTS_OPTIONS: Highcharts.Options = {
  chart: {
    backgroundColor: 'transparent',
    height: 450,
    spacing: [16, 16, 16, 16]
    // style: {
    //   ...DEFAULT_TEXT_STYLE
    // }
  },
  loading: {
    style: {
      backgroundColor: 'white',
      opacity: 0.5
    }
  },
  noData: {
    // style: {
    //   ...DEFAULT_TEXT_STYLE
    // }
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    title: {
      // style: {
      //   ...DEFAULT_TEXT_STYLE
      // }
    },
    lineWidth: 2,
    labels: {
      // style: {
      //   ...DEFAULT_TEXT_STYLE
      // }
    },
    gridLineColor: 'white'
  },
  yAxis: DEFAULT_Y_AXIS,
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  // exporting: {
  //   menuItemDefinitions: {
  //     [EXPORT_KEYS.CUSTOM_EXPORT_PNG]: {
  //       text: 'PNG Export',
  //       onclick() {
  //         ExportElementAs(this.container, 'PNG').then(() => {});
  //       }
  //     },
  //     [EXPORT_KEYS.CUSTOM_EXPORT_SVG]: {
  //       text: 'SVG Export',
  //       onclick() {
  //         ExportElementAs(this.container, 'SVG').then(() => {});
  //       }
  //     }
  //   },
  //   buttons: {
  //     contextButton: {
  //       menuItems: [EXPORT_KEYS.CUSTOM_EXPORT_PNG, EXPORT_KEYS.CUSTOM_EXPORT_SVG]
  //     }
  //   }
  // },
  tooltip: {
    useHTML: true,
    headerFormat: '',
    // style: {
    //   color: CssVar.COLOR_TEXT_TOOLTIP,
    //   fontFamily: CssVar.FONT_FAMILY_TOOLTIP,
    //   fontSize: CssVar.FONT_SIZE_TOOLTIP,
    //   fontWeight: CssVar.FONT_WEIGHT_TOOLTIP,
    //   textOutline: 'none'
    // },
    shadow: false,
    padding: 0,
    borderRadius: 0,
    borderWidth: 0
  },
  plotOptions: {
    series: {
      label: {
        // style: {
        //   ...DEFAULT_TEXT_STYLE
        // }
      },
      dataLabels: {
        overflow: 'allow',
        crop: false
        // style: {
        //   textOutline: null,
        //   ...DEFAULT_TEXT_STYLE
        // }
      }
    },
    line: {
      // marker: {
      //   fillColor: CssVar.COLOR_SURFACE_INVERT,
      //   radius: 4,
      //   symbol: 'circle'
      // },
      // color: CssVar.COLOR_SURFACE_INVERT,
      lineWidth: 2,
      events: {
        legendItemClick: () => false
      },
      allowPointSelect: false
    }
    // column: {
    //   color: CssVar.COLOR_SURFACE_INFO_MILD
    // }
  }
};

export function createLineDataLabelOptions(
  text: string,
  additionalClass: string = ''
): Highcharts.DataLabelsOptions {
  return {
    enabled: true,
    crop: false,
    useHTML: true,
    formatter: () =>
      `<div class="line-data-label ${additionalClass}">${text}</div>`
  };
}

export function HighchartsOptions(
  options: Highcharts.Options
): Highcharts.Options {
  const mergedOptions = deepMergeObjects(DEFAULT_HIGHCHARTS_OPTIONS, options);
  mergedOptions.lang = {
    // noData: translateService.instant('DCT_NXT.CHART.NO_DATA'),
    noData: 'Keine Daten gefunden'
  };
  return mergedOptions;
}

interface TooltipTableRow {
  colorClass: string;
  name: string;
  value: number;
  valuePercent: number;
  bold?: boolean;
}

// export function tooltipTableHtml(rows: TooltipTableRow[], header = null) {
//   return `
//     <table>
//       ${
//         header
//           ? `<tr><th colspan="5" class="text-wrap pb-3x">${header}</th></tr>`
//           : ''
//       }
//       ${rows
//         .map((row) => {
//           return `
//           <tr>
//             <td><div style="width: 8px; height: 8px;" class="${
//               row.colorClass
//             }"></div></td>
//             <td class="ps-1x pe-2x ${row.bold ? 'fw-strong' : ''}">${
//             row.name
//           }</td>
//             <td class="text-end ${row.bold ? 'fw-strong' : ''}">${
//             formatNumber(row.value, '1.0-0') ?? 0
//           }</td>
//             <td class="px-1x ${row.bold ? 'fw-strong' : ''}">|</td>
//             <td class="text-end ${
//               row.bold ? 'fw-strong' : ''
//             }">${formatPercentage(row.valuePercent, '1.2-2', true)}</td>
//           </tr>
//         `;
//         })
//         .join('')}
//     </table>
//   `;
// }
