import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { FilterStateOverviewService } from './filter-state-overview.service';

@Injectable({
  providedIn: 'root'
})
export class FilterStateOverviewResolver implements Resolve<Observable<boolean>> {
  constructor(private readonly router: Router, private readonly _filterStateService: FilterStateOverviewService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._filterStateService.initRoutingState(route);
  }
}
