<div style="padding: 10px">
  <ng-container *ngIf="showFilters">
    <!--<h1>Report</h1>-->
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
      <app-deeplink-filter
        header-filter
        [filterStateService]="filterStateService"
        [withFunctionButtons]="true"
        [withBadges]="true"
      >
      </app-deeplink-filter>
    </mat-expansion-panel>
  </ng-container>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateStartRef let-row="row">
          {{row?.dateStart | date : 'dd.MM.yy'}} @ {{row?.dateStart | date :
          'shortTime'}}
        </ng-template>

        <ng-template #cellDateEndRef let-row="row">
          {{row?.dateEnd | date : 'dd.MM.yy'}} @ {{row?.dateEnd | date :
          'shortTime'}}
        </ng-template>

        <ng-template #cellDateAddedRef let-row="row">
          {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date :
          'shortTime'}}
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'routeId',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'route.name', name: 'Routenname', sortable: false },
        { prop: 'route.ship.shipname', name: 'Schiff', sortable: false },
        { prop: 'shippingCompanyID', name: 'Reederei Id' },
        { prop: 'route.shippingCompany.name', name: 'Reederei', sortable: false },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'shipId', name: 'Ship Id' },
        { prop: 'cabin', name: 'Cabin' },
        { prop: 'cabinTypeCode', name: 'Cabin Type Code' },
        { prop: 'dateStart', name: 'Date Start', cellTemplate: cellDateStartRef},
        { prop: 'dateEnd', name: 'Date End', cellTemplate: cellDateEndRef},
        { prop: 'dateAdded', name: 'Date Added', cellTemplate: cellDateAddedRef},
        { prop: 'duration', name: 'Duration' },
        { prop: 'fromDestination', name: 'From Dest.' },
        { prop: 'toDestination', name: 'To Dest.' },
        { prop: 'priceCruise', name: 'Price Cruise' },
        { prop: 'priceCruise34', name: 'Price Cruise34' },
        { prop: 'priceCruiseSingle', name: 'Price Cruise Single' },
        { prop: 'priceCruiseChild', name: 'Price Cruise Child' },
        { prop: 'priceCruiseChild34', name: 'Price Cruise Child34' },
        { prop: 'priceCruiseJunior', name: 'Price Cruise Junior' },
        { prop: 'priceCruiseJunior34', name: 'Price Cruise Junior34' },
        { prop: 'min', name: 'Min' },
        { prop: 'max', name: 'Max' },
        { prop: 'priceModel', name: 'Price Model' },
        { prop: 'promoCode', name: 'Promo Code' },
        { prop: 'promoDescription', name: 'Promo Desc.' },
        { prop: 'offerType', name: 'Offer Type' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
