import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'millionsNumberFormat',
  pure: true
})
export class MillionsNumberFormatPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: unknown, digitsInfo?: string, noNumberContent = ''): string {
    return formatMillionsNumber(
      value,
      this.translateService.currentLang,
      digitsInfo,
      noNumberContent
    );
  }
}

export function formatMillionsNumber(
  value: unknown,
  locale = 'en-EN',
  digitsInfo = '1.2-2',
  noNumberContent = ''
): string {
  const valueNum = Number(value);

  if ((!value && value !== 0) || isNaN(valueNum)) {
    return noNumberContent;
  }
  return formatNumber(valueNum / 1000000, locale, digitsInfo);
}
