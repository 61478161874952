/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseModelUserFavouriteRoute } from '../models/database-model-user-favourite-route';

@Injectable({
  providedIn: 'root',
})
export class UserFavouriteRoutesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFavouriteRoutes
   */
  static readonly GetFavouriteRoutesPath = '/api/UserFavouriteRoutes/favourites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouriteRoutes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteRoutes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelUserFavouriteRoute>>> {

    const rb = new RequestBuilder(this.rootUrl, UserFavouriteRoutesService.GetFavouriteRoutesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelUserFavouriteRoute>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFavouriteRoutes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouriteRoutes(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelUserFavouriteRoute>> {

    return this.getFavouriteRoutes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelUserFavouriteRoute>>) => r.body as Array<DatabaseModelUserFavouriteRoute>)
    );
  }

  /**
   * Path part for operation addToFavourites
   */
  static readonly AddToFavouritesPath = '/api/UserFavouriteRoutes/favourites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addToFavourites()` instead.
   *
   * This method doesn't expect any request body.
   */
  addToFavourites$Response(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserFavouriteRoutesService.AddToFavouritesPath, 'post');
    if (params) {
      rb.query('routeId', params.routeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addToFavourites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addToFavourites(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.addToFavourites$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation removeFromFavourites
   */
  static readonly RemoveFromFavouritesPath = '/api/UserFavouriteRoutes/favourites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeFromFavourites()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavourites$Response(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserFavouriteRoutesService.RemoveFromFavouritesPath, 'delete');
    if (params) {
      rb.query('routeId', params.routeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeFromFavourites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeFromFavourites(params?: {
    routeId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.removeFromFavourites$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation isFavourite
   */
  static readonly IsFavouritePath = '/api/UserFavouriteRoutes/is-favourite/{routeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isFavourite()` instead.
   *
   * This method doesn't expect any request body.
   */
  isFavourite$Response(params: {
    routeId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserFavouriteRoutesService.IsFavouritePath, 'post');
    if (params) {
      rb.path('routeId', params.routeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isFavourite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isFavourite(params: {
    routeId: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.isFavourite$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
