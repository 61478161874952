import { PricePredictionsComponent } from './pages/manage/price-predictions/price-predictions.page';
import { OverviewComponent } from './pages/analytics/overview/overview.page';
import { CustomerInteractionComponent } from './pages/analytics/customer-interaction/customer-interaction.page';
import { FavouriteRoutesComponent } from './pages/manage/favourite-routes/favourite-routes.page';
import { ObservationsComponent } from './pages/manage/observations/observations.page';
import { BudgetObservationsComponent } from './pages/manage/budget-observations/budget-observations.page';
import { CabinsComponent } from './pages/manage/cabins/cabins.page';
import { ShipsComponent } from './pages/manage/ships/ships.page';
import { ShippingCompaniesComponent } from './pages/manage/shipping-companies/shipping-companies.page';
import { TaggedTimetablesComponent } from './pages/manage/tagged-timetables/tagged-timetables.page';
import { SpecialPricesComponent } from './pages/manage/special-prices/special-prices.page';
import { LivePriceDropsComponent } from './pages/manage/live-price-drops/live-price-drops.page';
import { ShopifyCouponsComponent } from './pages/manage/shopify-coupons/shopify-coupons.page';
import { RoutesComponent } from './pages/manage/routes/routes.page';
import { TimetablesComponent } from './pages/manage/timetables/timetables.page';
import { HistoricalPricesComponent } from './pages/manage/historical-prices/historical-prices.page';
import { LivePricesComponent } from './pages/manage/live-prices/live-prices.page';
import { FlightPricesComponent } from './pages/manage/flight-prices/flight-prices.page';
import { NotificationsDeletedComponent } from './pages/manage/notifications-deleted/notifications-deleted.page';
import { NotificationsSentComponent } from './pages/manage/notifications-sent/notifications-sent.page';
import { FcmTokensComponent } from './pages/manage/fcm-tokens/fcm-tokens.page';
import { BookingsTableComponent } from './pages/manage/bookings-table/bookings-table.page';
import { AccountsComponent } from './pages/manage/accounts/accounts.page';
import { BookingsComponent } from './pages/analytics/bookings/bookings.page';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { environment } from '../environments/environment';
import { DynamicTableComponent } from './shared/components/dynamic-table/dynamic-table.component';
import { GlobalLoadingComponent } from './shared/components/global-loading/global-loading.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogTableComponent } from './dashboard/tables/log-table/log-table.component';
import { MailTemplatePipe } from './shared/pipes/mail-template.pipe';
import { LogEntryFormatterPipe } from './shared/pipes/log-entry-formatter.pipe';
import { AccountDetailsFormPipe } from './shared/pipes/account-details-form.pipe';

import { FcmTokenTableComponent } from './dashboard/tables/fcm-token-table/fcm-token-table.component';
import { FcmNotificationsTableComponent } from './dashboard/tables/fcm-notifications-table/fcm-notifications-table.component';
import { BookedTripsTableComponent } from './dashboard/tables/booked-trips-table/booked-trips-table.component';
import { RoutesTableComponent } from './dashboard/tables/routes-table/routes-table.component';
import { TimetablesTableComponent } from './dashboard/tables/timetables-table/timetables-table.component';
import { CabinsTableComponent } from './dashboard/tables/cabins-table/cabins-table.component';
import { LivePricesTableComponent } from './dashboard/tables/live-prices-table/live-prices-table.component';
import { HistoricalPricesTableComponent } from './dashboard/tables/historical-prices-table/historical-prices-table.component';
import { ShippingCompaniesTableComponent } from './dashboard/tables/shipping-companies-table/shipping-companies-table.component';
import { ShipsTableComponent } from './dashboard/tables/ships-table/ships-table.component';
import { ObservationsTableComponent } from './dashboard/tables/observations-table/observations-table.component';
import { FavouriteRoutesTableComponent } from './dashboard/tables/favourite-routes-table/favourite-routes-table.component';
import { SpecialPricesTableComponent } from './dashboard/tables/special-prices-table/special-prices-table.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HighchartsChartModule } from 'highcharts-angular';

import { AccountDetailComponent } from './dashboard/detailwindows/account-detail/account-detail.component';
import { AggregatedLogsComponent } from './shared/components/aggregated-logs/aggregated-logs.component';
import { GenericDropdownFilterComponent } from './shared/components/generic-dropdown-filter/generic-dropdown-filter.component';

import { VisualDashboardComponent } from './dashboard/visual-dashboard/visual-dashboard.component';
import { GenericLogChartComponent } from './shared/components/generic-log-chart/generic-log-chart.component';

import { TopUsersComponent } from './dashboard/containers/top-users/top-users.component';
import { GlobalKpisComponent } from './dashboard/containers/global-kpis/global-kpis.component';

import { DataLoadingContainerComponent } from './shared/components/loading-container/loading-container.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsbApiModule } from './apis/msb/msb-api.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/services/global-data.service';
import { FlightPricesTableComponent } from './dashboard/tables/flight-prices-table/flight-prices-table.component';
import { MailApiModule } from './apis/mail/mail-api.module';
import { FormioAppConfig, FormioModule } from '@formio/angular';
import { AgencySpecialComponent } from './dashboard/agency-special/agency-special.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmationModalComponent } from './shared/Modals/confirmation-modal/confirmation-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LivePriceDropsTableComponent } from './dashboard/tables/live-price-drops-table/live-price-drops-table.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeletedFcmNotificationsTableComponent } from './dashboard/tables/deleted-fcm-notifications-table/deleted-fcm-notifications-table.component';
import { BudgetObservationsTableComponent } from './dashboard/tables/budget-observations-table/budget-observations-table.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { TaggedTimetablesFormComponent } from './shared/components/tagged-timetables-form/tagged-timetables-form.component';
import { TaggedTimetablesTableComponent } from './dashboard/tables/tagged-timetables-table/tagged-timetables-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import {
  DateTransformerPipe,
  DeeplinkFilterComponent
} from './shared/components/deeplink-filter/deeplink-filter.component';
import { MillionsNumberFormatPipe } from './shared/pipes/millions-number-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const materialModules = [
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatSelectModule,
  MatButtonModule,
  MatCardModule,
  MatSlideToggleModule,
  MatDialogModule,
  ClipboardModule,
  MatTableModule
];

const tableComponents = [
  DashboardComponent,
  LogTableComponent,
  FcmTokenTableComponent,
  FcmNotificationsTableComponent,
  DeletedFcmNotificationsTableComponent,
  BookedTripsTableComponent,
  RoutesTableComponent,
  TimetablesTableComponent,
  CabinsTableComponent,
  LivePricesTableComponent,
  LivePriceDropsTableComponent,
  HistoricalPricesTableComponent,
  FlightPricesTableComponent,
  ShippingCompaniesTableComponent,
  ShipsTableComponent,
  BudgetObservationsTableComponent,
  ObservationsTableComponent,
  FavouriteRoutesTableComponent,
  SpecialPricesTableComponent,
  TaggedTimetablesTableComponent
];

const containerComponents = [
  TopUsersComponent,
  GlobalKpisComponent,
  DataLoadingContainerComponent
];

const detailComponents = [AccountDetailComponent];

const pipes = [
  MailTemplatePipe,
  LogEntryFormatterPipe,
  AccountDetailsFormPipe,
  MillionsNumberFormatPipe
];

const modals = [ConfirmationModalComponent];

@NgModule({
  declarations: [
    AppComponent,
    PricePredictionsComponent,
    OverviewComponent,
    CustomerInteractionComponent,
    FavouriteRoutesComponent,
    ObservationsComponent,
    BudgetObservationsComponent,
    CabinsComponent,
    ShipsComponent,
    ShippingCompaniesComponent,
    TaggedTimetablesComponent,
    SpecialPricesComponent,
    LivePriceDropsComponent,
    ShopifyCouponsComponent,
    RoutesComponent,
    TimetablesComponent,
    HistoricalPricesComponent,
    LivePricesComponent,
    FlightPricesComponent,
    NotificationsDeletedComponent,
    NotificationsSentComponent,
    FcmTokensComponent,
    BookingsTableComponent,
    AccountsComponent,
    BookingsComponent,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    DynamicTableComponent,
    GlobalLoadingComponent,
    AggregatedLogsComponent,
    GenericDropdownFilterComponent,
    VisualDashboardComponent,
    AgencySpecialComponent,
    TaggedTimetablesFormComponent,
    GenericLogChartComponent,
    DateTransformerPipe,
    DeeplinkFilterComponent,
    ...tableComponents,
    ...detailComponents,
    ...containerComponents,
    ...pipes,
    ...modals
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormioModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    NgxDatatableModule,
    HighchartsChartModule,
    MsbApiModule.forRoot({
      rootUrl: environment.services.msbService.baseUrl
    }),
    MailApiModule.forRoot({
      rootUrl: environment.services.mailService.baseUrl
    }),
    BrowserAnimationsModule,
    ...materialModules,
    NgbModule,
    MatDatepickerModule,
    TranslateModule.forRoot(),
    MatTooltipModule
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: FormioAppConfig, useValue: {} }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
