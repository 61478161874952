import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import {
  INavigationStateService,
  InitializeNavigationStateHandler,
  NavigationStateHandler,
  UltraDynamicFilterConfig,
  UltraDynamicFilterStateMap
} from '../../../shared/navigation/navigation-state-handler.class';
import * as moment from 'moment';

export interface BookingsTableFilterState {
  pageLength?: number;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStateBookingsTableService
  implements INavigationStateService<BookingsTableFilterState>
{
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<BookingsTableFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.MANAGE_BOOKINGS_TABLE];
  }

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.MANAGE_BOOKINGS_TABLE,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      // TODO configure filters

      //table specific filters
      {
        type: 'DROPDOWN',
        matrixParameter: 'pageLength',
        label: 'Einträge pro Seite',
        iconName: '',
        required: true,
        options$: of([
          { id: '5', label: '5 pro Seite' },
          { id: '10', label: '10 pro Seite' },
          { id: '50', label: '50 pro Seite' },
          { id: '100', label: '100 pro Seite' },
          { id: '1000', label: '1000 pro Seite' }
        ]),
        defaultValue: '10',
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'search',
        controlOptions: {
          debounceTime: 500
        },
        label: 'Suchbegriff',
        iconName: 'search',
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateFrom',
        label: 'Datum von (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().subtract(36, 'months').format('YYYY-MM-DD'),
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'dateTo',
        label: 'Datum bis (YYYY-MM-DD)',
        iconName: 'date_range',
        required: true,
        defaultValue: moment().format('YYYY-MM-DD'),
        emptyOptionValue: null
      }
    ]
  };

  constructor(protected readonly _router: Router) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(
      PATHS.MSB,
      this.config,
      _router
    );
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): BookingsTableFilterState {
    const parsedFilter: BookingsTableFilterState = {};

    // TODO parse the filter data

    if (fltr?.pageLength) {
      parsedFilter.pageLength = parseInt(fltr.pageLength, 10);
    }

    if (fltr?.search) {
      parsedFilter.search = fltr.search;
    }

    if (fltr?.dateFrom) {
      parsedFilter.dateFrom = fltr.dateFrom;
    }

    if (fltr?.dateTo) {
      parsedFilter.dateTo = fltr.dateTo;
    }

    return parsedFilter;
  }
}
