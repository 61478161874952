export enum ROUTE {
  ANALYTICS = 'analytics',
  ANALYTICS_BOOKINGS = 'analytics/bookings',
  MANAGE_ACCOUNTS = 'manage/accounts',
  MANAGE_BOOKINGS_TABLE = 'manage/bookings-table',
  MANAGE_FCM_TOKENS = 'manage/fcm-tokens',
  MANAGE_NOTIFICATIONS_SENT = 'manage/notifications-sent',
  MANAGE_NOTIFICATIONS_DELETED = 'manage/notifications-deleted',
  MANAGE_FLIGHT_PRICES = 'manage/flight-prices',
  MANAGE_LIVE_PRICES = 'manage/live-prices',
  MANAGE_HISTORICAL_PRICES = 'manage/historical-prices',
  MANAGE_TIMETABLES = 'manage/timetables',
  MANAGE_ROUTES = 'manage/routes',
  MANAGE_SHOPIFY_COUPONS = 'manage/shopify-coupons',
  MANAGE_LIVE_PRICE_DROPS = 'manage/live-price-drops',
  MANAGE_SPECIAL_PRICES = 'manage/special-prices',
  MANAGE_TAGGED_TIMETABLES = 'manage/tagged-timetables',
  MANAGE_SHIPPING_COMPANIES = 'manage/shipping-companies',
  MANAGE_SHIPS = 'manage/ships',
  MANAGE_CABINS = 'manage/cabins',
  MANAGE_BUDGET_OBSERVATIONS = 'manage/budget-observations',
  MANAGE_OBSERVATIONS = 'manage/observations',
  MANAGE_FAVOURITE_ROUTES = 'manage/favourite-routes',
  ANALYTICS_CUSTOMER_INTERACTION = 'analytics/customer-interaction',
  ANALYTICS_OVERVIEW = 'analytics/overview',
  MANAGE_PRICE_PREDICTIONS = 'manage/price-predictions'
}
