<dynamic-table [config]="{
  defaultOrder: 'logDate',
  defaultOrderDirection: 'desc',
  pageLength: 10,
  columns: [
    { prop: 'logDate', name: 'Date', cellTemplate: cellDateRef },
    { prop: 'logKey', name: 'Event',  cellTemplate: cellLogKeyRef },
    { prop: 'userAgent', name: 'UserAgent' },
    { prop: 'ip', name: 'Ip' }
  ],
  fetchData: fetchData()
}" [disableSearch]="true" [enableRowDetails]="true" (tableDraw)="_globalLoadingService.release()" [rowDetailTemplateRef]="rowDetailTemplateRef">

    <ng-template #cellDateRef let-row="row">
        {{row?.logDate | date : 'dd.MM.yy'}} @ {{row?.logDate | date : 'shortTime'}}
    </ng-template>

    <ng-template #cellLogKeyRef let-row="row">
        {{row?.logKey | logEntryFormatter}}
    </ng-template>

    <ng-template #rowDetailTemplateRef let-row="row">
        <pre class="log-table_details">{{row | json}}</pre>
    </ng-template>

    <generic-dropdown-filter *ngIf="_globalDataService?.LogTypes?.length" [isKvp]="true" [filterLabel]="'Event'" [filterKey]="'logkey'" [filterOptions]="_globalDataService.LogTypes" [tableRef]="logTableRef">
    </generic-dropdown-filter>

    <div style="display: inline-block; padding-left: 10px;">
        <button mat-button color="primary" (click)="export()" title="Export as Excel">
            <mat-icon>save_alt</mat-icon>
        </button>
    </div>

     <mat-form-field *ngIf="_globalDataService?.LogTypes?.length">
        <mat-label>Event</mat-label>
        <mat-select [(ngModel)]="logTypeValue" (selectionChange)="logEventSelectionChange($event)">
            <mat-option *ngFor="let logType of _globalDataService.LogTypes" [value]="logType">
                {{logType.value}}
            </mat-option>
        </mat-select>
        <button mat-button *ngIf="logTypeValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetLogEventFilter($event)">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

</dynamic-table>
