<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellDateStartRef let-row="row">
          {{row?.startDate | date : 'dd.MM.yy'}} @ {{row?.startDate | date :
          'shortTime'}}
        </ng-template>

        <ng-template #rowDetailTemplateRef let-row="row">
          <div style="max-width: 85vw">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon>flight</mat-icon>&nbsp; Flug Preise (Aktuell)
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <app-manage-flight-prices
                    [showFilters]="false"
                    [cruiseId]="row.cruiseId"
                  >
                  </app-manage-flight-prices>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon>euro_symbol</mat-icon>&nbsp; Preise (Aktuell)
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <app-manage-live-prices
                    [showFilters]="false"
                    [cruiseId]="row.cruiseId"
                  >
                  </app-manage-live-prices>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon>euro_symbol</mat-icon>&nbsp; Preise (Historisch)
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <app-manage-historical-prices
                    [showFilters]="false"
                    [cruiseId]="row.cruiseId"
                  >
                  </app-manage-historical-prices>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-template>

        <ng-container
          *ngIf="{
      defaultOrder: 'id',
      defaultOrderDirection: 'desc',
      pageLength: 5,
      columns: [
        { prop: 'id', name: 'Id' },
        { prop: 'routeId', name: 'Route Id' },
        { prop: 'cruiseId', name: 'Cruise Id' },
        { prop: 'shipId', name: 'Ship Id' },
        { prop: 'shippingCompanyId', name: 'Company Id' },
        { prop: 'startDate', name: 'Start Date', cellTemplate: cellDateStartRef },
        { prop: 'duration', name: 'Duration' },
        { prop: 'offerType', name: 'Offer Type' }
      ],
      fetchData: fetchData()
} as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
