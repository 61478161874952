<div style="padding: 10px">
  <!--<h1>Report</h1>-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cellBookedDateRef let-row="row">
          {{row?.bookedOn| date : 'dd.MM.yy'}} @ {{row?.bookedOn | date :
          'shortTime'}}
        </ng-template>

        <ng-template #cellPlatformTemplateRef let-row="row">
          {{row?.bookingDataParsed?.travellers?.metadata?.pathName ===
          '/booking-form-desktop' ? 'Desktop' : 'Mobil'}}
        </ng-template>

        <!--<ng-container *ngIf="!userId">-->
        <ng-container>
          <br />
          &nbsp; &nbsp; Gesamtvolumen:
          <span style="color: red; font-weight: bold"
            >{{totalBookingVolume$ | async }} €</span
          >
          <br />
        </ng-container>

        <!--Change the table fields according to requirement-->
        <ng-container
          *ngIf="{
            defaultOrder: 'id',
            defaultOrderDirection: 'desc',
            pageLength: 10,
            columns: [
              { prop: 'id', name: 'Buchungs Id' },
              { prop: 'bookedOn', name: 'Zeitstempel', cellTemplate: cellBookedDateRef},
              { prop: 'bookingDataParsed.travellers.metadata.pathName', name: 'Gerät', cellTemplate: cellPlatformTemplateRef, sortable: false },
              { prop: 'route.shippingCompany.name', name: 'Reederei', sortable: false },

              { prop: 'bookingDataParsed.trip.totalPrice', name: 'Gesamtpreis', sortable: false },
              { prop: 'user.customerNumber', name: 'Kundennummer', sortable: false },
              { prop: 'routeId', name: 'Routen Id', sortable: false },
              { prop: 'cruiseId', name: 'Cruise Id', sortable: false },
              { prop: 'adults', name: 'Erwachsene', sortable: false },
              { prop: 'children', name: 'Kinder', sortable: false },

              { prop: 'bookingDataParsed.travellers.data.main_traveller_titel', name: 'Hauptr. Titel', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_anrede', name: 'Hauptr. Anrede', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_firstname', name: 'Hauptr. Vorname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_lastname', name: 'Hauptr. Nachname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_birthday', name: 'Hauptr. Geb.', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_street', name: 'Hauptr. Straße', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_city', name: 'Hauptr. Stadt', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_zip', name: 'Hauptr. PLZ', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_land', name: 'Hauptr. Land', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_eMail', name: 'Hauptr. E-Mail', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_nationality', name: 'Hauptr. Nationalität', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_phoneHome', name: 'Hauptr. Tel. Home', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.main_traveller_phoneMobile', name: 'Hauptr. Tel. Mobil', sortable: false},

              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_title', name: '1.Mitreis. Titel', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_anrede', name: '1.Mitreis. Anrede', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_firstname', name: '1.Mitreis. Vorname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_lastname', name: '1.Mitreis. Nachname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_birthday', name: '1.Mitreis. Geb.', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_2_nationality', name: '1.Mitreis. Nationalität', sortable: false},

              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_title', name: '2.Mitreis. Titel', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_anrede', name: '2.Mitreis. Anrede', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_firstname', name: '2.Mitreis. Vorname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_lastname', name: '2.Mitreis. Nachname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_birthday', name: '2.Mitreis. Geb.', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_3_nationality', name: '2.Mitreis. Nationalität', sortable: false},

              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_title', name: '3.Mitreis. Titel', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_anrede', name: '3.Mitreis. Anrede', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_firstname', name: '3.Mitreis. Vorname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_lastname', name: '3.Mitreis. Nachname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_birthday', name: '3.Mitreis. Geb.', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_4_nationality', name: '3.Mitreis. Nationalität', sortable: false},

              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_title', name: '4.Mitreis. Titel', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_anrede', name: '4.Mitreis. Anrede', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_firstname', name: '4.Mitreis. Vorname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_lastname', name: '4.Mitreis. Nachname', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_birthday', name: '4.Mitreis. Geb.', sortable: false},
              { prop: 'bookingDataParsed.travellers.data.other_traveller_5_nationality', name: '4.Mitreis. Nationalität', sortable: false}

            ],
            fetchData: fetchData()
          } as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
            [enableRowDetails]="true"
            [rowDetailTemplateRef]="rowDetailTemplateRef"
          >
            <ng-template #rowDetailTemplateRef let-row="row">
              <div style="max-width: 85vw">
                <div [innerHTML]="row | mailTemplate | async"></div>
              </div>
            </ng-template>
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
