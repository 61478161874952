/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatabaseBudgetModelObservationExtended } from '../models/database-budget-model-observation-extended';
import { DatabaseModelBudgetObservation } from '../models/database-model-budget-observation';

@Injectable({
  providedIn: 'root',
})
export class BudgetObservationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMyBudgetObservations
   */
  static readonly GetMyBudgetObservationsPath = '/api/BudgetObservations/budget-observations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyBudgetObservations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyBudgetObservations$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseModelBudgetObservation>>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.GetMyBudgetObservationsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseModelBudgetObservation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyBudgetObservations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyBudgetObservations(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseModelBudgetObservation>> {

    return this.getMyBudgetObservations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseModelBudgetObservation>>) => r.body as Array<DatabaseModelBudgetObservation>)
    );
  }

  /**
   * Path part for operation startBudgetObservation
   */
  static readonly StartBudgetObservationPath = '/api/BudgetObservations/budget-observations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBudgetObservation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startBudgetObservation$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StartBudgetObservationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startBudgetObservation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startBudgetObservation(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.startBudgetObservation$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stopBudgetObservation
   */
  static readonly StopBudgetObservationPath = '/api/BudgetObservations/budget-observations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  stopBudgetObservation$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  stopBudgetObservation$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  stopBudgetObservation$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  stopBudgetObservation$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  stopBudgetObservation$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  stopBudgetObservation$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  stopBudgetObservation$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBudgetObservation$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBudgetObservation(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  stopBudgetObservation$Xml$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  stopBudgetObservation$Xml(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBudgetObservation$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  stopBudgetObservation$Plain$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.StopBudgetObservationPath, 'delete');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopBudgetObservation$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  stopBudgetObservation$Plain(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.stopBudgetObservation$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getMyBudgetObservationsWithPrice
   */
  static readonly GetMyBudgetObservationsWithPricePath = '/api/BudgetObservations/budget-observations-with-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyBudgetObservationsWithPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyBudgetObservationsWithPrice$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<DatabaseBudgetModelObservationExtended>>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.GetMyBudgetObservationsWithPricePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DatabaseBudgetModelObservationExtended>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyBudgetObservationsWithPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyBudgetObservationsWithPrice(params?: {
    context?: HttpContext
  }
): Observable<Array<DatabaseBudgetModelObservationExtended>> {

    return this.getMyBudgetObservationsWithPrice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DatabaseBudgetModelObservationExtended>>) => r.body as Array<DatabaseBudgetModelObservationExtended>)
    );
  }

  /**
   * Path part for operation budgetIsObserved
   */
  static readonly BudgetIsObservedPath = '/api/BudgetObservations/budget-is-observed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `budgetIsObserved()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  budgetIsObserved$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.BudgetIsObservedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `budgetIsObserved$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  budgetIsObserved(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.budgetIsObserved$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation editBudgetObservation
   */
  static readonly EditBudgetObservationPath = '/api/BudgetObservations/edit-budget-observations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editBudgetObservation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editBudgetObservation$Response(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, BudgetObservationsService.EditBudgetObservationPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editBudgetObservation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editBudgetObservation(params?: {
    context?: HttpContext
    body?: DatabaseModelBudgetObservation
  }
): Observable<boolean> {

    return this.editBudgetObservation$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
