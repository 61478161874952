/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResponseModelWpMedia } from '../models/response-model-wp-media';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAppStoreUrl
   */
  static readonly GetAppStoreUrlPath = '/api/Media/appstoreurl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppStoreUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppStoreUrl$Response(params?: {
    operatingSystem?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetAppStoreUrlPath, 'get');
    if (params) {
      rb.query('operatingSystem', params.operatingSystem, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppStoreUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppStoreUrl(params?: {
    operatingSystem?: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAppStoreUrl$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getWordpressVideoEntries
   */
  static readonly GetWordpressVideoEntriesPath = '/api/Media/wordpressvideoentries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWordpressVideoEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWordpressVideoEntries$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseModelWpMedia>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetWordpressVideoEntriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelWpMedia>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWordpressVideoEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWordpressVideoEntries(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseModelWpMedia>> {

    return this.getWordpressVideoEntries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelWpMedia>>) => r.body as Array<ResponseModelWpMedia>)
    );
  }

  /**
   * Path part for operation getBlogContent
   */
  static readonly GetBlogContentPath = '/api/Media/blogcontent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlogContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResponseModelWpMedia>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetBlogContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResponseModelWpMedia>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBlogContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogContent(params?: {
    context?: HttpContext
  }
): Observable<Array<ResponseModelWpMedia>> {

    return this.getBlogContent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResponseModelWpMedia>>) => r.body as Array<ResponseModelWpMedia>)
    );
  }

  /**
   * Path part for operation getImprint
   */
  static readonly GetImprintPath = '/api/Media/imprint';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImprint()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImprint$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetImprintPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImprint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImprint(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getImprint$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getPrivacyPolicy
   */
  static readonly GetPrivacyPolicyPath = '/api/Media/privacy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrivacyPolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivacyPolicy$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetPrivacyPolicyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrivacyPolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrivacyPolicy(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getPrivacyPolicy$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTermsOfService
   */
  static readonly GetTermsOfServicePath = '/api/Media/tos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTermsOfService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsOfService$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetTermsOfServicePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTermsOfService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsOfService(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getTermsOfService$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getImage
   */
  static readonly GetImagePath = '/api/Media/images/{imagePath}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage$Response(params: {
    imagePath: string;
    width?: number;
    height?: number;
    'route-id'?: number;
    'cruise-id'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetImagePath, 'get');
    if (params) {
      rb.path('imagePath', params.imagePath, {});
      rb.query('width', params.width, {});
      rb.query('height', params.height, {});
      rb.query('route-id', params['route-id'], {});
      rb.query('cruise-id', params['cruise-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImage(params: {
    imagePath: string;
    width?: number;
    height?: number;
    'route-id'?: number;
    'cruise-id'?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRouteFiles
   */
  static readonly GetRouteFilesPath = '/api/Media/routefiles/{filePath}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRouteFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteFiles$Response(params: {
    filePath: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetRouteFilesPath, 'get');
    if (params) {
      rb.path('filePath', params.filePath, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRouteFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRouteFiles(params: {
    filePath: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getRouteFiles$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getShippingCompanyImage
   */
  static readonly GetShippingCompanyImagePath = '/api/Media/shippingcompanyimg/{imageName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShippingCompanyImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShippingCompanyImage$Response(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetShippingCompanyImagePath, 'get');
    if (params) {
      rb.path('imageName', params.imageName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShippingCompanyImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShippingCompanyImage(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getShippingCompanyImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDashboardImage
   */
  static readonly GetDashboardImagePath = '/api/Media/dashboardimgs/{imageName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDashboardImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardImage$Response(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetDashboardImagePath, 'get');
    if (params) {
      rb.path('imageName', params.imageName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDashboardImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardImage(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getDashboardImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRegionImage
   */
  static readonly GetRegionImagePath = '/api/Media/regions/{imageName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegionImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionImage$Response(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaService.GetRegionImagePath, 'get');
    if (params) {
      rb.path('imageName', params.imageName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegionImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionImage(params: {
    imageName: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getRegionImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
