import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import {
  INavigationStateService,
  InitializeNavigationStateHandler,
  NavigationStateHandler,
  UltraDynamicFilterConfig,
  UltraDynamicFilterStateMap
} from '../../../shared/navigation/navigation-state-handler.class';

export interface ObservationsFilterState {
  pageLength?: number;
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStateObservationsService
  implements INavigationStateService<ObservationsFilterState>
{
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<ObservationsFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.MANAGE_OBSERVATIONS];
  }

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.MANAGE_OBSERVATIONS,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      // TODO configure filters

      //table specific filters
      {
        type: 'DROPDOWN',
        matrixParameter: 'pageLength',
        label: 'Einträge pro Seite',
        iconName: '',
        required: true,
        options$: of([
          { id: '5', label: '5 pro Seite' },
          { id: '10', label: '10 pro Seite' },
          { id: '50', label: '50 pro Seite' },
          { id: '100', label: '100 pro Seite' },
          { id: '1000', label: '1000 pro Seite' }
        ]),
        defaultValue: '10',
        emptyOptionValue: null
      },
      {
        type: 'INPUT_TEXT',
        matrixParameter: 'search',
        controlOptions: {
          debounceTime: 500
        },
        label: 'Suchbegriff',
        iconName: 'search',
        emptyOptionValue: null
      }
    ]
  };

  constructor(protected readonly _router: Router) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(
      PATHS.MSB,
      this.config,
      _router
    );
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): ObservationsFilterState {
    const parsedFilter: ObservationsFilterState = {};

    // TODO parse the filter data

    if (fltr?.pageLength) {
      parsedFilter.pageLength = parseInt(fltr.pageLength, 10);
    }

    if (fltr?.search) {
      parsedFilter.search = fltr.search;
    }

    return parsedFilter;
  }
}
