<div class="c-data-loading-container" [class.data-loaded]="loadingState !== LoadingStates.LOADING">
    <div class="c-data-loading-container__indicator" [style.height]="height">
      <svg class="spinner" viewBox="0 0 66 66" data-reactid=".0.3.0.0.0.0.0">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" data-reactid=".0.3.0.0.0.0.0.0">
        </circle>
      </svg>
    </div>
    <div class="c-data-loading-container__content">
        <ng-content></ng-content>
    </div>
</div>
