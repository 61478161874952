/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminService } from './services/admin.service';
import { AdminTemporaryService } from './services/admin-temporary.service';
import { AirportsService } from './services/airports.service';
import { AppSettingsService } from './services/app-settings.service';
import { AuthService } from './services/auth.service';
import { BudgetObservationsService } from './services/budget-observations.service';
import { CabinsService } from './services/cabins.service';
import { ChartService } from './services/chart.service';
import { CommentsService } from './services/comments.service';
import { CruiseportsService } from './services/cruiseports.service';
import { DashboardService } from './services/dashboard.service';
import { FlightPricesService } from './services/flight-prices.service';
import { FormTemplateService } from './services/form-template.service';
import { LandtripsService } from './services/landtrips.service';
import { MediaService } from './services/media.service';
import { OfferService } from './services/offer.service';
import { PriceModelService } from './services/price-model.service';
import { PricesService } from './services/prices.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { RegionsService } from './services/regions.service';
import { ReservationsService } from './services/reservations.service';
import { RouteCoursesService } from './services/route-courses.service';
import { RoutesService } from './services/routes.service';
import { RouteTimetablesService } from './services/route-timetables.service';
import { SearchService } from './services/search.service';
import { SearchRoutesService } from './services/search-routes.service';
import { ShippingCompaniesService } from './services/shipping-companies.service';
import { ShipsService } from './services/ships.service';
import { TelephoneBookedTripsService } from './services/telephone-booked-trips.service';
import { TestingService } from './services/testing.service';
import { TravelGroupService } from './services/travel-group.service';
import { UploadedRouteFilesService } from './services/uploaded-route-files.service';
import { UserService } from './services/user.service';
import { UserBookedTripsService } from './services/user-booked-trips.service';
import { UserBookingWishService } from './services/user-booking-wish.service';
import { UserExceptionsService } from './services/user-exceptions.service';
import { UserFavouriteRoutesService } from './services/user-favourite-routes.service';
import { UserObservationsService } from './services/user-observations.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminService,
    AdminTemporaryService,
    AirportsService,
    AppSettingsService,
    AuthService,
    BudgetObservationsService,
    CabinsService,
    ChartService,
    CommentsService,
    CruiseportsService,
    DashboardService,
    FlightPricesService,
    FormTemplateService,
    LandtripsService,
    MediaService,
    OfferService,
    PriceModelService,
    PricesService,
    PushNotificationsService,
    RegionsService,
    ReservationsService,
    RouteCoursesService,
    RoutesService,
    RouteTimetablesService,
    SearchService,
    SearchRoutesService,
    ShippingCompaniesService,
    ShipsService,
    TelephoneBookedTripsService,
    TestingService,
    TravelGroupService,
    UploadedRouteFilesService,
    UserService,
    UserBookedTripsService,
    UserBookingWishService,
    UserExceptionsService,
    UserFavouriteRoutesService,
    UserObservationsService,
    ApiConfiguration
  ],
})
export class MsbApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<MsbApiModule> {
    return {
      ngModule: MsbApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MsbApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MsbApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
