import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import theme from 'highcharts/themes/brand-dark';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import {
  BookingsFilterState,
  FilterStateBookingsService
} from './filter-state-bookings.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import * as moment from 'moment';
import { RequestModelHighChartData } from '../../../apis/msb/models/request-model-high-chart-data';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { BookingsKpiIndependentData } from '../../../apis/msb/models/bookings-kpi-independent-data';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);
import { omit } from 'lodash';
import { HighchartsChartData } from '../../../apis/msb/models/highcharts-chart-data';
import { BookingsKpiDependentData } from '../../../apis/msb/models/bookings-kpi-dependent-data';
import { HighChartOptions } from '../../../shared/utils/chart.utils';
// Initialize exporting module.
Exporting(Highcharts);

@Component({
  selector: 'app-analytics-bookings',
  templateUrl: './bookings.page.html',
  styleUrls: ['./bookings.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingsComponent implements OnInit {
  readonly Highcharts = Highcharts;
  isHighcharts = typeof Highcharts === 'object';

  readonly kpiDataLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly bookingVolumeChartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly bookedCountChartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly bookingVolumePieChartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly bookingsStatsPieChartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly customersCountChartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filterValid$: Observable<boolean> =
    this.filterStateService.filterState.getFilterData$.pipe(
      map((fltr) => {
        let dateRangeIsValid = false;
        if (fltr?.dateFrom?.length === 10 && fltr?.dateTo?.length === 10) {
          if (
            moment(fltr.dateFrom, 'YYYY-MM-DD').isValid() &&
            moment(fltr.dateTo, 'YYYY-MM-DD').isValid()
          ) {
            dateRangeIsValid = true;
          }
        }
        return dateRangeIsValid;
      }),
      tap(() => {
        setTimeout(() => this._globalLoadingService.release(), 1000);
      }),
      shareReplay()
    );

  readonly chartConfigs$: Observable<RequestModelHighChartData> =
    this.filterStateService.filterState.getFilterData$.pipe(
      map((fltr) => {
        const chartConfigs: RequestModelHighChartData = {
          startDate: fltr?.dateFrom,
          endDate: fltr?.dateTo,
          dimension: fltr?.dimension,
          chartconfigs: [
            {
              title: 'Buchungen',
              columns: ['Booked', 'Canceled'],
              type: 'column'
            }
          ]
        };
        return chartConfigs;
      }),
      shareReplay(1)
    );

  readonly bookingVolumeChartOptions$: Observable<Highcharts.Options> =
    combineLatest([
      this.filterValid$,
      this.chartConfigs$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(([filterValid, config, fltr]) => !!filterValid && !!config),
      tap(() => this.bookingVolumeChartLoading$.next(LoadingStates.LOADING)),
      switchMap(([filterValid, config, fltr]) => {
        if (filterValid) {
          return this._adminService
            .getBookingVolumeChartData({ body: config })
            .pipe(
              map(
                (hcData): Highcharts.Options =>
                  HighChartOptions(fltr, hcData, false, 'Buchungsvolume')
              )
            );
        } else {
          return of(null as Highcharts.Options);
        }
      }),
      tap(() => this.bookingVolumeChartLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly bookedCountChartOptions$: Observable<Highcharts.Options> =
    combineLatest([
      this.filterValid$,
      this.chartConfigs$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(([filterValid, config, fltr]) => !!filterValid && !!config),
      tap(() => this.bookedCountChartLoading$.next(LoadingStates.LOADING)),
      switchMap(([filterValid, config, fltr]) => {
        if (filterValid) {
          return this._adminService
            .getBookingsSuccessCountChartData({ body: config })
            .pipe(
              map(
                (hcData): Highcharts.Options =>
                  HighChartOptions(fltr, hcData, false, 'Bucungsnummern')
              )
            );
        } else {
          return of(null as Highcharts.Options);
        }
      }),
      tap(() => this.bookingVolumeChartLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly bookingVolumePieChartOptions$: Observable<Highcharts.Options> =
    combineLatest([
      this.filterValid$,
      this.chartConfigs$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(([filterValid, config, fltr]) => !!filterValid && !!config),
      tap(() => this.bookingVolumePieChartLoading$.next(LoadingStates.LOADING)),
      switchMap(([filterValid, config, fltr]) => {
        if (filterValid) {
          return this._adminService
            .getBookingsVolumePieChartData({ body: config })
            .pipe(
              map((hcData): Highcharts.Options => {
                return HighChartOptions(fltr, hcData, true, 'Buchungsvolume');
              })
            );
        } else {
          return of(null as Highcharts.Options);
        }
      }),
      tap(() => this.bookingVolumeChartLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly bookingStatsPieChartOptions$: Observable<Highcharts.Options> =
    combineLatest([
      this.filterValid$,
      this.chartConfigs$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(([filterValid, config, fltr]) => !!filterValid && !!config),
      tap(() => this.bookingVolumePieChartLoading$.next(LoadingStates.LOADING)),
      switchMap(([filterValid, config, fltr]) => {
        if (filterValid) {
          return this._adminService
            .getBookingsCountPieChartData({ body: config })
            .pipe(
              map((hcData): Highcharts.Options => {
                return HighChartOptions(fltr, hcData, true, 'Buchungsnummern');
              })
            );
        } else {
          return of(null as Highcharts.Options);
        }
      }),
      tap(() => this.bookingVolumeChartLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly customersCountChartOptions$: Observable<Highcharts.Options> =
    combineLatest([
      this.filterValid$,
      this.chartConfigs$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(([filterValid, config, fltr]) => !!filterValid && !!config),
      tap(() => this.customersCountChartLoading$.next(LoadingStates.LOADING)),
      switchMap(([filterValid, config, fltr]) => {
        if (filterValid) {
          return this._adminService
            .getCustomersCountChartData({ body: config })
            .pipe(
              map(
                (hcData): Highcharts.Options =>
                  HighChartOptions(fltr, hcData, false, 'Anzahl der Neuekunden')
              )
            );
        } else {
          return of(null as Highcharts.Options);
        }
      }),
      tap(() => this.customersCountChartLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  readonly kpiIndependentData$: Observable<BookingsKpiIndependentData> =
    this._adminService.getBookingKpiIndependentData().pipe(shareReplay(1));

  readonly kpiDependentData$: Observable<BookingsKpiDependentData> =
    combineLatest([
      this.chartConfigs$,
      this.filterValid$,
      this.filterStateService.filterState.getFilterData$
    ]).pipe(
      filter(
        ([config, valid, fltr]) => !!valid && !!fltr?.dateTo && !!fltr?.dateFrom
      ),
      switchMap(([config, valid, fltr]) => {
        if (valid) {
          return this._adminService.getBookingKpiDependentData({
            body: config
          });
        } else {
          return of(null as BookingsKpiDependentData);
        }
      }),
      tap(() => this.kpiDataLoading$.next(LoadingStates.LOADED)),
      shareReplay(1)
    );

  constructor(
    readonly filterStateService: FilterStateBookingsService,
    private readonly _globalLoadingService: GlobalLoadingService,
    private readonly _adminService: AdminService
  ) {}

  ngOnInit(): void {
    theme(Highcharts);
    this._globalLoadingService.release();
  }

  isNumber(value: string | number): boolean {
    return typeof value === 'number' && !isNaN(value);
  }
}
