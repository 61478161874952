import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';
import { HighchartsOptions } from '../../../shared/utils/highcharts-utils';

import { FilterStateLivePriceDropsService } from './filter-state-live-price-drops.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-manage-live-price-drops',
  templateUrl: './live-price-drops.page.html',
  styleUrls: ['./live-price-drops.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LivePriceDropsComponent implements OnInit {
  rootUrl =
    'https://buchen.mein-schiffberater.com/reisen/details;adults=2;children=0;';

  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );
  readonly chartLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly Highcharts = Highcharts;

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    shareReplay()
  );

  readonly chartData$ = this.filterStateService.filterState.getFilterData$.pipe(
    tap(() => this.chartLoading$.next(LoadingStates.LOADING)),
    delay(2000),
    switchMap(() => {
      return of<any[]>([
        { name: 'Test 1', value: Math.floor(Math.random() * 100) },
        { name: 'Test 2', value: Math.floor(Math.random() * 100) },
        { name: 'Test 3', value: Math.floor(Math.random() * 100) },
        { name: 'Test 4', value: Math.floor(Math.random() * 100) },
        { name: 'Test 5', value: Math.floor(Math.random() * 100) }
      ]);
    }),
    tap(() => this.chartLoading$.next(LoadingStates.LOADED)),
    shareReplay()
  );

  readonly chartOptions$ = this.chartData$.pipe(
    map((rows) => {
      return HighchartsOptions(<Highcharts.Options>{
        chart: {
          type: 'column',
          height: 450
        },
        xAxis: {
          categories: rows.map((row) => row.name),
          gridLineWidth: 0
        },
        yAxis: {
          title: {
            text: 'Value'
          },
          gridLineWidth: 0
        },
        series: [
          {
            data: rows.map((row) => row.value)
          }
        ]
      });
    }),
    shareReplay()
  );

  constructor(
    readonly filterStateService: FilterStateLivePriceDropsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService,
    readonly clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) => {
      return this.adminService.streamPriceDrops({ body: request });
    };
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }

  async generatePriceDropsList() {
    const topList = [];
    const topPriceDrops = await this.adminService.topPriceDrops().toPromise();
    topPriceDrops.forEach((priceDrop) => {
      if (!topList.some((x) => x.routeName === priceDrop.routeName)) {
        topList.push(priceDrop);
      }
    });
    let topListString = '';
    topList.forEach((priceDrop) => {
      topListString += `Bis zu ${priceDrop.priceSavings}€ p.P. Ersparnis für ${priceDrop.routeName}!\n${priceDrop.routeUrl}\n\n`;
    });
    this.clipboard.copy(topListString);
    alert('Liste in der Zwischenablage gespeichert!');
  }
}
