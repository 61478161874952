/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for MailApi services
 */
@Injectable({
  providedIn: 'root',
})
export class MailApiConfiguration {
  rootUrl: string = '';
}

export interface MailApiConfigurationInterface {
  rootUrl?: string;
}
