/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequestModelReservationCabin } from '../models/request-model-reservation-cabin';
import { RequestModelReservationFormData } from '../models/request-model-reservation-form-data';
import { ReservationConfig } from '../models/reservation-config';
import { StringStringKeyValuePair } from '../models/string-string-key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation submitReservation
   */
  static readonly SubmitReservationPath = '/api/Reservations/submit-reservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitReservation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitReservation$Response(params?: {
    context?: HttpContext
    body?: RequestModelReservationFormData
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationsService.SubmitReservationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitReservation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitReservation(params?: {
    context?: HttpContext
    body?: RequestModelReservationFormData
  }
): Observable<boolean> {

    return this.submitReservation$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getReservationOptions
   */
  static readonly GetReservationOptionsPath = '/api/Reservations/get-reservation-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReservationOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationOptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ReservationConfig>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationsService.GetReservationOptionsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationConfig>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReservationOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationOptions(params?: {
    context?: HttpContext
  }
): Observable<ReservationConfig> {

    return this.getReservationOptions$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationConfig>) => r.body as ReservationConfig)
    );
  }

  /**
   * Path part for operation getReservationCabins
   */
  static readonly GetReservationCabinsPath = '/api/Reservations/get-reservation-cabins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReservationCabins()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReservationCabins$Response(params?: {
    context?: HttpContext
    body?: RequestModelReservationCabin
  }
): Observable<StrictHttpResponse<Array<StringStringKeyValuePair>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationsService.GetReservationCabinsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StringStringKeyValuePair>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReservationCabins$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReservationCabins(params?: {
    context?: HttpContext
    body?: RequestModelReservationCabin
  }
): Observable<Array<StringStringKeyValuePair>> {

    return this.getReservationCabins$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StringStringKeyValuePair>>) => r.body as Array<StringStringKeyValuePair>)
    );
  }

}
