export const environment = {
  production: true,
  services: {
    msbService: {
      baseUrl: 'https://api.mein-schiffberater.com/msb/testdatabase',
      swaggerPath: '/swagger/v1/swagger.json'
    },
    mailService: {
      baseUrl: 'https://mailservice.rodomnia.com/service',
      swaggerPath: '/swagger/v1/swagger.json'
    }
  }
};
