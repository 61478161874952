import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

export enum LoadingStates {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED'
}

@Component({
  selector: 'form-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class DataLoadingContainerComponent implements OnChanges {

  LoadingStates = LoadingStates;

  @Input() loadingState: LoadingStates = null;
  @Input() height = '200px';
  @Output() loaded = new EventEmitter(true);

  ngOnChanges(changes) {
    if (changes.loadingState.currentValue === LoadingStates.LOADED) {
      this.loaded.emit();
    }
  }
}